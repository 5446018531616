<template>
    <box title="Seleccion de procedimiento" icon="fas fa-clipboard-check">
        <div class="row">
            <div class="input-group col-12 col-md-6">
                <select class="form-control" v-model="select_procedimiento" @change="saveVeterinaria(1,'select_procedimiento')">
                            <option value="sistema_respiratorio">Sistema Respiratorio</option>
                            <option value="sistema_digestivo">Sistema Digestivo</option>
                            <option value="ojos">Ojos</option>
                            <option value="cabeza">Cabeza, cuello, oídos-orejas, nariz y cavidad bucal</option>
                            <option value="piel">Piel</option>
                            <option value="sistema_urinario">Sistema Urinario</option>
                            <option value="sistema_genital">Sistema Genital</option>
                            <option value="sistema_musculoesqueletico">Sistema Musculoesquelético</option>
                            <option value="sistema_nervioso">Sistema Nervioso</option>
                            <option value="aumentos_masas">Aumentos de masas</option>
                        </select>
                </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_respiratorio'" title="Sistema Respiratorio" icon="fas fa-lungs">
        <div class="row w-100">
            <div v-for="item in sistema_respiratorio" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_respiratorio')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_digestivo'" title="Antecedentes de la dieta" icon="fas fa-bowl-food">
        <div class="row w-100">
            <div v-for="item in antecedentes_dieta" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'antecedentes_dieta')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_digestivo'" title="Queja principal" icon="fas fa-hospital-user">
        <div class="row w-100">
            <div v-for="item in queja_principal" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'queja_principal')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'ojos'" title="Ojos" icon="fas fa-eye">
        <div class="row w-100">
            <div v-for="item in ojos" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'ojos')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'cabeza'" title="Cabeza, cuello, oídos-orejas, nariz y cavidad bucal" icon="fas fa-shield-dog">
        <div class="row w-100">
            <div v-for="item in cabeza" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'cabeza')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'piel'" title="Piel" icon="fas fa-dog">
        <div class="row w-100">
            <div v-for="item in piel" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'piel')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_urinario'" title="Sistema Urinario" icon="fas fa-dog">
        <div class="row w-100">
            <div v-for="item in sistema_urinario" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_urinario')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_genital'" title="Sistema Genital" icon="fas fa-dog">
        <div class="row w-100">
            <div v-for="item in sistema_genital" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_genital')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_musculoesqueletico'" title="Sistema Musculoesquelético" icon="fas fa-hospital-user">
        <div class="row w-100">
            <div v-for="item in sistema_musculoesqueletico" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_musculoesqueletico')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'sistema_nervioso'" title="Sistema Nervioso" icon="fas fa-dog">
        <div class="row w-100">
            <div v-for="item in sistema_nervioso" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_nervioso')"/>
            </div>
        </div>
    </box>
    <box v-if="select_procedimiento === 'aumentos_masas'" title="Aumentos de masas" icon="fas fa-dog">
        <div class="row w-100">
            <div v-for="item in aumentos_masas" class="form-group col-12 col-md-6 col-2xl-3">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'aumentos_masas')"/>
            </div>
        </div>
    </box>
    <box title="Otros" icon="fas fa-file-alt">
        <div class="row">
            <div class="col-12 col-md-6 mb-3" v-for="item in veterinaria_otros" :key="item.name">
                <Field :data="item" v-model="item.value" @change="saveOtros"></Field>
            </div>
        </div>
    </box>
</template>  

<script>
import API from "src/core/Api";
import Box from '/src/modules/medico/views/fichas/Box.vue';
import {mapGetters} from "vuex";
import Field from '/src/modules/medico/views/fichas/Field.vue';
import '@vueform/slider/themes/default.css';

export default { 
data() {
    return {
    //data
      //tab veterinaria

      select_procedimiento:'sistema_respiratorio',

        sistema_respiratorio:{  
        dias_enfermo:{
            label:"Cuantos días lleva enfermo",
            value:"",
            placeholder:"Escriba un valor aproximado...",
        },
        dificultad:{
            label:"Tiene dificultad respiratoria o congestión",
            value:"",
            placeholder:"Describa si ha notado alguno de estos sintomas...",
        },
        tos:{
            label:"Tipo de tos",
            value:"",
            placeholder:"Productiva, no productiva, húmeda, seca, aspera o ruidosa",
        },
        contracciones:{
            label:"Contracciones abdominales antes de la producción de fluido",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        secrecion_nasal:{
            label:"Tipo de secrecion nasal o en mucosas",
            value:"",
            placeholder:"Transparente, mucoide, mucopurulenta o hemorrágica",
        },
        sintomas_alergicos:{
            label:"Síntomas alérgicos",
            value:"",
            placeholder:"Estornudos, agitación de cabeza, etc",
        },
        inflamacion_oidos:{
            label:"Inflamación e irritación en oídos",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        apetito:{
            label:"Apetito",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        vomitos:{
            label:"Vomitos y náusea",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        deshidratacion:{
            label:"Deshidratación",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        temblor_muscular:{
            label:"Temblores musculares o coordinación para caminar, calambres",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        vision:{
            label:"Problemas de visión",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        agitacion:{
            label:"Agitación, jadeo, salivación, cambio de voz",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },
        no_ejercicio:{
            label:"Intolerancia al ejercicio, debilidad o desmayos",
            value:"",
            placeholder:"Escriba aqui la descripción...",
        },

        }, 

        antecedentes_dieta: {
        tiene_apetito_perdida_ganancia_peso: {
            label: "Apetito y peso",
            value: "",
            placeholder: "Describa los cambios en el apetito o peso del animal."
        },
        tipo_dieta_nombre_comercial_premios: {
            label: "Tipo de dieta",
            value: "",
            placeholder: "¿Qué tipo de comida consume? (seca, húmeda, etc.) Nombre comercial, premios y bocadillos."
        },
        metodo_alimentacion_cantidad_alimento: {
            label: "Método de alimentación",
            value: "",
            placeholder: "¿Cómo se le da de comer? (libre o tiempos individuales) Cantidad de alimento."
        },
        acceso_basura_toxinas_consumo_objetos: {
            label: "Acceso a toxinas",
            value: "",
            placeholder: "¿Tiene acceso a basura, toxinas o plantas? ¿Ha ingerido objetos?"
        },
        sospecha_obstrucción: {
            label: "Se sospecha alguna obstrucción ",
            value: "",
            placeholder: "¿Cree que tenga alguna obstrucción?"
        }
        },

        queja_principal: {

        dias_enfermo: {
            label: "Cuantos días lleva enfermo",
            value: "",
            placeholder: "¿Cuántos días lleva el animal presentando estos síntomas?"
        },
        vomitos_nausea: {
            label: "Vómitos o náuseas",
            value: "",
            placeholder: "Describa el tipo de vómito (comida digerida, no digerida, fluidos, etc.)"
        },
        frecuencia_vomito: {
            label: "Frecuencia del vómito",
            value: "",
            placeholder: "¿Con qué frecuencia presenta el animal vómitos?"
        },
        contracciones_abdominales_vomito: {
            label: "Contracciones abdominales al vomitar",
            value: "",
            placeholder: "¿Ha observado contracciones abdominales en el animal al vomitar?"
        },
        diarrea_estreñimiento: {
            label: "¿Diarrea o estreñimiento?",
            value: "",
            placeholder: "¿Ha observado diarrea o estreñimiento en el animal?"
        },
        color_heces: {
            label: "Color de las heces",
            value: "",
            placeholder: "¿De qué color son las heces del animal? (blanco, verde, amarillo, marrón, rojo)"
        },
        consistencia_heces: {
            label: "Consistencia de las heces",
            value: "",
            placeholder: "¿Qué consistencia tienen las heces del animal? (sólida, muy dura, pastosa, líquida, con ligas o mucosidad, hemorragia)"
        },
        volumen_heces: {
            label: "Volumen de las heces",
            value: "",
            placeholder: "¿Ha notado algún cambio en el volumen de las heces del animal?"
        },
        frecuencia_defecacion: {
            label: "Frecuencia de defecación",
            value: "",
            placeholder: "¿Con qué frecuencia defeca el animal? (2, 3, 5 veces al día) (foto idealmente)"
        },
        dificultad_defecar: {
            label: "¿Puja, se esfuerza o se queja de dolor al defecar?",
            value: "",
            placeholder: "¿Ha observado si el animal presenta dificultad para defecar?"
        },
        coprofagia: {
            label: "¿Consume sus propias heces?",
            value: "",
            placeholder: "¿Ha observado si el animal consume sus propias heces?"
        },
        apetito: {
            label: "Apetito",
            value: "",
            placeholder: "¿Ha notado algún cambio en el apetito del animal? (come, no come, muy poco)"
        },
        perdida_peso: {
            label: "Pérdida de peso",
            value: "",
            placeholder: "¿Ha notado alguna pérdida de peso en el animal?"
        },
        deshidratacion: {
            label: "Deshidratación",
            value: "",
            placeholder: "¿Ha observado signos de deshidratación en el animal? (sequedad en boca, encías pegajosas, piel seca, ojos hundidos, etc.)"
        },
        gases_inflamacion_dolor_abdominal: {
            label: "¿Gases, inflamación o dolor abdominal?",
            value: "",
            placeholder: "¿Ha observado si el animal presenta gases, inflamación o dolor abdominal?"
        },
        sospecha_consumo_inadecuado: {
            label: "Sospecha de consumo inadecuado",
            value: "",
            placeholder: "¿Sospecha que el animal haya consumido algo inadecuado? (basura, carne en descomposición, insecticida, veneno, grasa)"
        }
        },

        ojos: {
        secrecion_ocular: {
            label: "Secreciones oculares",
            value: "",
            placeholder: "¿Ha notado secreciones en los ojos del animal? (serosa, mucoide, mucopurelenta)"
        },
        unilateral_bilateral: {
            label: "¿Unilateral o bilateral?",
            value: "",
            placeholder: "¿Afecta uno o ambos ojos?"
        },
        dolor_ocular_incomodidad: {
            label: "Dolor ocular o incomodidad",
            value: "",
            placeholder: "¿Ha observado si el animal presenta dolor o molestia en los ojos?"
        },
        rascado_ojos: {
            label: "¿Se rasca los ojos o frota la cara contra objetos?",
            value: "",
            placeholder: "¿Ha observado si el animal se rasca los ojos o frota la cara contra objetos?"
        },
        fotofobia: {
            label: "Fotofobia",
            value: "",
            placeholder: "¿Parece que el animal le molesta la luz?"
        },
        enrojecimiento_inflamacion_asimetria: {
            label: "Enrojecimiento, inflamación o asimetría",
            value: "",
            placeholder: "¿Ha notado enrojecimiento, inflamación o asimetría en los ojos del animal?"
        },
        cambio_color: {
            label: "Cambio de color",
            value: "",
            placeholder: "¿Ha notado algún cambio en el color de los ojos del animal?"
        },
        alteraciones_vista: {
            label: "Alteraciones de la vista",
            value: "",
            placeholder: "¿Ha notado que el animal tiene problemas para ubicarse o atender a su llamado?"
        },
        ceguera: {
            label: "Ceguera",
            value: "",
            placeholder: "¿Sospecha que el animal ha perdido la visión?"
        }
        },

        cabeza: {
        cambio_volumen_asimetria: {
            label: "Cambio de volumen o asimetría",
            value: "",
            placeholder: "¿Ha notado algún cambio en el tamaño o forma de la cabeza o el cuello del animal?"
        },
        sacude_cabeza_rasca_orejas: {
            label: "¿Sacude la cabeza o se rasca las orejas?",
            value: "",
            placeholder: "¿Ha observado si el animal se rasca las orejas o sacude la cabeza con frecuencia?"
        },
        secrecion_mal_olor_otico: {
            label: "Secreción o mal olor ótico",
            value: "",
            placeholder: "¿Ha notado secreción o mal olor en los oídos del animal?"
        },
        perdida_audicion: {
            label: "Pérdida de audición",
            value: "",
            placeholder: "¿Ha notado que el animal parece tener problemas para escuchar?"
        },
        secrecion_nasal: {
            label: "Secreciones nasales",
            value: "",
            placeholder: "¿Ha notado secreciones nasales en el animal? (serosa, mucoide, mucopurulenta, hemorragia)"
        },
        unilateral_bilateral_nasal: {
            label: "¿Unilateral o bilateral (nariz)?",
            value: "",
            placeholder: "¿Las secreciones nasales afectan una o ambas fosas nasales?"
        },
        estornudos_frota_nariz: {
            label: "Estornudos o frota la nariz",
            value: "",
            placeholder: "¿Ha observado si el animal estornuda o se frota la nariz con frecuencia?"
        },
        mal_aliento: {
            label: "Mal aliento",
            value: "",
            placeholder: "¿Ha notado mal aliento en el animal?"
        },
        dificultad_comer_deglutir: {
            label: "Dificultad para comer o deglutir",
            value: "",
            placeholder: "¿Ha observado si el animal tiene dificultad para comer o tragar?"
        },
        estado_encias_lengua: {
            label: "Estado de las encías y lengua",
            value: "",
            placeholder: "¿Ha observado el estado de las encías y la lengua del animal? (color, inflamación, sangrado, etc.)"
        },
        cambio_pigmentacion_gingival: {
            label: "Cambio de pigmentación gingival",
            value: "",
            placeholder: "¿Ha notado algún cambio en el color de las encías del animal?"
        },
        cambio_ladrido_maullido: {
            label: "Cambio de ladrido o maullido",
            value: "",
            placeholder: "¿Ha notado algún cambio en el ladrido o maullido del animal?"
        }
        },

        piel: {
        perdida_pelo: {
            label: "Pérdida de pelo",
            value: "",
            placeholder: "¿Ha notado pérdida de pelo en el animal? Describa la zona afectada (capa superior o inferior del pelaje)."
        },
        prurito: {
            label: "Prurito",
            value: "",
            placeholder: "¿Ha observado que el animal se rasca, se muerde o se lame? Describa la intensidad (leve, moderado, intenso) y si es continuo o estacional."
        },
        pulgas_tratamiento: {
            label: "Pulgas y tratamiento",
            value: "",
            placeholder: "¿Se observan pulgas en el animal? ¿Qué tratamiento antipulgas usa regularmente?"
        },
        tipo_cama: {
            label: "Tipo de cama",
            value: "",
            placeholder: "¿Qué tipo de cama usa la mascota? (plumas, algodón, lana)"
        },
        dieta_premios_juguetes: {
            label: "Dieta, premios y juguetes",
            value: "",
            placeholder: "¿Qué tipo de comida, premios y juguetes utiliza el animal?"
        },
        plantas_interiores: {
            label: "Plantas interiores",
            value: "",
            placeholder: "¿Hay plantas interiores o en el entorno donde se mantiene la mascota?"
        },
        reaccion_farmacos: {
            label: "Reacción a fármacos",
            value: "",
            placeholder: "¿Ha tenido el animal alguna reacción a medicamentos en el pasado?"
        },
        olor_corporal: {
            label: "Cambio de olor o mal olor",
            value: "",
            placeholder: "¿Ha notado algún cambio en el olor corporal del animal? ¿Mal olor?"
        },
        pigmentacion_piel_pelo: {
            label: "Cambio de pigmentación",
            value: "",
            placeholder: "¿Ha notado algún cambio en la pigmentación de la piel o el pelo del animal?"
        },
        textura_piel_pelo: {
            label: "Cambios en la textura",
            value: "",
            placeholder: "¿Ha notado algún cambio en la textura de la piel o el pelo del animal?"
        },
        caspa: {
            label: "Caspa",
            value: "",
            placeholder: "¿Ha notado caspa en el animal? ¿En qué áreas está afectada?"
        },
        higiene: {
            label: "Higiene",
            value: "",
            placeholder: "¿Con qué frecuencia baña, corta el pelo y cepilla al animal? ¿Qué productos utiliza?"
        },
        mascotas_afectadas: {
            label: "Mascotas en casa",
            value: "",
            placeholder: "¿Hay más mascotas en la casa? ¿Están también afectadas por problemas de piel?"
        },
        problemas_piel_humanos: {
            label: "Problemas de piel en humanos",
            value: "",
            placeholder: "¿Algún miembro de la casa tiene problemas de piel que puedan estar relacionados con la mascota?"
        },
        tratamiento_previo: {
            label: "Tratamiento previo",
            value: "",
            placeholder: "¿Ha recibido el animal algún tratamiento previo para problemas de piel? Describa el tratamiento (medicamento, dosis, vía de administración, frecuencia, respuesta observada)."
        }
        },

        sistema_urinario: {
        orina_exceso_frecuencia: {
            label: "Orina en exceso o cambios de frecuencia",
            value: "",
            placeholder: "¿Ha notado que el animal orina con más frecuencia de lo habitual o en cantidades excesivas?"
        },
        consumo_agua: {
            label: "Cambios en el consumo de agua",
            value: "",
            placeholder: "¿Ha notado cambios en la cantidad de agua que consume la mascota?"
        },
        otros_animales_toman_agua: {
            label: "Otros animales y el agua",
            value: "",
            placeholder: "¿Otros animales toman de la misma fuente de agua que la mascota afectada?"
        },
        orina_dentro_fuera_casa: {
            label: "Orina dentro o fuera de la casa",
            value: "",
            placeholder: "¿El animal orina dentro o fuera de la casa? ¿En qué lugares específicos?"
        },
        problema_despierto_dormido: {
            label: "Problema despierto o dormido",
            value: "",
            placeholder: "¿El problema de orinar ocurre cuando el animal está despierto o dormido?"
        },
        goteo_orina: {
            label: "Goteo de orina",
            value: "",
            placeholder: "¿Ha observado que el animal gotea orina sin darse cuenta?"
        },
        cantidad_orina: {
            label: "Cantidad de orina",
            value: "",
            placeholder: "¿Ha notado si la cantidad de orina producida por el animal ha cambiado?"
        },
        dificultad_orinar: {
            label: "Dificultad para orinar",
            value: "",
            placeholder: "¿Ha observado si el animal puja o muestra dificultad para orinar?"
        },
        sangrado_orina: {
            label: "Sangrado en la orina",
            value: "",
            placeholder: "¿Ha notado sangre en la orina del animal, antes o después de orinar?"
        },
        cambio_color_orina: {
            label: "Cambio de coloración de la orina",
            value: "",
            placeholder: "¿Ha notado algún cambio en el color de la orina del animal? (muy oscura, con sangre)"
        }
        },

        sistema_genital: {
        ultimo_celo: {
            label: "Último celo (hembras)",
            value: "",
            placeholder: "¿Cuándo fue el último celo de la mascota? (fecha aproximada)"
        },
        monta_accidental: {
            label: "Monta accidental (hembras)",
            value: "",
            placeholder: "¿Ha sido cubierta la mascota o existe la posibilidad de una monta accidental?"
        },
        ciclo_estral_normal: {
            label: "Ciclo estral normal (hembras)",
            value: "",
            placeholder: "¿La duración del ciclo estral fue normal respecto al intervalo esperado entre el ciclo anterior?"
        },
        reproducido_anterioridad: {
            label: "¿Se ha reproducido la mascota con anterioridad?",
            value: "",
            placeholder: "¿La mascota ha tenido crías o ha participado en la reproducción anteriormente?"
        },
        exito_cruzamiento: {
            label: "Éxito en el cruzamiento",
            value: "",
            placeholder: "¿Si se ha reproducido antes, tuvo éxito en el cruzamiento?"
        },
        dificultad_parto: {
            label: "Dificultad en el parto",
            value: "",
            placeholder: "¿Ha tenido la mascota alguna dificultad en el parto en el pasado?"
        },
        secreciones_vulvares: {
            label: "Secreciones vulvares",
            value: "",
            placeholder: "¿Ha observado secreciones vulvares en la mascota? Describa la cantidad, consistencia, color y olor."
        }
        },

        sistema_musculoesqueletico: {
        dolor: {
            label: "¿Hay dolor?",
            value: "",
            placeholder: "¿Ha observado que el animal presenta dolor? Describa la intensidad y localización del dolor."
        },
        apoyo_peso: {
            label: "¿Apoya peso?",
            value: "",
            placeholder: "¿El animal apoya peso en la extremidad afectada? ¿En qué medida?"
        },
        traumatismo_previo: {
            label: "Trauma previo",
            value: "",
            placeholder: "¿Ha habido algún traumatismo reciente que pueda estar relacionado con el dolor? ¿El dueño conoce algún incidente?"
        },
        perdida_masa_muscular: {
            label: "Pérdida de masa muscular",
            value: "",
            placeholder: "¿Ha notado pérdida de masa muscular en la zona afectada?"
        },
        asimetria_inflamacion: {
            label: "Asimetría o inflamación",
            value: "",
            placeholder: "¿Ha observado asimetría en las extremidades o inflamación en las articulaciones?"
        },
        dificultad_movimiento: {
            label: "Dificultad para moverse",
            value: "",
            placeholder: "¿Ha notado que el animal tiene dificultad para levantarse, subir o bajar escaleras?"
        },
        evolucion_dolor: {
            label: "Evolución del dolor",
            value: "",
            placeholder: "¿El dolor mejora o empeora con el tiempo?"
        },
        garrapatas_lyme: {
            label: "Garrapatas y enfermedad de Lyme",
            value: "",
            placeholder: "¿Se han observado garrapatas en el animal? (especialmente en zonas endémicas de la enfermedad de Lyme)"
        }
        },

        sistema_nervioso: {
        cambios_conducta: {
            label: "Cambios de conducta",
            value: "",
            placeholder: "¿Ha notado cambios en la conducta del animal, como agresión o demencia?"
        },
        convulsiones: {
            label: "Convulsiones",
            value: "",
            placeholder: "¿Ha observado convulsiones en el animal? Describa la duración, intervalo entre ellas y la apariencia de las convulsiones."
        },
        anormalidad_postura: {
            label: "Anormalidad en la postura",
            value: "",
            placeholder: "¿Ha notado alguna anormalidad en la postura del animal?"
        },
        incoordinacion_marcha: {
            label: "Incoordinación para caminar",
            value: "",
            placeholder: "¿Ha observado que el animal camina de manera incoordinada o tambaleándose?"
        },
        caidas: {
            label: "¿Se cae hacia algún lado?",
            value: "",
            placeholder: "¿Ha notado que el animal se cae con frecuencia hacia un lado en particular?"
        },
        vueltas_circulos: {
            label: "¿Da vueltas en círculos?",
            value: "",
            placeholder: "¿Ha observado que el animal da vueltas en círculos sin razón aparente?"
        },
        arrastrar_extremidad: {
            label: "¿Arrastra alguna extremidad?",
            value: "",
            placeholder: "¿Ha notado que el animal arrastra alguna extremidad o presenta debilidad en una de ellas?"
        }
        },

        aumentos_masas: {
        volumen_anormal: {
            label: "¿Se observan aumentos de volumen anormal?",
            value: "",
            placeholder: "¿Ha notado la presencia de masas o protuberancias inusuales en el cuerpo del animal?"
        },
        localizacion: {
            label: "Localización",
            value: "",
            placeholder: "¿En qué parte del cuerpo se encuentran estas masas o protuberancias?"
        },
        tiempo_aparicion: {
            label: "Tiempo de aparición",
            value: "",
            placeholder: "¿Hace cuánto tiempo ha notado la presencia de estas masas o protuberancias? ¿Ha observado un aumento de volumen en ese tiempo?"
        },
        cambio_apariencia_tamano: {
            label: "Cambio de apariencia o tamaño",
            value: "",
            placeholder: "¿Ha notado algún cambio en la apariencia o el tamaño de estas masas o protuberancias?"
        },
        consistencia: {
            label: "Consistencia",
            value: "",
            placeholder: "¿Cómo describiría la consistencia de estas masas o protuberancias? (blanda o sólida, firme o fluctuante, móvil o adherida)"
        },
        piel_afectada: {
            label: "Piel afectada",
            value: "",
            placeholder: "¿La piel sobre estas masas o protuberancias está cicatrizada o presenta alguna herida abierta?"
        }
        },

        veterinaria_otros: {
            habitos: {
                name: 'Hábitos o comportamientos',
                type: 'textarea',
                value: '',
            },
            observaciones: {
                name: 'Observaciones',
                type: 'textarea',
                value: '',
            },
        },

    
     
}},
components: {
    Box,
    Field,
},
computed: {
    ...mapGetters({
        AuthGetUserInfo: 'AuthGetUserInfo',
        GetMedicoFichaActive: 'GetMedicoFichaActive',
        GetReloadFicha: 'GetReloadFicha'
    })
},
watch: {

    GetReloadFicha(newValue, oldValue) {
        if (newValue === false && oldValue === true) {

            const self = this;
                
            Object.keys(self.veterinaria_otros).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_otros) {
                    if (self.GetMedicoFichaActive.metadata.veterinaria_otros[value]) {
                        self.veterinaria_otros[value].value = self.GetMedicoFichaActive.metadata.veterinaria_otros[value];
                    }
                }
            });

            //cargo procedimiento
            if(self.GetMedicoFichaActive.metadata.select_procedimiento){
                self.select_procedimiento=self.GetMedicoFichaActive.metadata.select_procedimiento['select_procedimiento'];
            }
            if(self.GetMedicoFichaActive.metadata.sistema_respiratorio){
                Object.keys(self.sistema_respiratorio).map(function (value) {
                            self.sistema_respiratorio[value].value = self.GetMedicoFichaActive.metadata.sistema_respiratorio[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.antecedentes_dieta){
                Object.keys(self.antecedentes_dieta).map(function (value) {
                            self.antecedentes_dieta[value].value = self.GetMedicoFichaActive.metadata.antecedentes_dieta[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.queja_principal){
                Object.keys(self.queja_principal).map(function (value) {
                            self.queja_principal[value].value = self.GetMedicoFichaActive.metadata.queja_principal[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.ojos){
                Object.keys(self.ojos).map(function (value) {
                            self.ojos[value].value = self.GetMedicoFichaActive.metadata.ojos[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.cabeza){
                Object.keys(self.cabeza).map(function (value) {
                            self.cabeza[value].value = self.GetMedicoFichaActive.metadata.cabeza[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.piel){
                Object.keys(self.piel).map(function (value) {
                            self.piel[value].value = self.GetMedicoFichaActive.metadata.piel[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.sistema_urinario){
                Object.keys(self.sistema_urinario).map(function (value) {
                            self.sistema_urinario[value].value = self.GetMedicoFichaActive.metadata.sistema_urinario[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.sistema_genital){
                Object.keys(self.sistema_genital).map(function (value) {
                            self.sistema_genital[value].value = self.GetMedicoFichaActive.metadata.sistema_genital[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.sistema_musculoesqueletico){
                Object.keys(self.sistema_musculoesqueletico).map(function (value) {
                            self.sistema_musculoesqueletico[value].value = self.GetMedicoFichaActive.metadata.sistema_musculoesqueletico[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.sistema_nervioso){
                Object.keys(self.sistema_nervioso).map(function (value) {
                            self.sistema_nervioso[value].value = self.GetMedicoFichaActive.metadata.sistema_nervioso[value] || '';
                            });
            }
            if(self.GetMedicoFichaActive.metadata.aumentos_masas){
                Object.keys(self.aumentos_masas).map(function (value) {
                            self.aumentos_masas[value].value = self.GetMedicoFichaActive.metadata.aumentos_masas[value] || '';
                            });
            }

        }
    }
},
mounted(){

this.emitter.emit("medico-ficha-load",['select_procedimiento','sistema_respiratorio','antecedentes_dieta','queja_principal','ojos','cabeza','piel','sistema_urinario','sistema_genital','sistema_musculoesqueletico','sistema_nervioso','aumentos_masas','veterinaria_otros'])  

  
},
methods:{
    saveVeterinaria(item,slug){
            const self = this;
            let itemsTosave={};
            
            switch (item) {
                case 1:
                itemsTosave[slug]=self.select_procedimiento;
                    break;
                case 2:
                    Object.keys(self[slug]).map(function (value) {
                        itemsTosave[value]=self[slug][value].value;
                    });
                    break;
                default:
                    return API.showErrorAlert('Item invalido AX-6775');
                
            }

            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug,
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",[slug]);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
        },
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_otros).map(function (value) {
                if (self.veterinaria_otros[value].value !== '') arrToSave[value] = self.veterinaria_otros[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_otros',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['veterinaria_otros']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
}
}
</script>
