<script>
import {DatePicker} from 'v-calendar';
import {mapActions,mapGetters} from "vuex";
import API from "src/core/Api";
import Box from "src/modules/medico/views/fichas/Box.vue";
 
export default { 
 data() {
        return {
            
            prenatal:{
                fur: '',
                edad_gestacional: '',
                sistemas: '',
                apetito: '',
                nauseas: '',
                vomitos: '',
                mareos: '',
                acidez: '',
                reflujo: '',
                diarrea: '',
                estrenimiento: '',
                dolor: '',
                cefalea: '',
                edema: '',
                fosfenos: '',
                orina: '',
                flujo: '',
                hemorragia: '',
                calambres: '',
                movimientos_fetales: '',
                disnea: '',
                medicamentos: '',
                indicaciones: '',
            },

            examen_fisico:{
                anos:'',
                peso: '',
                presion_arterial: '',
                frecuencia_cardiaca: '',
                temperatura: '',
                altura_uterina: '',
                frecuencia_cardiaca_fetal: '',
                presentacion: '',
                altura: '',
                ginecologico: '',
                tacto_vaginal: '',
                edema: '',
            }
        
 }},
 mounted(){

    this.emitter.emit("medico-ficha-load",['prenatal','examen_fisico'])

},
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha'
        })
    },
 watch:{
    GetReloadFicha(newValue, oldValue) {
        if (newValue === false && oldValue === true) {

            const self = this;

            Object.keys(self.prenatal).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.prenatal!= undefined){
                        if (self.GetMedicoFichaActive.metadata.prenatal[value]!= undefined) self.prenatal[value] = self.GetMedicoFichaActive.metadata.prenatal[value];
                    }
                    });

                
            Object.keys(self.examen_fisico).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.examen_fisico!= undefined){
                        if (self.GetMedicoFichaActive.metadata.examen_fisico[value]!= undefined) self.examen_fisico[value] = self.GetMedicoFichaActive.metadata.examen_fisico[value];
                    }
                    });

                //CARGO LOS DATOS DE PRECLINICA SI NO EXISTEN EN PRENATAL
                if(!self.examen_fisico.anos && self.GetMedicoFichaActive.cliente.edad!== undefined){
                    self.examen_fisico.anos = self.GetMedicoFichaActive.cliente.edad;
                }

                if(!self.examen_fisico.peso && self.GetMedicoFichaActive.metadata.datos_paciente.peso!== undefined){
                    self.examen_fisico.peso = self.GetMedicoFichaActive.metadata.datos_paciente.peso;
                }

                if(!self.examen_fisico.altura && self.GetMedicoFichaActive.metadata.datos_paciente.talla!== undefined){
                    self.examen_fisico.altura = self.GetMedicoFichaActive.metadata.datos_paciente.talla;
                }
                
                if(!self.examen_fisico.temperatura && self.GetMedicoFichaActive.metadata.datos_paciente.temperatura!== undefined && self.GetMedicoFichaActive.metadata.datos_paciente.tempLocation!== undefined){
                    self.examen_fisico.temperatura = self.GetMedicoFichaActive.metadata.datos_paciente.temperatura + ' ' + self.GetMedicoFichaActive.metadata.datos_paciente.tempLocation ;
                }
        }
    }

 },
 components:{
  Box,
  DatePicker
 },
 methods: {
    savePrenatal(){
        const self = this;
        console.log( self.prenatal );
        API.send('POST', 'orders/metadata/save/', {
            orderId: self.GetMedicoFichaActive.orden.id,
            slug: 'prenatal',
            detail: self.prenatal,
            uniqueMeta: 1,
        }, function (response) {
            API.showSuccessAlert(response.msg);
            self.emitter.emit("medico-ficha-notify",['prenatal']);
        },
        function (response) {
            API.showErrorAlert(response.msg);
        });
    },
    saveExamenFisico(){
        const self = this;
    
        API.send('POST', 'orders/metadata/save/', {
            orderId: self.GetMedicoFichaActive.orden.id,
            slug: 'examen_fisico',
            detail: self.examen_fisico,
            uniqueMeta: 1,
        }, function (response) {
            API.showSuccessAlert(response.msg);
            self.emitter.emit("medico-ficha-notify",['examen_fisico']);
        },
        function (response) {
            API.showErrorAlert(response.msg);
        });
    }
}
}
</script>
 
 
<template>
  <box  title="Examen fisico" icon="fa-solid fa-person-dress" :contraction="true">
    <section class="row">
        <div class="form-group col-12 col-md-3">
            <label>Años</label>
            <input type="number" class="form-control" v-model="examen_fisico.anos" @change="saveExamenFisico"/>
        </div>
         <!-- Peso -->
        <div class="form-group col-12 col-md-3">
            <label>Peso (lb)</label>
            <input type="text" class="form-control" v-model="examen_fisico.peso" @change="saveExamenFisico"/>
        </div>
         <!-- Altura -->
         <div class="form-group col-12 col-md-3">
            <label>Altura (cm)</label>
            <input type="text" class="form-control" v-model="examen_fisico.altura" @change="saveExamenFisico"/>
        </div>
        <!-- Temperatura (Tº) -->
        <div class="form-group col-12 col-md-3">
            <label>Temperatura (Cº)</label>
            <input type="text" class="form-control" v-model="examen_fisico.temperatura" @change="saveExamenFisico"/>
        </div>
        <!-- Presión Arterial (P/A) -->
        <div class="form-group col-12 col-md-3">
            <label>Presión Arterial (P/A)</label>
            <input type="text" class="form-control" v-model="examen_fisico.presion_arterial" @change="saveExamenFisico"/>
        </div>
        <!-- Frecuencia Cardiaca (FC) -->
        <div class="form-group col-12 col-md-3">
            <label>Frecuencia Cardiaca (FC)</label>
            <input type="text" class="form-control" v-model="examen_fisico.frecuencia_cardiaca" @change="saveExamenFisico"/>
        </div>
           <!-- Altura Uterina (AU) -->
           <div class="form-group col-12 col-md-3">
            <label>Altura Uterina (AU)</label>
            <input type="text" class="form-control" v-model="examen_fisico.altura_uterina" @change="saveExamenFisico"/>
        </div>
        <!-- Frecuencia Cardiaca Fetal (FCF) -->
        <div class="form-group col-12 col-md-3">
            <label>Frecuencia Cardiaca Fetal (FCF)</label>
            <input type="text" class="form-control" v-model="examen_fisico.frecuencia_cardiaca_fetal" @change="saveExamenFisico"/>
        </div>
        <!-- Presentación -->
        <div class="form-group col-12 col-md-3">
            <label>Presentación</label>
            <input type="text" class="form-control" v-model="examen_fisico.presentacion" @change="saveExamenFisico"/>
        </div>
         <!-- Ginecológico -->
         <div class="form-group col-12 col-md-3">
            <label>Ginecológico</label>
            <input type="text" class="form-control" v-model="examen_fisico.ginecologico" @change="saveExamenFisico"/>
        </div>
        <!-- Tacto Vaginal (TV) -->
        <div class="form-group col-12 col-md-3">
            <label>Tacto Vaginal (TV)</label>
            <input type="text" class="form-control" v-model="examen_fisico.tacto_vaginal" @change="saveExamenFisico"/>
        </div>
        <!-- Edema -->
        <div class="form-group col-12 col-md-3">
            <label>Edema</label>
            <input type="text" class="form-control" v-model="examen_fisico.edema" @change="saveExamenFisico"/>
        </div>
    </section>
  </box>

  <box  title="Prenatal" icon="fa-solid fa-person-pregnant" :contraction="true">
    <section class="row">
        <!-- Sistemas -->
        <div class="form-group col-12">
            <label>Sistemas</label>
            <textarea v-model="prenatal.sistemas" class="form-control" placeholder="Escribe aquí" @change="savePrenatal"></textarea>
        </div>
        <!-- Fecha de Última Regla -->
        <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Fecha de Última Regla (FUR)</label>
                    <date-picker v-model="prenatal.fur" mode="date" @dayclick="savePrenatal"  :timezone="'UTC'" >
                        <template #default="{ inputValue, inputEvents }">
                            <input :value="inputValue" v-on="inputEvents" class="form-control" @change="savePrenatal"/>
                        </template>
                    </date-picker>
                </div>
            </div>
    
        <!-- Edad Gestacional -->
        <div class="form-group col-12 col-md-3">
            <label>Edad Gestacional (EG)</label>
            <input type="text" class="form-control" v-model="prenatal.edad_gestacional" @change="savePrenatal"/>
        </div>

        <!-- Apetito -->
        <div class="form-group col-12 col-md-3">
            <label>Apetito</label>
            <input type="text" class="form-control" v-model="prenatal.apetito" @change="savePrenatal"/>
        </div>
        <!-- Náuseas -->
        <div class="form-group col-12 col-md-3">
            <label>Náuseas</label>
            <input type="text" class="form-control" v-model="prenatal.nauseas" @change="savePrenatal"/>
        </div>
        <!-- Vómitos -->
        <div class="form-group col-12 col-md-3">
            <label>Vómitos</label>
            <input type="text" class="form-control" v-model="prenatal.vomitos" @change="savePrenatal"/>
        </div>

        <!-- Mareos -->
        <div class="form-group col-12 col-md-3">
            <label>Mareos</label>
            <input type="text" class="form-control" v-model="prenatal.mareos" @change="savePrenatal"/>
        </div>
        <!-- Acidez -->
        <div class="form-group col-12 col-md-3">
            <label>Acidez</label>
            <input type="text" class="form-control" v-model="prenatal.acidez" @change="savePrenatal"/>
        </div>
        <!-- Reflujo -->
        <div class="form-group col-12 col-md-3">
            <label>Reflujo</label>
            <input type="text" class="form-control" v-model="prenatal.reflujo" @change="savePrenatal"/>
        </div>

        <!-- Diarrea -->
        <div class="form-group col-12 col-md-3">
            <label>Diarrea</label>
            <input type="text" class="form-control" v-model="prenatal.diarrea" @change="savePrenatal"/>
        </div>
        <!-- Estreñimiento -->
        <div class="form-group col-12 col-md-3">
            <label>Estreñimiento</label>
            <input type="text" class="form-control" v-model="prenatal.estrenimiento" @change="savePrenatal"/>
        </div>
        <!-- Dolor -->
        <div class="form-group col-12 col-md-3">
            <label>Dolor</label>
            <input type="text" class="form-control" v-model="prenatal.dolor" @change="savePrenatal"/>
        </div>

        <!-- Cefalea -->
        <div class="form-group col-12 col-md-3">
            <label>Cefalea</label>
            <input type="text" class="form-control" v-model="prenatal.cefalea" @change="savePrenatal"/>
        </div>
        <!-- Edema -->
        <div class="form-group col-12 col-md-3">
            <label>Edema</label>
            <input type="text" class="form-control" v-model="prenatal.edema" @change="savePrenatal"/>
        </div>
        <!-- Fosfenos -->
        <div class="form-group col-12 col-md-3">
            <label>Fosfenos</label>
            <input type="text" class="form-control" v-model="prenatal.fosfenos" @change="savePrenatal"/>
        </div>

        <!-- Orina -->
        <div class="form-group col-12 col-md-3">
            <label>Orina</label>
            <input type="text" class="form-control" v-model="prenatal.orina" @change="savePrenatal"/>
        </div>
        <!-- Flujo -->
        <div class="form-group col-12 col-md-3">
            <label>Flujo</label>
            <input type="text" class="form-control" v-model="prenatal.flujo" @change="savePrenatal"/>
        </div>
        <!-- Hemorragia -->
        <div class="form-group col-12 col-md-3">
            <label>Hemorragia</label>
            <input type="text" class="form-control" v-model="prenatal.hemorragia" @change="savePrenatal"/>
        </div>

        <!-- Calambres -->
        <div class="form-group col-12 col-md-3">
            <label>Calambres</label>
            <input type="text" class="form-control" v-model="prenatal.calambres" @change="savePrenatal"/>
        </div>
        <!-- Movimientos Fetales -->
        <div class="form-group col-12 col-md-3">
            <label>Movimientos Fetales</label>
            <input type="text" class="form-control" v-model="prenatal.movimientos_fetales" @change="savePrenatal"/>
        </div>
        <!-- Disnea -->
        <div class="form-group col-12 col-md-3">
            <label>Disnea</label>
            <input type="text" class="form-control" v-model="prenatal.disnea" @change="savePrenatal"/>
        </div>

        <div class="form-group col-12">
            <label>Medicamentos</label>
            <textarea v-model="prenatal.medicamentos" class="form-control" placeholder="Escribe aquí" @change="savePrenatal"></textarea>
        </div>
            
    </section>

    <section class="row">
        <!-- Indicaciones -->
        <div class="form-group col-12">
            <label>Indicaciones</label>
            <textarea v-model="prenatal.indicaciones" class="form-control" placeholder="Escribe aquí" @change="savePrenatal"></textarea>
        </div>
    </section>

  </box>
</template>
 
 
<style scoped>
 
 textarea{
    min-height: 150px;
    max-height: 500px;
 }
</style>