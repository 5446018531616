
<template>
          <box title="Prenatales" icon="fas fa-clock">
              <div class="row">
                  <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_prenatales" :key="item.name">
                      <Field :data="item" v-model="item.value" @change="savePrenatales"></Field>
                  </div>
              </div>
          </box>
          <box title="Datos del nacimiento" icon="fas fa-baby">
              <div class="row">
                  <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_natales" :key="item.name">
                      <Field :data="item" v-model="item.value" @change="saveNatales"></Field>
                  </div>
              </div>
          </box>
          <box title="Alimentación" icon="fas fa-utensils">
              <div class="row">
                  <div class="col-12 mb-3" v-for="item in pediatria_alimentacion" :key="item.name">
                      <Field :data="item" v-model="item.value" @change="saveAlimentacion"></Field>
                  </div>
              </div>
          </box>
          <box title="Otros" icon="fas fa-file-alt">
              <div class="row">
                  <div class="col-12 col-sm-6 mb-3" v-for="item in pediatria_otros" :key="item.name">
                      <Field :data="item" v-model="item.value" @change="saveOtros"></Field>
                  </div>
              </div>
          </box>
          <box title="Gráficos" icon="fas fa-chart-pie">
              <div class="text-muted mb-4">
                  Atención, no se mostrarán gráficos que no posean información dentro de sus parámetros
              </div>
  
              <div v-if="Object.keys(weightForAge).length > 0">
                  <h6>{{weightForAge.title}}</h6>
                  <div class="row">
                      <div class="col-12 col-sm-6" v-for="item in weightForAge" :key="item">
                          <highcharts :options="item.data" class="mb-4"></highcharts>
                      </div>
                  </div>
              </div>
              <div v-if="Object.keys(weightForLengthHeight).length > 0">
                  <h6>{{weightForLengthHeight.title}}</h6>
                  <div class="row">
                      <div class="col-12 col-sm-6" v-for="item in weightForLengthHeight" :key="item">
                          <highcharts :options="item.data" class="mb-4"></highcharts>
                      </div>
                  </div>
              </div>
              <div v-if="Object.keys(lengthHeightForAge).length > 0">
                  <h6>{{lengthHeightForAge.title}}</h6>
                  <div class="row">
                      <div class="col-12 col-sm-6" v-for="item in lengthHeightForAge" :key="item">
                          <highcharts :options="item.data" class="mb-4"></highcharts>
                      </div>
                  </div>
              </div>
          </box>
          <box title="Vacunas" icon="fas fa-syringe">
              <table class="table">
                  <tr>
                      <td class="font-weight-bold">
                          Vacuna
                      </td>
                      <td class="font-weight-bold">Fecha #1</td>
                      <td class="font-weight-bold">Fecha #2</td>
                      <td class="font-weight-bold">Fecha #3</td>
                      <td class="font-weight-bold">Fecha #4</td>
                      <td class="font-weight-bold">Fecha #5</td>
                  </tr>
                  <tr v-for="item in pediatria_vacunas.vacunas" :key="item" class="mb-3">
                      <td>
                          <div v-if="!item.editable">
                              {{item.nombre}}
                          </div>
                          <div v-else>
                              <input type="text" class="form-control" v-model="item.nombre" @change="saveVacunas"/>
                          </div>
                      </td>
                      <td>
                          <input type="date" class="form-control" v-model="item.f1" @change="saveVacunas"/>
                      </td>
                      <td>
                          <input type="date" class="form-control" v-model="item.f2" @change="saveVacunas"/>
                      </td>
                      <td>
                          <input type="date" class="form-control" v-model="item.f3" @change="saveVacunas"/>
                      </td>
                      <td>
                          <input type="date" class="form-control" v-model="item.f4" @change="saveVacunas"/>
                      </td>
                      <td>
                          <input type="date" class="form-control" v-model="item.f5" @change="saveVacunas"/>
                      </td>
                  </tr>
              </table>
              <div class="mt-3">
                  <div class="form-group">
                      <label>Observaciones sobre vacunas</label>
                      <textarea class="form-control w-100" v-model="pediatria_vacunas.observaciones"  @change="saveVacunas"></textarea>
                  </div>
              </div>
          </box>
</template>
   

<script>
// Component
import {mapGetters} from "vuex";
import API from "src/core/Api";
import Box from "src/modules/medico/views/fichas/Box.vue";
// Grafics
import {Chart} from 'highcharts-vue';
import '@vueform/slider/themes/default.css';
import Field from '/src/modules/medico/views/fichas/Field.vue';

// Methods
import dayjs from "dayjs";
import Numbro from "numbro";

export default { 
 data() {
        return {
            pediatria_prenatales: {
                edad_materna: {
                    name: 'Edad Materna',
                    type: 'text',
                    value: '',
                },
                numero_de_gestas: {
                    name: 'Número de gestas',
                    type: 'text',
                    value: '',
                },
                metodos_de_planificacion_familiar: {
                    name: 'Métodos de planificación familiar',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                complicaciones_durante_el_embarazo: {
                    name: 'Complicaciones durante el embarazo',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                duracion_del_embarazo: {
                    name: 'Duración del embarazo',
                    type: 'text',
                    value: '',
                },
            },
            pediatria_natales: {
                duracion_del_parto: {
                    name: 'Duración del parto',
                    type: 'text',
                    value: '',
                },
                notas: {
                    name: 'Notas',
                    type: 'text',
                    value: '',
                },
                parto_atendido: {
                    name: 'Parto atendido',
                    type: 'select',
                    value: '',
                    options: [
                        'Hospital',
                        'Médico Particular',
                        'Comadrona',
                    ]
                },
                parto_atendido_por: {
                    name: 'Nombre de quien atendió el parto',
                    type: 'text',
                    value: '',
                },
                parto_atendido_lugar: {
                    name: 'Lugar donde se realizó el parto',
                    type: 'text',
                    value: '',
                },
                peso_al_nacer: {
                    name: 'Peso al nacer (kg)',
                    type: 'text',
                    value: '',
                },
                peso_observaciones: {
                    name: 'Observaciones sobre el peso',
                    type: 'text',
                    value: '',
                },
                oxigeno_suplementario: {
                    name: 'Oxígeno suplementario',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                necesidad_de_cuidado_intensivo: {
                    name: 'Necesidad de cuidado intensivo',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                apgar_minuto: {
                    name: 'APGAR al minuto',
                    type: 'number',
                    value: '',
                },
                apgar_cinco_minutos: {
                    name: 'APGAR a los 5 minutos',
                    type: 'number',
                    value: '',
                },
                apgar_diez_minutos: {
                    name: 'APGAR a los 10 minutos',
                    type: 'number',
                    value: '',
                },
            },
            pediatria_vacunas: {
                vacunas: {
                    BCG: {
                        nombre: "BCG",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    hexavalente: {
                        nombre: "Hexavalente",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    rotavirus1: {
                        nombre: "Rotavirus (Rotarix)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    rotavirus2: {
                        nombre: "Rotavirus (Rotatateq)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    neumococo: {
                        nombre: "Neumococo",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    MMR: {
                        nombre: "MMR (SRP)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    hepatitisA: {
                        nombre: "Hepatitis A",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    influenza: {
                        nombre: "Influenza",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    varicela: {
                        nombre: "Varicela",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    meningococo: {
                        nombre: "Meningococo",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    papiloma: {
                        nombre: "Papiloma",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    boostrix: {
                        nombre: "Boostrix (D.T./T.)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    fiebreAmarilla: {
                        nombre: "Fiebre Amarilla",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    neumo23: {
                        nombre: "Neumo 23",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    toxoIde: {
                        nombre: "Toxoide",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    hepatitisB: {
                        nombre: "Hepatitis B",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    dtp: {
                        nombre: "DTP",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    polio: {
                        nombre: "Polio",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    haemophilusInfluenzaeB: {
                        nombre: "Haemophilus Influenzae B",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    tefoidea: {
                        nombre: "Tefoidea",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    antirapica: {
                        nombre: "Antirapica",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    otraUno: {
                        nombre: "",
                        editable: true,
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    otraDos: {
                        nombre: "",
                        editable: true,
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                },
                observaciones: '',
            },
            pediatria_alimentacion: {
                alimentacion: {
                    name: 'Alimentación',
                    type: 'textarea',
                    value: '',
                },
            },
            pediatria_otros: {
                habitos: {
                    name: 'Hábitos',
                    type: 'textarea',
                    value: '',
                },
                observaciones: {
                    name: 'Observaciones',
                    type: 'textarea',
                    value: '',
                },
            },

             // graficos
             weightForAge: {},
            weightForLengthHeight: {},
            lengthHeightForAge: {},
            BMIForAge: false,        
 }},
 mounted(){
    const self = this;
    this.emitter.emit("medico-ficha-load",['pediatria_vacunas','pediatria_otros','pediatria_alimentacion','pediatria_natales', 'pediatria_prenatales'])

    self.drawChart();
},
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        })
    },
watch: {
    GetReloadFicha(newValue, oldValue) {
        if (newValue === false && oldValue === true) {
            const self = this;
            // Cargo la info de prenatales
            Object.keys(self.pediatria_prenatales).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pediatria_prenatales) {
                    if (self.GetMedicoFichaActive.metadata.pediatria_prenatales[value]) {
                        self.pediatria_prenatales[value].value = self.GetMedicoFichaActive.metadata.pediatria_prenatales[value];
                    }
                }
            });
            // Cargo la info de natales
            Object.keys(self.pediatria_natales).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pediatria_natales) {
                    if (self.GetMedicoFichaActive.metadata.pediatria_natales[value]) {
                        self.pediatria_natales[value].value = self.GetMedicoFichaActive.metadata.pediatria_natales[value];
                    }
                }
            });


            // Cargo la info de pediatria
            Object.keys(self.pediatria_alimentacion).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pediatria_alimentacion) {
                    if (self.GetMedicoFichaActive.metadata.pediatria_alimentacion[value]) {
                        self.pediatria_alimentacion[value].value = self.GetMedicoFichaActive.metadata.pediatria_alimentacion[value];
                    }
                }
            });

            // Cargo la info de pediatria
            Object.keys(self.pediatria_otros).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pediatria_otros) {
                    if (self.GetMedicoFichaActive.metadata.pediatria_otros[value]) {
                        self.pediatria_otros[value].value = self.GetMedicoFichaActive.metadata.pediatria_otros[value];
                    }
                }
            });

            // Cargo la info de vacunas
            Object.keys(self.pediatria_vacunas.vacunas).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas) {
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f1']) self.pediatria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f1'];
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f2']) self.pediatria_vacunas.vacunas[value].f2 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f2'];
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f3']) self.pediatria_vacunas.vacunas[value].f3 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f3'];
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f4']) self.pediatria_vacunas.vacunas[value].f4 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f4'];
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f5']) self.pediatria_vacunas.vacunas[value].f5 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f5'];
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_n']) self.pediatria_vacunas.vacunas[value].nombre = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_n'];
                    if (self.GetMedicoFichaActive.metadata.pediatria_vacunas['observaciones']) self.pediatria_vacunas['observaciones'] = self.GetMedicoFichaActive.metadata.pediatria_vacunas['observaciones'];
                }
            });

            }
    },
    'GetMedicoFichaActive.datos_paciente.peso': function (value) {
            this.drawChart();
    }
},        
 components:{
  Box,
  Field,
  highcharts: Chart,

 },
 methods: {
    dayjs,
    Numbro,
    savePrenatales() {
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.pediatria_prenatales).map(function (value) {
            if (self.pediatria_prenatales[value].value !== '') arrToSave[value] = self.pediatria_prenatales[value].value;
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'pediatria_prenatales',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify", ['pediatria_prenatales'])
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveNatales() {
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.pediatria_natales).map(function (value) {
            if (self.pediatria_natales[value].value !== '') arrToSave[value] = self.pediatria_natales[value].value;
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'pediatria_natales',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify", ['pediatria_natales'])
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveAlimentacion() {
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.pediatria_alimentacion).map(function (value) {
            if (self.pediatria_alimentacion[value].value !== '') arrToSave[value] = self.pediatria_alimentacion[value].value;
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'pediatria_alimentacion',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify", ['pediatria_alimentacion'])
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveOtros() {
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.pediatria_otros).map(function (value) {
            if (self.pediatria_otros[value].value !== '') arrToSave[value] = self.pediatria_otros[value].value;
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'pediatria_otros',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify", ['pediatria_otros'])
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveVacunas() {
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.pediatria_vacunas.vacunas).map(function (value) {
            if (self.pediatria_vacunas.vacunas[value].f1 !== '') arrToSave[value + '_f1'] = self.pediatria_vacunas.vacunas[value].f1;
            if (self.pediatria_vacunas.vacunas[value].f2 !== '') arrToSave[value + '_f2'] = self.pediatria_vacunas.vacunas[value].f2;
            if (self.pediatria_vacunas.vacunas[value].f3 !== '') arrToSave[value + '_f3'] = self.pediatria_vacunas.vacunas[value].f3;
            if (self.pediatria_vacunas.vacunas[value].f4 !== '') arrToSave[value + '_f4'] = self.pediatria_vacunas.vacunas[value].f4;
            if (self.pediatria_vacunas.vacunas[value].f5 !== '') arrToSave[value + '_f5'] = self.pediatria_vacunas.vacunas[value].f5;

            if (self.pediatria_vacunas.vacunas[value].editable) {
                if (self.pediatria_vacunas.vacunas[value].nombre !== '') arrToSave[value + '_n'] = self.pediatria_vacunas.vacunas[value].nombre;
            }
        });
        arrToSave['observaciones'] = self.pediatria_vacunas['observaciones'];

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'pediatria_vacunas',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify", ['pediatria_vacunas'])
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },


     // Gráficos
     drawChart() {

    const self = this;
    //const peso = (typeof self.GetMedicoFichaActive.cliente.peso !== 'undefined') ? self.GetMedicoFichaActive.cliente.peso : false;
    const clienteId = (typeof self.GetMedicoFichaActive.cliente.id !== 'undefined') ? self.GetMedicoFichaActive.cliente.id : false;
    /*const talla = (typeof self.GetMedicoFichaActive.metadata !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente.talla !== 'undefined') ? self.GetMedicoFichaActive.metadata.datos_paciente.talla : false;
    const genero = (typeof self.GetMedicoFichaActive.cliente.genero !== 'undefined') ? self.GetMedicoFichaActive.cliente.genero : false;
    const fechaNacimiento = (typeof self.GetMedicoFichaActive.cliente.fechaNacimiento !== 'undefined') ? self.GetMedicoFichaActive.cliente.fechaNacimiento : false;
    const fechaComparativa = (typeof self.GetMedicoFichaActive.reserva.fechaReservada !== 'undefined') ? self.GetMedicoFichaActive.reserva.fechaReservada : false;*/

    API.send('POST', 'medico/utilities/oms-anthropometric-indicators/by/cliente', {
            'clienteId': clienteId,
        }, function (response) {
            self.BMIForAge = response.data.BMIForAge;
            self.weightForAge = response.data.weightForAge;
            self.weightForLengthHeight = response.data.weightForLengthHeight;
            self.lengthHeightForAge = response.data.lengthHeightForAge;
        },
        function (response) {
            API.showErrorAlert(response.msg);
        });
    },
    }
}
</script>