<template>
    <div class="container mt-4">
        <div class="card shadow-sm">
            <div class="card-header bg-primary text-white">
                <h2 class="card-title mb-0">Procesar Requerimientos de Pólizas</h2>
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group">
                        <label for="fileUpload" class="form-label">Selecciona el archivo Excel:</label>
                        <input type="file" id="fileUpload" class="form-control mb-3" @change="handleFileUpload" />
                    </div>
                    <button type="button" class="btn btn-primary w-100" @click="processPolicies">
                        Procesar Pólizas
                    </button>
                </form>

                <!-- Animación de progreso -->
                <div v-if="isProcessing" class="d-flex flex-column align-items-center mt-4">
                    <div class="progress-circle">
                        <svg viewBox="0 0 36 36">
                            <path
                                    class="circle-bg"
                                    d="M18 2.0845
                                   a 15.9155 15.9155 0 0 1 0 31.831
                                   a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                    class="circle"
                                    :style="{ strokeDasharray: `${progressPercentage}, 100` }"
                                    d="M18 2.0845
                                   a 15.9155 15.9155 0 0 1 0 31.831
                                   a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                        </svg>
                        <span class="circle-text">{{ progressPercentage.toFixed(0) }}%</span>
                    </div>
                    <p class="text-center mt-3">Tiempo restante: {{ estimatedTime }} segundos</p>
                </div>

                <!-- Resultados -->
                <div v-if="jsonResults.length" class="mt-4">
                    <div class="alert alert-success text-center" role="alert">
                        <strong>¡Procesamiento completado!</strong> Puedes descargar los archivos generados a continuación.
                    </div>
                    <div class="d-flex justify-content-around">
                        <button class="btn btn-outline-info" @click="downloadJSON">
                            Descargar JSON
                        </button>
                        <button class="btn btn-outline-success" @click="downloadExcel">
                            Descargar Excel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";

export default {
    data() {
        return {
            policies: [],
            jsonResults: [],
            isProcessing: false,
            progressPercentage: 0,
            estimatedTime: 0,
            startTime: null,
            token: "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoie1xuICBcInVzdWFyaW9cIiA6IFwibWhlcnJlcmFAYWJrYnVzaW5lc3MuY29tXCIsXG4gIFwibm9tYnJlXCIgOiBcIiBNQVJJTyBIRVJSRVJBIFwiLFxuICBcInBlcmZpbFwiIDogXCJBR0VOVEVcIixcbiAgXCJleHBpcmFcIiA6IFwiV2VkIE5vdiAyNyAxODowMDoyMyBDU1QgMjAyNFwiLFxuICBcImxhcHNvXCIgOiBcIjE4MFwiXG59IiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTczMjc0MTIyMywiZXhwIjoxNzMyNzUyMDIzfQ.OrcNDvb9yd_su7FDImRMt70_0HKUkZG594KF70hJxaIYidy2cljc7NQi7hhQomCcH0PunI6C787fpcEWmTZw3A",
        };
    },
    methods: {
        async handleFileUpload(event) {
            const file = event.target.files[0];
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            this.policies = XLSX.utils.sheet_to_json(worksheet);
        },
        async processPolicies() {
            this.isProcessing = true;
            this.startTime = new Date(); // Registrar el inicio del procesamiento
            const totalPolicies = this.policies.length;
            let totalGlobal = 0;
            const globalRequerimientos = [];

            for (let i = 0; i < totalPolicies; i++) {
                const policy = this.policies[i];
                const pPoliza = policy["NUM POL"];
                const encargosCobranza = parseInt(policy["Encargos Cobranza"]);
                const montoTotal = parseFloat(policy["Suma de Pago"]);
                const url = `https://agentes.elroble.com/Cobros/v1/findRequerimientosByPoliza?pPoliza=${pPoliza}&pProducto=AEPH&pAfiliada=0`;

                try {
                    const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    });

                    const requerimientos = response.data;

                    if (requerimientos.data && requerimientos.data.length > 0) {
                        const montoPorPago = montoTotal / encargosCobranza;
                        const distribuidos = this.generateRequerimientos(
                            requerimientos.data,
                            montoPorPago,
                            encargosCobranza
                        );
                        globalRequerimientos.push(...distribuidos);
                        totalGlobal += montoTotal;
                    } else {
                        console.warn(`No se encontraron requerimientos para la póliza ${pPoliza}`);
                    }

                    // Actualizar progreso y tiempo estimado
                    this.progressPercentage = ((i + 1) / totalPolicies) * 100;
                    const elapsedTime = (new Date() - this.startTime) / 1000; // En segundos
                    const averageTimePerPolicy = elapsedTime / (i + 1);
                    this.estimatedTime = Math.max(
                        Math.round(averageTimePerPolicy * (totalPolicies - (i + 1))),
                        0
                    );
                } catch (error) {
                    console.error(`Error procesando la póliza ${pPoliza}:`, error);
                }
            }

            const formattedResult = {
                total: totalGlobal.toFixed(2),
                requerimientos: globalRequerimientos,
            };

            this.jsonResults = [formattedResult];
            this.isProcessing = false; // Finalizar el indicador de procesamiento
        },
        generateRequerimientos(requerimientos, montoPorPago, encargosCobranza) {
            const distribuidos = [];
            const requerimientosAgregados = new Set();

            for (let i = 0; i < requerimientos.length && distribuidos.length < encargosCobranza; i++) {
                const requerimiento = requerimientos[i];
                if (!requerimientosAgregados.has(requerimiento.requerimiento)) {
                    distribuidos.push({
                        numero: requerimiento.requerimiento,
                        serie: requerimiento.serie,
                        monto: montoPorPago.toFixed(2),
                    });
                    requerimientosAgregados.add(requerimiento.requerimiento);
                }
            }

            return distribuidos;
        },
        downloadJSON() {
            const jsonString = JSON.stringify(this.jsonResults, null, 2);
            const blob = new Blob([jsonString], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "RequerimientosGlobales.json";
            link.click();
            URL.revokeObjectURL(url);
        },
        downloadExcel() {
            const worksheet = XLSX.utils.json_to_sheet(this.jsonResults);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "RequerimientosGlobales");
            XLSX.writeFile(workbook, "RequerimientosGlobales.xlsx");
        },
    },
};
</script>

<style>
/* Estilos para el círculo de progreso */
.progress-circle {
    position: relative;
    width: 100px;
    height: 100px;
}

svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
}

.circle-bg {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke: #007bff;
    stroke-width: 3.8;
    stroke-linecap: round;
    stroke-dasharray: 0, 100;
    transition: stroke-dasharray 0.3s ease;
}

.circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
}
</style>
