<template>
    <control-comercios-layout :active="60">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Cambiar Información</h4>
            </div>
            <div class="card-body">
                <!-- Búsqueda por DPI o SKU -->
                <div class="mb-3">
                    <div class="input-group">
                        <!-- Input para DPI -->
                        <span class="input-group-text p-1 bg-transparent border-0">
                            <i class="fas fa-id-card" style="font-size: 1.2rem; color: var(--primary-color);"></i>
                        </span>
                        <input type="text" id="dpi-search" v-model="search.dpi" class="form-control" placeholder="DPI" />

                        <!-- Input para SKU -->
                        <span class="input-group-text p-1 bg-transparent border-0">
                            <i class="fas fa-box" style="font-size: 1.2rem; color: var(--primary-color);"></i>
                        </span>
                        <input type="text" id="sku-search" v-model="search.sku" class="form-control" placeholder="SKU" />

                        <!-- Botón de Buscar -->
                        <button @click="searchData" class="btn btn-primary p-2">
                            <i class="fas fa-search" style="font-size: 1.2rem;"></i>
                        </button>
                    </div>
                </div>


                <!-- Información para editar -->
                <div v-if="formLoaded">
                    <form>
                        <div class="row g-3">
                            <!-- Información Personal -->
                            <div class="col-md-6">
                                <h6 class="text-primary mb-2">Información Personal</h6>
                                <div class="row g-2">
                                    <div class="col-6">
                                        <input type="text" id="nombre" v-model="form.Nombre" class="form-control form-control-sm" placeholder="Nombre" />
                                        <label for="nombre" class="form-label small text-muted mt-1">Nombre</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="apellido" v-model="form.Apellido" class="form-control form-control-sm" placeholder="Apellido" />
                                        <label for="apellido" class="form-label small text-muted mt-1">Apellido</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="segundo-apellido" v-model="form['Segundo Apellido']" class="form-control form-control-sm" placeholder="Segundo Apellido" />
                                        <label for="segundo-apellido" class="form-label small text-muted mt-1">Segundo Apellido</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="dpi" v-model="form.DPI" class="form-control form-control-sm" placeholder="DPI" />
                                        <label for="dpi" class="form-label small text-muted mt-1">DPI</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="date" id="fecha-nacimiento" v-model="form['Fecha de Nacimiento']" class="form-control form-control-sm" />
                                        <label for="fecha-nacimiento" class="form-label small text-muted mt-1">Fecha de Nacimiento</label>
                                    </div>
                                    <div class="col-6">
                                        <select id="genero" v-model="form.Genero" class="form-control form-select form-select-sm">
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                        </select>
                                        <label for="genero" class="form-label small text-muted mt-1">Género</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="email" id="correo" v-model="form['Correo electrónico']" class="form-control form-control-sm" placeholder="Correo Electrónico" />
                                        <label for="correo" class="form-label small text-muted mt-1">Correo Electrónico</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="telefono" v-model="form.Telefono" class="form-control form-control-sm" placeholder="Teléfono" />
                                        <label for="telefono" class="form-label small text-muted mt-1">Teléfono</label>
                                    </div>
                                </div>
                            </div>
                            <!-- Información de la Orden -->
                            <div class="col-md-6">
                                <h6 class="text-primary mb-2">Información de la Orden</h6>
                                <div class="row g-2">
                                    <div class="col-6">
                                        <input type="text" id="direccion" v-model="form.Direccion" class="form-control form-control-sm" placeholder="Dirección" />
                                        <label for="direccion" class="form-label small text-muted mt-1">Dirección</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="nit" v-model="form.NIT" class="form-control form-control-sm" placeholder="NIT" />
                                        <label for="nit" class="form-label small text-muted mt-1">NIT</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="ocupacion" v-model="form.Ocupacion" class="form-control form-control-sm" placeholder="Ocupación" />
                                        <label for="ocupacion" class="form-label small text-muted mt-1">Ocupación</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="frecuencia-pago" v-model="form['Frecuencia de pago']" class="form-control form-control-sm" placeholder="Frecuencia de Pago" />
                                        <label for="frecuencia-pago" class="form-label small text-muted mt-1">Frecuencia de Pago</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="sucursal" v-model="form.Sucursal" class="form-control form-control-sm" placeholder="Sucursal" />
                                        <label for="sucursal" class="form-label small text-muted mt-1">Sucursal</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="poliza" v-model="form['Póliza']" class="form-control form-control-sm" placeholder="Póliza" />
                                        <label for="poliza" class="form-label small text-muted mt-1">Póliza</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="number" id="monto" v-model="form.Monto" class="form-control form-control-sm" step="0.01" placeholder="Monto" />
                                        <label for="monto" class="form-label small text-muted mt-1">Monto</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Botones -->
                        <div class="d-flex justify-content-end mt-3">
                            <!-- Botón de Guardar -->
                            <button @click="saveData" type="button" class="btn btn-success btn-sm me-2">
                                <i class="fa fa-save"></i>
                            </button>

                            <!-- Botón de Reset -->
                            <button @click="resetForm" type="button" class="btn btn-secondary btn-sm me-2">
                                <i class="fa fa-times"></i>
                            </button>

                            <!-- Botón de Eliminar -->
                            <button @click="showDeleteModal" type="button" class="btn btn-danger btn-sm">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>



            <!-- Modal de confirmación para eliminar -->
            <div v-if="showModal" class="modal fade show d-block" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Confirmar Eliminación</h5>
                            <button type="button" class="btn-close" @click="closeModal">x</button>
                        </div>
                        <div class="modal-body">
                            <p>¿Estás seguro de que deseas eliminar esta información?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
                            <button type="button" class="btn btn-danger" @click="deleteData">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>
<style scoped>
/* Estilo base para los botones */
.btn {
    margin-right: 0.5rem;
    border-radius: 6px; /* Bordes suaves */
    font-size: 0.8rem; /* Tamaño moderado */
    padding: 0.1rem 0.1rem; /* Padding para un tamaño cómodo */
    border: 2px solid transparent; /* Borde transparente por defecto */
    transition: all 0.3s ease; /* Transición suave */
    text-align: center; /* Centrar el texto */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave para el efecto "crayon" */
}

/* Estilo de fondo de los botones */
.btn-success {
    background-color: #28a745;
    color: white;
    border-color: #28a745;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
    border-color: #6c757d;
}

.btn-danger {
    background-color: #dc3545;
    color: white;
    border-color: #dc3545;
}

/* Efectos al hacer hover */
.btn:hover {
    background-color: transparent;
    color: currentColor; /* Cambia el color del texto al color de borde */
    border-color: currentColor; /* El borde se pone del mismo color que el texto */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más profunda */
}

/* Estilo para los iconos dentro de los botones */
.btn i {
    font-size: 1.6rem; /* Ajuste el tamaño de los iconos */
    position: relative;
    top: -1px; /* Ajustar la posición del icono para alinearlo */
}
.form-control {
    background-color: transparent !important; /* Sin fondo */
    border: none !important; /* Elimina todos los bordes */
    border-bottom: 1px solid var(--primary-color) !important; /* Línea inferior como el cuaderno */
    box-shadow: none !important; /* Elimina cualquier sombra */
    font-family: 'Courier New', Courier, monospace; /* Fuente tipo máquina de escribir */
    color: #000; /* Color negro para el texto */
    padding: 0.1rem 0.1rem; /* Ajuste de padding */
    transition: border-color 0.3s ease !important; /* Transición suave para el borde */
}

.form-control:focus {
    border-bottom: 3px solid var(--primary-color) !important; /* Aumenta el grosor de la línea inferior cuando está enfocado */
    outline: none !important; /* Elimina el contorno del input */
}

.form-label.small {
    font-size: 0.8rem; /* Tamaño de letra pequeño */
}

.text-muted {
    color: #6c757d; /* Gris claro para el texto */
}
.input-group-text {
    border: none !important; /* Elimina bordes */
    background-color: transparent; /* Fondo transparente */
    padding: 0.5rem; /* Espaciado cómodo */
}
.btn-primary {
    border-radius: 0.25rem; /* Bordes redondeados para el botón */
    padding: 0.5rem 1rem; /* Ajuste de padding */
}

.btn-primary i {
    font-size: 1.2rem; /* Ajuste del tamaño del icono */
}

/* Ajuste para el label */
.form-label {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.60rem;
    color: #6c757d;
    font-weight: bold;
    margin-top: 0.1rem;
}

/* Agregar un toque más de "cuaderno" */
.form-control::placeholder {
    color: #999; /* Color de texto para los placeholders */
    font-style: italic; /* Estilo en cursiva para el placeholder */
}
</style>

<script>
import controlComerciosLayout from "../../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";

export default {
    components: {
        controlComerciosLayout,
    },
    data() {
        return {
            search: {
                dpi: "",
                sku: "",
            },
            form: {
                Nombre: "",
                Apellido: "",
                "Segundo Apellido": "",
                DPI: "",
                "Fecha de Nacimiento": "",
                Genero: "",
                "Correo electrónico": "",
                Telefono: "",
                Direccion: "",
                NIT: "",
                Ocupacion: "",
                "Frecuencia de pago": "",
                Sucursal: "",
                "Póliza": "",
                Monto: null,
            },
            formLoaded: false,
            showModal: false,
        };
    },
    methods: {
        resetForm() {
            // Restablece los datos del formulario
            Object.keys(this.form).forEach((key) => {
                this.form[key] = "";
            });
        },
        searchData() {
            const self = this;
            const searchUrl = 'orders/dpi-buscar/reimprimir/'+self.search.dpi+'/'+ self.search.sku;
            API.send(
                "GET",
                searchUrl,
                {},
                function (response) {
                    self.form = response.data;
                    self.formLoaded = true;
                    API.showSuccessNotify("Datos cargados correctamente.");
                },
                function (response) {
                    API.showErrorNotify("Error al buscar la información.");
                }
            );
        },
        saveData() {
            const self = this;
            API.send(
                "POST",
                "endpoint/guardar",
                self.form,
                function (response) {
                    API.showSuccessNotify("Datos guardados correctamente.");
                },
                function (response) {
                    API.showSuccessNotify("Datos guardados correctamente.");
                }
            );
        },
        deleteData() {
            const self = this;
            API.send(
                "DELETE",
                `endpoint/eliminar/${self.form.id}`,
                {},
                function (response) {
                    self.closeModal();
                    self.formLoaded = false;
                    API.showSuccessNotify("Datos eliminados correctamente.");
                },
                function (response) {
                    API.showErrorNotify("Error al eliminar los datos.");
                }
            );
        },
        showDeleteModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>
