 <template>
        <box title="Checklist del procedimiento" icon="fa-solid fa-check-double">
            <div class="d-flex justify-content-center w-100">
                <table class="table">
                    <thead class="thead-light">
                    <tr>
                        <th scope="col" class="font-weight-bold">
                            Preceso
                        </th>
                        <th scope="col" class="font-weight-bold">
                            <div class="center-option">
                                Cancelado
                            </div>
                        </th>
                        <th scope="col" class="font-weight-bold">
                            <div class="center-option">
                                Listo
                            </div>
                        </th>
                        <th scope="col" class="font-weight-bold">
                            <div class="center-option">
                                Observación
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="table-hover">
                    <tr v-for="item in veterinaria_checklist" class="inputGroup bgtr" :class="handleCheckStyle(item)">
                        <td>{{ item.name }}
                            <div v-if="item.detail" class="form-group" title="Nombre del proceso">
                                <textarea v-model="item.detailValue" class="form-control text-detail" placeholder="Ingese aqui observaciones adicionales..." @change="saveCheckProcess"></textarea>
                            </div>
                        </td>
                        <td>
                            <div class="center-option">
                                <label :for="`cancel${item.name}`" title="Cancelar proceso">

                                    <input :id="`cancel${item.name}`" class="cancel" name="cancel" type="checkbox" v-model="item.cancel" @change="handleCheckSelected(item, 'cancel')"/>
                                    <svg v-if="item.cancel" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                <path style="fill:#F05228;" d="M213.333,0C95.514,0,0,95.514,0,213.333s95.514,213.333,213.333,213.333  s213.333-95.514,213.333-213.333S331.153,0,213.333,0z M330.995,276.689l-54.302,54.306l-63.36-63.356l-63.36,63.36l-54.302-54.31  l63.356-63.356l-63.356-63.36l54.302-54.302l63.36,63.356l63.36-63.356l54.302,54.302l-63.356,63.36L330.995,276.689z"/>
                                </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                        <defs/>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                            <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                        </g>
                                    </svg>


                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="center-option">
                                <label :for="`check${item.name}`" title="Marcar proceso como listo">

                                    <input :id="`check${item.name}`" class="check" name="check" type="checkbox" v-model="item.check" @change="handleCheckSelected(item, 'check')"/>
                                    <svg  v-if="item.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                        <defs/>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                            <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                        </g>
                                    </svg>
                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="center-option p-1" @click="onChangeDetail(item)" title="Agregar observaciones adicionales">
                                <i v-if="!item.detail" class="fa-solid fa-chevron-down"></i>
                                <i v-if="item.detail" class="fa-solid fa-chevron-up"></i>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </box>
</template>  

<style scoped>


.check-style{
    color: rgb(252, 252, 252);
    background-color: #007bff;
    .center-option label svg{
        border-radius: 50%;
        background-color: aliceblue;
        opacity: 1;
    }
}

.cancel-style{
    background-color: #6c6c6c27;
    opacity: 0.6;
    text-decoration: line-through;
    .center-option label svg{
        border-radius: 50%;
        background-color: aliceblue;
        opacity: 1;
    }
}

.bgtr{
    transition: 0.2s ease-in;
}

.bgtr td {
    background-color: transparent;
}

.center-option{
   display: flex; 
   flex-direction: row;
   justify-content: center;
}

.cancel,
.check {
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    order: 1;
    z-index: 1;
    cursor: pointer;
    visibility: hidden;
}

.center-option label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 30px;
  height: 30px;
}


.center-option label svg {
    position: absolute;
    width: 25px;
    height: 25px;
}

.inputGroup {
    /* background-color: #fff;
    display: block;
    margin: 10px 0; */
    position: relative;
   
  }

  .text-detail{
    resize: none;
    max-height: 150px;
    text-decoration: none;
  }



</style>

<script>
import API from "src/core/Api";
import Box from '/src/modules/medico/views/fichas/Box.vue';
import {mapGetters} from "vuex";

export default { 
 data() {
        return {
        //data

        
            // checklist
            veterinaria_checklist:{
                recepcion: {
                    name: 'Recepcion y toma de datos',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                decision: {
                    name: 'Decisión del estilo de corte',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                revision: {
                    name: 'Revisión de heridas, lesiones o problemas de piel con toma de fotos para constancia',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                corte_limado: {
                    name: 'Corte uñas y limado',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                limpieza_orejas: {
                    name: 'Limpieza de orejas',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                corte_pelo: {
                    name: 'Corte de pelo o fulminado según caso',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                b_medicado: {
                    name: 'Baño medicado (2 aplicaciones)',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                vaciado_glandula: {
                    name: 'Vaciado de glándula anal',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                secado: {
                    name: 'Secado (toalla y secadora)',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                secado_oidos: {
                    name: 'Secado de oídos',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                retoque: {
                    name: 'Retoque final del corte',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                llamada: {
                    name: 'Llamada al cliente para indicar que ya pueden recogerlo',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                perfume: {
                    name: 'Perfume',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                accesorios: {
                    name: 'Accesorios',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
            },
 }},
 components: {
        Box,
},
 computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha'
        })
},
 watch: {

        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {

                const self = this;
        
                //Cargo checklist
                Object.keys(self.veterinaria_checklist).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.veterinaria_checklist) {

                        if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_cancel']) {
                            self.veterinaria_checklist[value].cancel = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_cancel'];
                        }
                        if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_check']) {
                            self.veterinaria_checklist[value].check = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_check'];
                        }
                        if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detail']) {
                            self.veterinaria_checklist[value].detail = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detail'];
                        }
                        if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detailValue']) {
                            self.veterinaria_checklist[value].detailValue = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detailValue'];
                        }

                    }
                });
                
            }
        }
 },
 mounted(){

    this.emitter.emit("medico-ficha-load",['veterinaria_checklist'])  

      
 },
 methods:{
    handleCheckStyle(item){
        if(item.cancel){
            return "cancel-style";
        }else if (item.check) {
            return "check-style";
        }
    },
    onChangeDetail(item){
            item.detail=!item.detail;
    },
    saveCheckProcess(){
        const self = this;

        const arrToSave = {};
        Object.keys(self.veterinaria_checklist).map(function (value) {
            arrToSave[value+'_cancel'] = self.veterinaria_checklist[value].cancel;
            arrToSave[value+'_check'] = self.veterinaria_checklist[value].check;
            arrToSave[value+'_detail'] = self.veterinaria_checklist[value].detail;
            arrToSave[value+'_detailValue'] = self.veterinaria_checklist[value].detailValue;
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'veterinaria_checklist',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['veterinaria_checklist']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    handleCheckSelected(item, action){
        if(action=== 'cancel' && item.cancel && item.check){
            item.check=false;
        }else if(action=== 'check' && item.check && item.cancel){
            item.cancel=false;
        }
        this.saveCheckProcess();
    },
 }
 }
</script>
 