<template>
    <layout :active="31" :title="true">
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4" v-if="AuthGetUserInfo.layout !== 'cotizador'">
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Mis órdenes</h4>
                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item">
                                        <router-link to="/orders/generated" class="nav-link" :class="active === 31 && 'active'">
                                            <i class="fa-solid fa-receipt"></i>
                                            <span>Ordenes generadas</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/orders/subscriptions" class="nav-link" :class="active === 32 && 'active'">
                                            <i class="fa-solid fa-infinity"></i>
                                            <span>Suscripciones</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/orders/reservas" class="nav-link" :class="active === 34 && 'active'">
                                            <i class="la la-calendar"></i>
                                            <span>Agenda</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/orders/cod-promo-list" class="nav-link" :class="active === 35 && 'active'">
                                            <i class="la la-money-check"></i>
                                            <span>Códigos promocionales</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/orders/massive-invoce" class="nav-link" :class="active === 36 && 'active'">
                                            <i class="fa-solid fa-file-invoice"></i>
                                            <span>Facturación masiva</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Taquilla</h4>
                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item">
                                        <router-link to="/frm/ticket/printer" class="nav-link" :class="active === 37 && 'active'">
                                            <i class="fa-solid fa-print"></i>
                                            <span>Impresión de gafetes</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/frm/assistance/validator" class="nav-link" :class="active === 38 && 'active'">
                                            <i class="fa-solid fa-calendar-check"></i>
                                            <span>Validador de asistencia</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div :class="(AuthGetUserInfo.layout !== 'cotizador')?'col-xl-9 col-md-8':'col-xl-12'">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import {mapGetters} from "vuex";

export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
        }),
    },
};
</script>
