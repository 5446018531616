 <template>
  <box title="Observaciones" icon="fas fa-radiation">
    <div class="row">
        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in podologia_observaciones" :key="'grooming_comportamiento_' + key">
            <label class="toggle">
                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="savePodologia"/>
                <div class="toggle-switch"></div>
                <span class="toggle-label">
                    {{ item.name }}
                </span>
            </label>
        </div>  
    </div>
   </box>
  </template>
 
 
<script>
import {mapGetters} from "vuex";
import API from "src/core/Api";
import Box from '/src/modules/medico/views/fichas/Box.vue';

export default { 
 data() {
        return {
        //data
        podologia_observaciones: {
                ortesis: {
                    name: 'ÓRTESIS',
                    complemento: {},
                    value: false,
                },
                pseca: {
                    name: 'PIEL SECA',
                    complemento: {},
                    value: false,
                },
                phumeda: {
                    name: 'PIEL HÚMEDA',
                    complemento: {},
                    value: false,
                },
                pdelgada: {
                    name: 'PIEL DELGADA',
                    complemento: {},
                    value: false,
                },
                pEscamosa: {
                    name: 'PIEL Escamosa',
                    complemento: {},
                    value: false,
                },
                pBrillante: {
                    name: 'PIEL Brillante',
                    complemento: {},
                    value: false,
                },
                pTransparente: {
                    name: 'PIEL Transparente',
                    complemento: {},
                    value: false,
                },
                pSincabello: {
                    name: 'PIEL Sin cabello',
                    complemento: {},
                    value: false,
                }
            }
 }},
 components: {
        Box,
    },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        })
    },
 watch: {
    GetReloadFicha(newValue, oldValue) {
        if (newValue === false && oldValue === true) {

            const self = this;

            // Cargo la info de prenatales
            Object.keys(self.podologia_observaciones).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.podologia_observaciones) {
                    if (self.GetMedicoFichaActive.metadata.podologia_observaciones[value]) {
                        self.podologia_observaciones[value].value = self.GetMedicoFichaActive.metadata.podologia_observaciones[value];
                    }
                }
            });
        }
    }
 },
 mounted(){
    this.emitter.emit("medico-ficha-load",['podologia_observaciones'])  
 },
 methods: {
       
        savePodologia() {
           const self = this;

           // transformo los keys a un array normal
           const arrToSave = {};
           Object.keys(self.podologia_observaciones).map(function (value) {
               if (self.podologia_observaciones[value].value !== '') arrToSave[value] = self.podologia_observaciones[value].value;
           });

           // Guardo y luego emito el evento
           API.send('POST', 'orders/metadata/save/', {
                   orderId: self.GetMedicoFichaActive.orden.id,
                   slug: 'podologia_observaciones',
                   detail: arrToSave,
                   uniqueMeta: 1,
               }, function (response) {
                   API.showSuccessAlert(response.msg);
                   self.emitter.emit("medico-ficha-notify",['podologia_observaciones']);
               },
               function (response) {
                   API.showErrorAlert(response.msg);
               });
       }
   },
 }
</script>
 
<style scoped>
 
</style>