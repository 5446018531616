import localDisk from 'src/localDisk';
import API from "src/core/Api";

export const medicoState = {
    state: {
        medicoFichaActive: localDisk.read('medicoFichaActive'),
        reloadFicha: false,
    },
    mutations: {
        // categorias
        MutateMedicoFichaActive(state, data) {

            if (typeof data.metadata === 'undefined') {
                data.metadata = {};
            }

            state.medicoFichaActive = data;
            localDisk.write('medicoFichaActive', state.medicoFichaActive);
        },
        MutateReloadFicha(state, data) {
            state.reloadFicha = data;
        },
    },
    getters: {
        GetMedicoFichaActive: state => {
            if (!state.medicoFichaActive) {
                return {};
            }
            else {
                return state.medicoFichaActive;
            }
        },
        GetReloadFicha: state => state.reloadFicha,
    },
    actions: {
        // Categories
        SetMedicoFichaActive: ({commit, getters}, data) => {
            commit('MutateMedicoFichaActive', data);
        },
        SetReloadFicha: ({commit, getters}, data) => {
            commit('MutateReloadFicha', data);
        },
    }
}
