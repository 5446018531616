
<template>
        <box title="Primer mes" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida1" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(1, 'mesesxvida1')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(1, 'mesesxvida1')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Segundo y tercer mes" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in  mesesxvida2_3" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(2, 'mesesxvida2_3')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(2, 'mesesxvida2_3')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Cuarto y quinto mes" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in  mesesxvida4_5" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(3, 'mesesxvida4_5')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(3, 'mesesxvida4_5')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Sexto, séptimo y octavo mes" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida6_8" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(4, 'mesesxvida6_8')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(4, 'mesesxvida6_8')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Noveno, décimo, onceavo y doceavo mes" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida9_12" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(5, 'mesesxvida9_12')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(5, 'mesesxvida9_12')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Trece a dieciocho meses" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida13_18" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(6, 'mesesxvida13_18')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(6, 'mesesxvida13_18')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Diecinueve a veinticuatro meses" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida19_24" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(7, 'mesesxvida19_24')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(7, 'mesesxvida19_24')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="2 Años" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida2y" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(8, 'mesesxvida2y')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(8, 'mesesxvida2y')"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="3 Años" icon="fas fa-calendar-check">
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida3y" :key="key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(9, 'mesesxvida3y')"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(9, 'mesesxvida3y')"></textarea>
                    </div>
                </div>
            </div>
        </box>
</template>

<script>
import API from "src/core/Api";
import {mapGetters} from "vuex";
import Box from "src/modules/medico/views/fichas/Box.vue";

export default { 
 data() {
        return {
            mesesxvida1: {
                frunce_parpados_Luz: {
                    name: '1. Frunce los parpados a la Luz',
                    value: false,
                    detail: ''
                },
                reflejo_succion: {
                    name: '2. Reflejo de succión',
                    value: false,
                    detail: ''
                },
                reflejo_moro: {
                    name: '3. Reflejo de Moro',
                    value: false,
                    detail: ''
                },
                reflejo_prehension_palmar_plantar: {
                    name: '4. Reflejo de prehension palmar y plantar',
                    value: false,
                    detail: ''
                },
                reflejo_busqueda: {
                    name: '5. Reflejo de Búsqueda',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida2_3: {
                mueve_levanta_cabeza: {
                    name: '1. Mueve y levanta la cabeza',
                    value: false,
                    detail: ''
                },
                fija_mirada: {
                    name: '2. Fija la mirada',
                    value: false,
                    detail: ''
                },
                toma_con_firmeza: {
                    name: '3. Toma con firmeza',
                    value: false,
                    detail: ''
                },
                movimiento_manos: {
                    name: '4. Movimiento de las manos',
                    value: false,
                    detail: ''
                },
                sonrisa_social: {
                    name: '5. Sonrisa social',
                    value: false,
                    detail: ''
                },
                emite_sonidos: {
                    name: '6. Emite sonidos',
                    value: false,
                    detail: ''
                },
                inicia_hábito_dormir: {
                    name: '7. Iniciar hábito de dormir',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida4_5: {
                sostiene_cabeza: {
                    name: '1. Sostiene la cabeza',
                    value: false,
                    detail: ''
                },
                se_sienta_con_ayuda: {
                    name: '2. Se sienta con ayuda',
                    value: false,
                    detail: ''
                },
                sigue_objetos_mirada: {
                    name: '3. Sigue objetos con la mirada',
                    value: false,
                    detail: ''
                },
                sonrie_personas_conocidas_verlas: {
                    name: '4. Sonríe con personas conocidas al verlas',
                    value: false,
                    detail: ''
                },
                explora_manos: {
                    name: '5. Explora con las manos',
                    value: false,
                    detail: ''
                },
                llora_rie_solo: {
                    name: '6. Llora o ríe al estar solo',
                    value: false,
                    detail: ''
                },
                balbucea: {
                    name: '7. Balbucea',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida6_8: {
                se_sienta_solo: {
                    name: '1. Se sienta solo',
                    value: false,
                    detail: ''
                },
                juega_con_pies: {
                    name: '2. Juega con sus pies',
                    value: false,
                    detail: ''
                },
                encuentra_objetos : {
                    name: '3. Encuentra objetos ',
                    value: false,
                    detail: ''
                },
                explora_la_boca: {
                    name: '4. Explora con la boca',
                    value: false,
                    detail: ''
                },
                mueve_objetos_mano_a_otra: {
                    name: '5. Mueve objetos de una mano a otra',
                    value: false,
                    detail: ''
                },
                reconoce_extraños: {
                    name: '6. Reconoce a extraños ',
                    value: false,
                    detail: ''
                },
                repite_sonidos: {
                    name: '7. Repite sonidos',
                    value: false,
                    detail: ''
                },
                puede_decir_silabas: {
                    name: '8. Puede decir sílabas',
                    value: false,
                    detail: ''
                },
                despierta_facil : {
                    name: '9. Despierta fácil ',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida9_12: {
                gatea: {
                    name: '1. Gatea',
                    value: false,
                    detail: ''
                },
                se_para_solo: {
                    name: '2. Se para solo',
                    value: false,
                    detail: ''
                },
                saluda_con_mano: {
                    name: '3. Saluda con la mano',
                    value: false,
                    detail: ''
                },
                puede_triturar_comida: {
                    name: '4. Puede triturar la comida',
                    value: false,
                    detail: ''
                },
                movimientos_de_pinza: {
                    name: '5. Movimientos de pinza',
                    value: false,
                    detail: ''
                },
                molestia_alejarse_personas_conocidas: {
                    name: '6. Molestia al alejarse de personas conocidas',
                    value: false,
                    detail: ''
                },
                curioso: {
                    name: '7. Es curioso',
                    value: false,
                    detail: ''
                },
                pronuncia_silabas: {
                    name: '8. Pronuncia sílabas',
                    value: false,
                    detail: ''
                },
                manipula_para_no_estar_olo: {
                    name: '9. Manipula para no estar solo',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida13_18: {
                camina_solo: {
                    name: '1. Camina solo',
                    value: false,
                    detail: ''
                },
                ayuda_a_vestirse: {
                    name: '2. Ayuda a vestirse',
                    value: false,
                    detail: ''
                },
                pone_atencion_a_imagenes: {
                    name: '3. Pone atención a imágenes',
                    value: false,
                    detail: ''
                },
                inicia_a_masticar: {
                    name: '4. Inicia a masticar',
                    value: false,
                    detail: ''
                },
                toma_objetos_con_sus_manos: {
                    name: '5. Toma objetos con sus manos',
                    value: false,
                    detail: ''
                },
                responde_a_su_nombre: {
                    name: '6. Responde a su nombre',
                    value: false,
                    detail: ''
                },
                dice_dos_o_tres_palabras: {
                    name: '7. Dice dos o tres palabras',
                    value: false,
                    detail: ''
                },
                se_resiste_a_dormir: {
                    name: '8. Se resiste a dormir',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida19_24: {
                camina_solo: {
                    name: '1. Camina solo',
                    value: false,
                    detail: ''
                },
                tomar_crayones: {
                    name: '2. Tomar crayones',
                    value: false,
                    detail: ''
                },
                muerde_todo_tipo_de_comida: {
                    name: '3. Muerde todo tipo de comida',
                    value: false,
                    detail: ''
                },
                puede_comer_con_cubiertos: {
                    name: '4. Puede comer con cubiertos',
                    value: false,
                    detail: ''
                },
                inicia_su_personalidad: {
                    name: '5. Inicia su personalidad',
                    value: false,
                    detail: ''
                },
                puede_deci_d_6_a_10_palabras: {
                    name: '6. Puede decir de 6 a 10 palabras',
                    value: false,
                    detail: ''
                },
                duerme: {
                    name: '7. Duerme',
                    value: false,
                    detail: ''
                },
                inicia_control_de_esfinteres: {
                    name: '8. Inicia control de esfínteres',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida2y: {
                sube_y_baja_escaleras: {
                    name: '1. Sube y baja escaleras',
                    value: false,
                    detail: ''
                },
                salta: {
                    name: '2. Salta',
                    value: false,
                    detail: ''
                },
                coloca_por_colores: {
                    name: '3. Coloca por colores',
                    value: false,
                    detail: ''
                },
                coloca_por_figuras: {
                    name: '4. Coloca por figuras',
                    value: false,
                    detail: ''
                },
                come_solidos: {
                    name: '5. Come sólidos',
                    value: false,
                    detail: ''
                },
                juega_con_ninos: {
                    name: '6. Juega con niños',
                    value: false,
                    detail: ''
                },
                sigue_instrucciones_sencillas: {
                    name: '7. Sigue instrucciones sencillas',
                    value: false,
                    detail: ''
                },
                familiarizarlo_con_el_bano: {
                    name: '8. Familiarizarlo con el baño',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida3y: {
                se_para_un_pie: {
                    name: '1. Se para un pie',
                    value: false,
                    detail: ''
                },
                sube_escaleras_facilmente: {
                    name: '2. Sube escaleras fácilmente',
                    value: false,
                    detail: ''
                },
                mastica: {
                    name: '3. Mastica',
                    value: false,
                    detail: ''
                },
                puede_armar_un_puente: {
                    name: '4. Puede armar un puente',
                    value: false,
                    detail: ''
                },
                seguir_un_modelo: {
                    name: '5. Seguir un modelo',
                    value: false,
                    detail: ''
                },
                puede_dibujar_un_circulo: {
                    name: '6. Puede dibujar un círculo',
                    value: false,
                    detail: ''
                },
                muestra_afecto: {
                    name: '7. Muestra afecto',
                    value: false,
                    detail: ''
                },
                juega_interactivamente: {
                    name: '8. Juega interactivamente',
                    value: false,
                    detail: ''
                },
                aprende_a_esperar_su_turno: {
                    name: '9. Aprende a esperar su turno',
                    value: false,
                    detail: ''
                },
                habla_constantemente: {
                    name: '10. Habla constantemente',
                    value: false,
                    detail: ''
                },
                emple_frases_de_4_palabras: {
                    name: '11. Emple frases de 4 palabras',
                    value: false,
                    detail: ''
                },
                puede_aparecer_terrores_nocturnos: {
                    name: '12. Puede aparecer terrores nocturnos',
                    value: false,
                    detail: ''
                },
                control_de_esfintere: {
                    name: '13. Control de esfínteres',
                    value: false,
                    detail: ''
                },

            },
 }},
 mounted() {
    this.emitter.emit("medico-ficha-load",['mesesxvida1','mesesxvida2_3','mesesxvida4_5','mesesxvida6_8', 'mesesxvida9_12', 'mesesxvida13_18','mesesxvida19_24','mesesxvida2y', 'mesesxvida3y'])
},
 components:{
    Box,
 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        })
    },
 watch: {
    GetReloadFicha(newValue, oldValue) {
        if (newValue === false && oldValue === true) {
            const self = this;
            
            Object.keys(self.mesesxvida1).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida1) {
                        self.mesesxvida1[value].value = self.GetMedicoFichaActive.metadata.mesesxvida1[value] ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida1[value+'_detail']) {
                        self.mesesxvida1[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida1[value+'_detail'];
                    }

                }
            });

            Object.keys(self.mesesxvida2_3).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida2_3) {
                        self.mesesxvida2_3[value].value = self.GetMedicoFichaActive.metadata.mesesxvida2_3[value]  ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida2_3[value+'_detail']) {
                        self.mesesxvida2_3[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida2_3[value+'_detail'];
                    }

                }
            });
            Object.keys(self.mesesxvida4_5).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida4_5) {
                        self.mesesxvida4_5[value].value = self.GetMedicoFichaActive.metadata.mesesxvida4_5[value] ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida4_5[value+'_detail']) {
                        self.mesesxvida4_5[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida4_5[value+'_detail'];
                    }

                }
            });
            Object.keys(self.mesesxvida6_8).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida6_8) {
                        self.mesesxvida6_8[value].value = self.GetMedicoFichaActive.metadata.mesesxvida6_8[value] ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida6_8[value+'_detail']) {
                        self.mesesxvida6_8[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida6_8[value+'_detail'];
                    }

                }
            });
            Object.keys(self.mesesxvida9_12).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida9_12) {
                        self.mesesxvida9_12[value].value = self.GetMedicoFichaActive.metadata.mesesxvida9_12[value] ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida9_12[value+'_detail']) {
                        self.mesesxvida9_12[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida9_12[value+'_detail'];
                    }

                }
            });

            Object.keys(self.mesesxvida13_18).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida13_18) {
                        self.mesesxvida13_18[value].value = self.GetMedicoFichaActive.metadata.mesesxvida13_18[value] ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida13_18[value+'_detail']) {
                        self.mesesxvida13_18[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida13_18[value+'_detail'];
                    }

                }
            });

            Object.keys(self.mesesxvida19_24).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida19_24) {
                        self.mesesxvida19_24[value].value = self.GetMedicoFichaActive.metadata.mesesxvida19_24[value]  ? true : false;

                    if (self.GetMedicoFichaActive.metadata.mesesxvida19_24[value+'_detail']) {
                        self.mesesxvida19_24[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida19_24[value+'_detail'];
                    }

                }
            });
            Object.keys(self.mesesxvida2y).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida2y) {
                        self.mesesxvida2y[value].value = self.GetMedicoFichaActive.metadata.mesesxvida2y[value] ? true : false;
                    
                    if (self.GetMedicoFichaActive.metadata.mesesxvida2y[value+'_detail']) {
                        self.mesesxvida2y[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida2y[value+'_detail'];
                    }

                }
            });
            Object.keys(self.mesesxvida3y).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.mesesxvida3y) {
                        self.mesesxvida3y[value].value = self.GetMedicoFichaActive.metadata.mesesxvida3y[value] ? true : false;
                    
                    if (self.GetMedicoFichaActive.metadata.mesesxvida3y[value+'_detail']) {
                        self.mesesxvida3y[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida3y[value+'_detail'];
                    }

                }
            });

        }
    },
    }, 
methods: {
    saveSaveMesesxVida(data, slug){
            const self = this;
            let item
            switch (data) {
                case 1:
                    item=self.mesesxvida1
                    break;
                case 2:
                    item=self.mesesxvida2_3
                    break;
                case 3:
                    item=self.mesesxvida4_5
                    break;
                case 4:
                    item=self.mesesxvida6_8
                    break;
                case 5:
                    item=self.mesesxvida9_12
                    break;
                case 6:
                    item=self.mesesxvida13_18
                    break;
                case 7:
                    item=self.mesesxvida19_24
                    break;
                case 8:
                    item=self.mesesxvida2y
                    break;
                case 9:
                    item=self.mesesxvida3y
                    break;

                default:
                API.showErrorAlert('Seleccion no valida');
                    break;
            }

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(item).map(function (value) {
                arrToSave[value] = item[value].value;
                if(item[value].value===false){
                    arrToSave[value + '_detail'] = '';
                    item[value].detail = '';
                }else{
                    arrToSave[value + '_detail'] = item[value].detail;
                }
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: slug,
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify", [slug])
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

        }, 
},

}
</script>