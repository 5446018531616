<template>
    <box title="Lista de Vacunación" icon="fas fa-syringe">
        <table class="table">
            <tr>
                <td class="font-weight-bold">
                    Vacuna
                </td>
                <td class="font-weight-bold">Fecha #1</td>
                <td class="font-weight-bold">Fecha #2</td>
                <td class="font-weight-bold">Fecha #3</td>
                <td class="font-weight-bold">Fecha #4</td>
                <td class="font-weight-bold">Fecha #5</td>
            </tr>
            <tr v-for="item in veterinaria_vacunas.vacunas" :key="item" class="mb-3">
                <td>
                    <div v-if="!item.editable">
                        {{item.nombre}}
                    </div>
                    <div v-else>
                        <input type="text" class="form-control" v-model="item.nombre" @change="saveVacunas"/>
                    </div>
                </td>
                <td>
                    <input type="date" class="form-control" v-model="item.f1" @change="saveVacunas"/>
                </td>
                <td>
                    <input type="date" class="form-control" v-model="item.f2" @change="saveVacunas"/>
                </td>
                <td>
                    <input type="date" class="form-control" v-model="item.f3" @change="saveVacunas"/>
                </td>
                <td>
                    <input type="date" class="form-control" v-model="item.f4" @change="saveVacunas"/>
                </td>
                <td>
                    <input type="date" class="form-control" v-model="item.f5" @change="saveVacunas"/>
                </td>
            </tr>
        </table>
        <div class="mt-3">
            <div class="form-group">
                <label>Observaciones sobre vacunas</label>
                <textarea class="form-control w-100" v-model="veterinaria_vacunas.observaciones"  @change="saveVacunas"></textarea>
            </div>
        </div>
    </box>
</template>

<style scoped>

</style>

<script>
import API from "src/core/Api";
import Box from '/src/modules/medico/views/fichas/Box.vue';
import {mapGetters} from "vuex";

export default { 
 data() {
        return {
        //data

            veterinaria_vacunas: {
                "vacunas": {
                    "primeraVacunaPerro": {
                        "nombre": "Primera vacuna - polivalente (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "SegundaVacunaPerro": {
                        "nombre": "Segunda vacuna - polivalente (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "terceraVacunaPerro": {
                        "nombre": "Tercera vacuna - polivalente (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "cuartaVacuna": {
                        "nombre": "Cuarta vacuna - Tos de la perrera (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "quintaVacuna": {
                        "nombre": "Quinta vacuna - Antirrábica (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "primeraVacunaGato": {
                        "nombre": "Primera vacuna - Polivalente (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "segundaVacunaGato": {
                        "nombre": "Segunda vacuna - Polivalente (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "terceraVacunaGatos": {
                        "nombre": "Tercer vacuna - Polivalente (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "cuartaVacunaGatos": {
                        "nombre": "Cuarta vacuna - Leucemia (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "defensorRabia": {
                        "nombre": "Quinta vacuna - antirrábica (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "otraUno": {
                        "nombre": "",
                        "editable": true,
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "otraDos": {
                        "nombre": "",
                        "editable": true,
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    }
                },
                "observaciones": ""
            },
 }},
 components: {
        Box,
},
 computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha'
        })
},
 watch: {

        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                const self = this;
                
                // Cargo la info de vacunas
                Object.keys(self.veterinaria_vacunas.vacunas).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas) {
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f1']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f1'];
                    
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f2']) self.veterinaria_vacunas.vacunas[value].f2 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f2'];
                        
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f3']) self.veterinaria_vacunas.vacunas[value].f3 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f3'];
                        
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f4']) self.veterinaria_vacunas.vacunas[value].f4 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f4'];
                    
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f5']) self.veterinaria_vacunas.vacunas[value].f5 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f5'];
                        
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_n']) self.veterinaria_vacunas.vacunas[value].nombre = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_n'];
                        
                        if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas['observaciones']) self.veterinaria_vacunas['observaciones'] = self.GetMedicoFichaActive.metadata.veterinaria_vacunas['observaciones'];
                    }
                });
            }
        }
 },
 mounted(){

    this.emitter.emit("medico-ficha-load",['veterinaria_vacunas'])  

      
 },
 methods:{
    saveVacunas() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_vacunas.vacunas).map(function (value) {
                if (self.veterinaria_vacunas.vacunas[value].f1 !== '') arrToSave[value + '_f1'] = self.veterinaria_vacunas.vacunas[value].f1;
                if (self.veterinaria_vacunas.vacunas[value].f2 !== '') arrToSave[value + '_f2'] = self.veterinaria_vacunas.vacunas[value].f2;
                if (self.veterinaria_vacunas.vacunas[value].f3 !== '') arrToSave[value + '_f3'] = self.veterinaria_vacunas.vacunas[value].f3;
                if (self.veterinaria_vacunas.vacunas[value].f4 !== '') arrToSave[value + '_f4'] = self.veterinaria_vacunas.vacunas[value].f4;
                if (self.veterinaria_vacunas.vacunas[value].f5 !== '') arrToSave[value + '_f5'] = self.veterinaria_vacunas.vacunas[value].f5;

                if (self.veterinaria_vacunas.vacunas[value].editable) {
                    if (self.veterinaria_vacunas.vacunas[value].nombre !== '') arrToSave[value + '_n'] = self.veterinaria_vacunas.vacunas[value].nombre;
                }
            });
            arrToSave['observaciones'] = self.veterinaria_vacunas['observaciones'];

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_vacunas',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-notify",['veterinaria_vacunas']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
              
          
 }
 }
</script>
 