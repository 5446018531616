<template>
    <div>
        <ficha-layout title="Veterinaria" color="#1e9a7c" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #consulta>
              <consulta></consulta>
            </template>
            <template #vacunas>
                <vacunas></vacunas>
            </template>
            <template #grooming>
                <grooming></grooming>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #checklist>
                <checklist></checklist>
            </template>
        </ficha-layout>
    </div>
</template>

<style scoped>
</style>

<script>
import FichaLayout from '../components/FichaLayout.vue';

// componentes medicos
import Tratamiento from '../components/Tratamiento.vue';
import PreClinica from '../components/PreClinica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Grooming from '../components/Grooming.vue';
import Historial from '../components/HistorialPr.vue';
import Resumen from '../components/Resumen.vue';
import Adjuntos from '../components/Adjuntos.vue';

import Checklist from "./components/Checklist.vue";
import Consulta from "./components/Consulta.vue";
import Vacunas from './components/Vacunas.vue';
export default {
    components: {
        FichaLayout,
        Consulta,
        Vacunas,
        Tratamiento,
        PreClinica,
        Grooming,
        ImpresionClinica,
        Historial,
        Resumen,
        Adjuntos,
        Checklist
    },
    props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    }
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                consulta: {
                    icon: 'fas fa-paw',
                    name: 'Veterinaria',
                },
                vacunas: {
                    icon: 'fas fa-syringe',
                    name: 'Vacunas',
                },
                grooming: {
                    icon: 'fas fa-hands-wash',
                    name: 'Grooming',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos',
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
                checklist: {
                    icon: 'fa-solid fa-list-check',
                    name: 'Checklist',
                },
            },     
        };
    },
};

</script>
