 <template>
   <box title="Rayos X" icon="fas fa-x-ray">
        <div class="row">
            <div class="col-12 col-sm-6 mb-3" v-for="item in traumatologia_radiologia" :key="item.name">
                <Field :data="item" v-model="item.value" @change="saveRayosX"></Field>
            </div>
        </div>
    </box>
    <box title="RMN" icon="fas fa-radiation">
        <div class="row">
        <div class="col-12 col-sm-6 mb-3" v-for="item in traumatologia_rmn" :key="item.name">
            <Field :data="item" v-model="item.value" @change="saveRMN"></Field>
        </div>
        </div>
    </box>
    <box title="Densitometria" icon="fas fa-bone">
        <div class="row">
        <div class="col-12 mb-3" v-for="item in traumatologia_densitometria" :key="item.name">
            <Field :data="item" v-model="item.value" @change="saveDensitometria"></Field>
        </div>
        </div>
    </box>
    <box title="Tomografía" icon="fas fa-skull">
    <div class="row">
        <div class="col-12 mb-3" v-for="item in traumatologia_tomografia" :key="item.name">
        <Field :data="item" v-model="item.value" @change="saveTomografia"></Field>
        </div>
    </div>
    </box>
</template>
    
<style scoped>
   
</style>

<script>
import {mapGetters} from "vuex";

import API from "src/core/Api";
import Box from '/src/modules/medico/views/fichas/Box.vue';

import Field from '/src/modules/medico/views/fichas/Field.vue';
import '@vueform/slider/themes/default.css';

export default { 
 data() {
        return {
        //data
        traumatologia_radiologia: {
                columna_completa: {
                    name: 'Columna Completa AP-Parado',
                    type: 'textarea',
                    value: '',
                },
                pierna: {
                    name: 'Pierna',
                    type: 'textarea',
                    value: '',
                },
                rodilla: {
                  name: 'Rodilla',
                  type: 'textarea',
                  value: '',
                },
                femur: {
                  name: 'Fémur',
                  type: 'textarea',
                  value: '',
                },
                cadera: {
                  name: 'Cadera',
                  type: 'textarea',
                  value: '',
                },
                pelvis: {
                  name: 'Pelvis',
                  type: 'textarea',
                  value: '',
                },
            mano: {
              name: 'Mano',
              type: 'textarea',
              value: '',
            },
            antebrazo: {
              name: 'Antebrazo',
              type: 'textarea',
              value: '',
            },
              codo: {
                name: 'Codo',
                type: 'textarea',
                value: '',
              },
              brazo: {
                name: 'brazo',
                type: 'textarea',
                value: '',
              },
              hombro_izquierdo: {
                name: 'Hombro Izquierdo',
                type: 'textarea',
                value: '',
              },
              hombro_derecho: {
                name: 'Hombro Derecho',
                type: 'textarea',
                value: '',
              },
              torax: {
                name: 'Tórax',
                type: 'textarea',
                value: '',
              },
              columna_lumbosacra: {
                name: 'Columna Lumbosacra',
                type: 'textarea',
                value: '',
              },
              columna_toraxica: {
                name: 'Columna Toráxica',
                type: 'textarea',
                value: '',
              },
              columna_cervical: {
                name: 'Columna Cervical',
                type: 'textarea',
                value: '',
              },
              pie: {
                name: 'Pie',
                type: 'textarea',
                value: '',
              },
              tobillo: {
                name: 'Tobillo',
                type: 'textarea',
                value: '',
              },
            },
        traumatologia_rmn: {
            columna_cervical: {
              name: 'Columna Cervical',
              type: 'textarea',
              value: '',
            },
            miembro_superior: {
              name: 'Miembro Superior',
              type: 'textarea',
              value: '',
            },
            columna_lumbosacra: {
              name: 'Columna Lumbosacra',
              type: 'textarea',
              value: '',
            },
            miembro_inferior: {
              name: 'Miembro Inferior',
              type: 'textarea',
              value: '',
            },
            columna_dorsal: {
              name: 'Columna Dorsal',
              type: 'textarea',
              value: '',
            },
          },
        traumatologia_densitometria: {
            comentarios: {
              name: 'Comentarios',
              type: 'textarea',
              value: '',
            },
          },
        traumatologia_tomografia: {
            comentarios: {
              name: 'Comentarios',
              type: 'textarea',
              value: '',
            },
          }
 }},
 components:{
    Box,
    Field,
 },
 computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        })
    },
    watch: {

        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {

                const self = this;

                Object.keys(self.traumatologia_radiologia).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.traumatologia_radiologia) {
                        if (self.GetMedicoFichaActive.metadata.traumatologia_radiologia[value]) {
                            self.traumatologia_radiologia[value].value = self.GetMedicoFichaActive.metadata.traumatologia_radiologia[value];
                        }
                    }
                });

                Object.keys(self.traumatologia_rmn).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.traumatologia_rmn) {
                        if (self.GetMedicoFichaActive.metadata.traumatologia_rmn[value]) {
                            self.traumatologia_rmn[value].value = self.GetMedicoFichaActive.metadata.traumatologia_rmn[value];
                        }
                    }
                });

                Object.keys(self.traumatologia_densitometria).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.traumatologia_densitometria) {
                        if (self.GetMedicoFichaActive.metadata.traumatologia_densitometria[value]) {
                            self.traumatologia_densitometria[value].value = self.GetMedicoFichaActive.metadata.traumatologia_densitometria[value];
                        }
                    }
                });

                Object.keys(self.traumatologia_tomografia).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.traumatologia_tomografia) {
                        if (self.GetMedicoFichaActive.metadata.traumatologia_tomografia[value]) {
                            self.traumatologia_tomografia[value].value = self.GetMedicoFichaActive.metadata.traumatologia_tomografia[value];
                        }
                    }
                });
            }
        }


    },
    mounted() {
        this.emitter.emit("medico-ficha-load",['traumatologia_radiologia','traumatologia_rmn','traumatologia_densitometria','traumatologia_tomografia'])  
    },
    methods: {
        saveRayosX() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.traumatologia_radiologia).map(function (value) {
                if (self.traumatologia_radiologia[value].value !== '') arrToSave[value] = self.traumatologia_radiologia[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'traumatologia_radiologia',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-notify",['traumatologia_radiologia']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveRMN(){
          const self = this;

          // transformo los keys a un array normal
          const arrToSave = {};
          Object.keys(self.traumatologia_rmn).map(function (value) {
            if (self.traumatologia_rmn[value].value !== '') arrToSave[value] = self.traumatologia_rmn[value].value;
          });

          // Guardo y luego emito el evento
          API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'traumatologia_rmn',
                detail: arrToSave,
                uniqueMeta: 1,
              }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['traumatologia_rmn']);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        saveDensitometria(){
          const self = this;

          // transformo los keys a un array normal
          const arrToSave = {};
          Object.keys(self.traumatologia_densitometria).map(function (value) {
            if (self.traumatologia_densitometria[value].value !== '') arrToSave[value] = self.traumatologia_densitometria[value].value;
          });

          // Guardo y luego emito el evento
          API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'traumatologia_densitometria',
                detail: arrToSave,
                uniqueMeta: 1,
              }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['traumatologia_densitometria']);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        saveTomografia(){
          const self = this;

          // transformo los keys a un array normal
          const arrToSave = {};
          Object.keys(self.traumatologia_tomografia).map(function (value) {
            if (self.traumatologia_tomografia[value].value !== '') arrToSave[value] = self.traumatologia_tomografia[value].value;
          });

          // Guardo y luego emito el evento
          API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'traumatologia_tomografia',
                detail: arrToSave,
                uniqueMeta: 1,
              }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['traumatologia_tomografia']);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        }
    },
 }
</script>
