
<script>
import API from "src/core/Api";
import {mapGetters} from "vuex";

import FrontBody from "/src/assets/Images/FrontBody.jpg";
import BackBody from "/src/assets/Images/BackBody.jpg";
import CustomTooltip from "./CustomTooltip.vue";

export default {
  data() {
    return {
      FrontBody,
      BackBody,
      showInput: false,
      inputText: '',
      inputPosition: { x: 0, y: 0 },
      currentImage: '',
      body_selections: { 
          front: [],
          back: []
      }
    };
  },
  components:{
    CustomTooltip
  },
  computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
 },
  mounted() {
    const self = this;
    self.loadBodyParts();
  },
  watch: {
   
  },
  methods: {
    handleClick(event, image) {
      this.currentImage = image;
      const rect = event.target.getBoundingClientRect();
      this.inputPosition = {
        x: event.clientX - rect.left - 10,
        y: event.clientY - rect.top - 10,
      };
      this.showInput = true;
    },
    addLabel() {
      if (this.inputText.trim()) {
        let muscle = {
          id:0,
          text: this.inputText,
          x: this.inputPosition.x,
          y: this.inputPosition.y
        }
        this.body_selections[this.currentImage].push(muscle);
        this.inputText = '';
        this.showInput = false;
        this.saveBodySelections(muscle,this.currentImage);
      }
    },
    hideInput() {
      this.showInput = false;
      this.inputText = '';
    },
    saveBodySelections(muscle,slug){
      const self = this;

      API.send('POST', 'orders/metadata/save/', {
          orderId: self.GetMedicoFichaActive.orden.id,
          metadataId: muscle.id,
          slug,
          detail: muscle,
      }, function (response) {
          API.showSuccessAlert(response.msg);
          self.emitter.emit("medico-ficha-refresh",[slug]);
          setTimeout(()=>{
            self.loadBodyParts();
          },1000)
        
      },
      function (response) {
          API.showErrorAlert(response.msg);
      });
    },
    loadBodyParts(){
      const self = this;
      
      self.body_selections= { 
          front: [],
          back: []
      };
      if(typeof self.GetMedicoFichaActive.metadata.front !== "undefined"){
      Object.keys(self.GetMedicoFichaActive.metadata.front).map(function (key,index) {
        let itemTemp = {
          id: key,
          text: self.GetMedicoFichaActive.metadata.front[key].detail.text,
          x:self.GetMedicoFichaActive.metadata.front[key].detail.x,
          y:self.GetMedicoFichaActive.metadata.front[key].detail.y
        }
        self.body_selections.front.push(itemTemp)
        
      });
    }

    
    if(typeof self.GetMedicoFichaActive.metadata.back !== "undefined"){
      Object.keys(self.GetMedicoFichaActive.metadata.back).map(function (key,index) {
        let itemTemp = {
          id: key,
          text: self.GetMedicoFichaActive.metadata.back[key].detail.text,
          x:self.GetMedicoFichaActive.metadata.back[key].detail.x,
          y:self.GetMedicoFichaActive.metadata.back[key].detail.y
        }
        self.body_selections.back.push(itemTemp)
        
      });
    }

    },
    deleteBodyPart(slug,id){
      const self = this;
        API.showConfirm('¿Está seguro de eliminar?', '', function (){
            API.send('POST', 'orders/metadata/delete', {
                    metadataId: id,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh", [slug]); // emito un evento global para que se actualice la info de la ficha
                    self.pushItem(slug,id);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        })
    },
    pushItem(slug, id) {
      this.body_selections[slug] = this.body_selections[slug].filter(function (item) {
        return item.id !== id;
      });
    }
  }
};
</script>
 
<template>
 
    <div class="mt-2 w-100 d-flex flex-column flex-md-row justify-content-center align-items-center position-relative">
      <div class="w-100 w-md-50 d-flex justify-content-center align-items-center">
        <div class="w-auto position-relative" @click="handleClick($event, 'front')">
          <img :src="FrontBody" alt="Human body parts" :width="260">
         
            <div
            v-for="(item, index) in body_selections.front"
            :key="'front' + index"
            :style="{ top: item.y + 'px', left: item.x + 'px' }"
            class="label"
            > 
            <i class="fa-solid fa-circle-xmark xclose" style="color: #1c1c1c;" @click.prevent.stop="deleteBodyPart('front', item.id)"></i>
            <CustomTooltip :fontSz="12" :text="item.text" :direction="item.x>130 ? 'right' : 'left'">
            <i class="fa-regular fa-circle-dot" style="color: #db0606;" @click.stop></i>
            </CustomTooltip>
          </div>
        </div>
      </div>
      <div class="w-100 w-md-50 d-flex justify-content-center align-items-center">
        <div class="w-auto position-relative" @click="handleClick($event, 'back')">
          <img :src="BackBody" alt="Human body parts" :width="260">
          <div
            v-for="(item, index) in body_selections.back"
            :key="'back' + index"
            :style="{ top: item.y + 'px', left: item.x + 'px' }"
            class="label"
          >
          <i class="fa-solid fa-circle-xmark xclose" style="color: #1c1c1c;" @click.prevent.stop="deleteBodyPart('back', item.id)"></i>
            <CustomTooltip :fontSz="12" :text="item.text" :direction="item.x>130 ? 'right' : 'left'">
            <i class="fa-regular fa-circle-dot" style="color: #db0606;" @click.stop></i>
            </CustomTooltip>
          </div>
        </div>
      </div>
     
      <div v-if="showInput" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-body">
                      <div class="row">
                        <div class="form-group col-12 d-flex flex-column justify-content-center align-items-center">
                          <label>Agregar descripción</label>
                          <input type="text" class="form-control" v-model="inputText" @keyup.enter="addLabel" />
                        </div>
                      </div>                   
                          <div class="row">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                              <div type="button" @click.prevent="addLabel" class="btn btn-success m-1">Agregar</div>
                              <div type="button" @click.prevent="hideInput" class="btn btn-danger m-1">Cancelar</div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
 
<style scoped>
.position-relative {
  position: relative;
}

.label {
  position: absolute;
  font-size: 20px;
}

.label i{
    z-index: 1;
    transition: 0.3s;
    opacity:0.7;
}

.label:hover i,
.xclose{
    opacity:1;
    transform: scale(1.2);
    visibility: visible;
}

.xclose{
  position: absolute;
  top:0px;
  left:50%;
  font-size: 8px;
  visibility: hidden;
  transition: 0.3s;
  z-index: 1;
}

.input-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBodyPart{
 width: 250px;
 height: 150px;
 border-radius: 7px;
 background-color: white;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 display: flex;
 justify-content: center;
 align-items: center;
}

input {
  width: 200px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.popover.show {
  display: block; /* Force popover to always be visible */
}
</style>