<script>
//Utils
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";

//Layout
import Box from '/src/modules/medico/views/fichas/Box.vue';


export default { 
data() {
    return {

    tableShow:0,
    tableOptions:{
        ALL:0,
        WOMAM:1,
        MAN:2,
        WOMAM_ERC:3,
        MAN_ERC:4,
    },

    //data
    recordatorio:{
        actividad_fisica:{
            value:"si",
            options:[
                { label: "Si", value: "si"},
                { label: "No", value: "no"},
            ]
        },
        descp_af:{
            value:"",
        },
        tiempo_af:{
            value:"",
        },
        hora_desp:{
            value:"",
        },
        hora_desayuno:{
            value:"",
        },
        comida_desayuno:{
            value:"",
        },
        bebida_desayuno:{
            value:"",
        },
        comida_refaccion:{
            value:"",
        },
        bebida_refaccion:{
            value:"",
        },
        comida_almuerzo:{
            value:"",
        },
        bebida_almuerzo:{
            value:"",
        },
        comida_cena:{
            value:"",
        },
        bebida_cena:{
            value:"",
        },
        cantida_agua:{
            value:"",
        },
        type_agua:{
            value:"lt",
            options:[
                { label: "Mililitros", value: "ml"},
                { label: "Litros", value: "lt"},
            ]
        },
        hora_dormir:{
            value:"",
        },
        interrupcion_suenio:{
            value:"",
        },
        hora_despertar:{
            value:"",
        },
        horas_de_sueño:{
            value:"",
        },
    },

    evaluacion:{
        peso_lb:"",
        peso_kg:"",
        talla:"",
        imc:"",
        porcentaje_grasa:"",
        masa_muscular:"",
        porcentaje_agua:"",
        masa_sin_grasa:"",
        masa_osea:"",
        porcentaje_proteina:"",
        grasa_visceral:"",
        meta_basal:"",
        meta_edad:"",
    },

    pa_femenino:{
    cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    verduras_lc:{
         grupo:"Verduras de libre consumo",
         porciones:"",
         calorias:"10",
         carbohidratos:"2.5",
         lipidos:"0",
         proteinas:"0"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_ag:{
         grupo:"Carnes altas en grasa",
         porciones:"",
         calorias:"120",
         carbohidratos:"1",
         lipidos:"8",
         proteinas:"11"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_ag:{
         grupo:"Lacteos alto en grasa",
         porciones:"",
         calorias:"110",
         carbohidratos:"9",
         lipidos:"6",
         proteinas:"5"
        },
    lacteos_mg:{
         grupo:"Lacteo medio en grasa",
         porciones:"",
         calorias:"85",
         carbohidratos:"9",
         lipidos:"3",
         proteinas:"5"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    alimentos_lipidos:{
         grupo:"Alimentos ricos en lipidos",
         porciones:"",
         calorias:"175",
         carbohidratos:"5",
         lipidos:"15",
         proteinas:"5"
        },
    proteina_scp:{
         grupo:"Proteína (scoop)",
         porciones:"",
         calorias:"100",
         carbohidratos:"0",
         lipidos:"0",
         proteinas:"25"
        },
    },
    total_pa_femenino:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    },
    suma_total_pa_femenino:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_f:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_f:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_f:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    pa_masculino:{
        cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    verduras_lc:{
         grupo:"Verduras de libre consumo",
         porciones:"",
         calorias:"10",
         carbohidratos:"2.5",
         lipidos:"0",
         proteinas:"0"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_ag:{
         grupo:"Carnes altas en grasa",
         porciones:"",
         calorias:"120",
         carbohidratos:"1",
         lipidos:"8",
         proteinas:"11"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_ag:{
         grupo:"Lacteos alto en grasa",
         porciones:"",
         calorias:"110",
         carbohidratos:"9",
         lipidos:"6",
         proteinas:"5"
        },
    lacteos_mg:{
         grupo:"Lacteo medio en grasa",
         porciones:"",
         calorias:"85",
         carbohidratos:"9",
         lipidos:"3",
         proteinas:"5"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    alimentos_lipidos:{
         grupo:"Alimentos ricos en lipidos",
         porciones:"",
         calorias:"175",
         carbohidratos:"5",
         lipidos:"15",
         proteinas:"5"
        },
    proteina_scp:{
         grupo:"Proteína (scoop)",
         porciones:"",
         calorias:"100",
         carbohidratos:"0",
         lipidos:"0",
         proteinas:"25"
        },
    },
    total_pa_masculino:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    },
    suma_total_pa_masculino:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_m:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_m:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_m:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    pa_femenino_erc:{
        cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    },
    total_pa_femenino_erc:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    },
    suma_total_pa_femenino_erc:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_ferc:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_ferc:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_ferc:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    pa_masculino_erc:{
        cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    },
    total_pa_masculino_erc:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    },
    suma_total_pa_masculino_erc:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_merc:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_merc:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_merc:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    extra:{
        reconmendaciones: "",
        observaiones: "",
    }
}},
components: {
    Box
},
computed: {
    ...mapGetters({
        AuthGetUserInfo: 'AuthGetUserInfo',
        GetMedicoFichaActive: 'GetMedicoFichaActive',
        GetReloadFicha: 'GetReloadFicha'
    })
},
watch: {

        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
        
            const self = this;

            if (self.GetMedicoFichaActive.metadata.nutricion_recordatorio) {
            Object.keys(self.recordatorio).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.nutricion_recordatorio[value]) {
                        self.recordatorio[value].value = self.GetMedicoFichaActive.metadata.nutricion_recordatorio[value];
                    }
                });
            }

            if (self.GetMedicoFichaActive.metadata.nutricion_evaluacion) {
            Object.keys(self.evaluacion).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.nutricion_evaluacion[value]) {
                        self.evaluacion[value] = self.GetMedicoFichaActive.metadata.nutricion_evaluacion[value];
                    }
                });
            }

            if(self.GetMedicoFichaActive.metadata.datos_paciente){
            if(self.GetMedicoFichaActive.metadata.datos_paciente.peso && (!self.evaluacion.peso_lb || isNaN(self.evaluacion.peso_lb))){
                self.evaluacion.peso_lb=self.GetMedicoFichaActive.metadata.datos_paciente.peso;
                self.evaluacion.peso_kg=self.lbToKg(self.evaluacion.peso_lb);
            }
            if(self.GetMedicoFichaActive.metadata.datos_paciente.talla && !self.evaluacion.talla){
                self.evaluacion.talla=self.GetMedicoFichaActive.metadata.datos_paciente.talla;
            }
            }


            Object.keys(self.pa_femenino).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.pa_femenino) {
                        
                            self.pa_femenino[value].porciones = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_porciones'] || "";
                            self.pa_femenino[value].calorias = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_calorias'] || "";
                            self.pa_femenino[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_carbohidratos'] || "";
                            self.pa_femenino[value].lipidos = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_lipidos'] || "";
                            self.pa_femenino[value].proteinas = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_proteinas'] || "";
                        
                    }
                });
            Object.keys(self.pa_masculino).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pa_masculino) {
                    
                        self.pa_masculino[value].porciones = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_porciones'] || "";
                        self.pa_masculino[value].calorias = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_calorias'] || "";
                        self.pa_masculino[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_carbohidratos'] || "";
                        self.pa_masculino[value].lipidos = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_lipidos'] || "";
                        self.pa_masculino[value].proteinas = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.pa_femenino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pa_femenino_erc) {
                    
                        self.pa_femenino_erc[value].porciones = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_porciones'] || "";
                        self.pa_femenino_erc[value].calorias = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_calorias'] || "";
                        self.pa_femenino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_carbohidratos'] || "";
                        self.pa_femenino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_lipidos'] || "";
                        self.pa_femenino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.pa_masculino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pa_masculino_erc) {
                    
                        self.pa_masculino_erc[value].porciones = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_porciones'] || "";
                        self.pa_masculino_erc[value].calorias = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_calorias'] || "";
                        self.pa_masculino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_carbohidratos'] || "";
                        self.pa_masculino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_lipidos'] || "";
                        self.pa_masculino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_proteinas'] || "";
                    
                }
            });

            // SUMA TOTAL

            Object.keys(self.suma_total_pa_femenino).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.suma_total_pa_femenino) {
                        
                            self.suma_total_pa_femenino[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_porciones'] || "";
                            self.suma_total_pa_femenino[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_calorias'] || "";
                            self.suma_total_pa_femenino[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_carbohidratos'] || "";
                            self.suma_total_pa_femenino[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_lipidos'] || "";
                            self.suma_total_pa_femenino[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_proteinas'] || "";
                        
                    }
                });
            Object.keys(self.suma_total_pa_masculino).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.suma_total_pa_masculino) {
                    
                        self.suma_total_pa_masculino[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_porciones'] || "";
                        self.suma_total_pa_masculino[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_calorias'] || "";
                        self.suma_total_pa_masculino[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_carbohidratos'] || "";
                        self.suma_total_pa_masculino[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_lipidos'] || "";
                        self.suma_total_pa_masculino[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.suma_total_pa_femenino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc) {
                    
                        self.suma_total_pa_femenino_erc[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_porciones'] || "";
                        self.suma_total_pa_femenino_erc[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_calorias'] || "";
                        self.suma_total_pa_femenino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_carbohidratos'] || "";
                        self.suma_total_pa_femenino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_lipidos'] || "";
                        self.suma_total_pa_femenino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.suma_total_pa_masculino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc) {
                    
                        self.suma_total_pa_masculino_erc[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_porciones'] || "";
                        self.suma_total_pa_masculino_erc[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_calorias'] || "";
                        self.suma_total_pa_masculino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_carbohidratos'] || "";
                        self.suma_total_pa_masculino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_lipidos'] || "";
                        self.suma_total_pa_masculino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_proteinas'] || "";
                    
                }
            });


            //LOAD REQUERIMENTS

            
            Object.keys(self.requerimientos_f).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_f) {
                    Object.keys(self.requerimientos_f[value]).map(function (value2) {
                        self.requerimientos_f[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_f[value+'_'+value2] || "";
                    });       
                }
            });
            Object.keys(self.requerimientos_m).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_m) {
                    Object.keys(self.requerimientos_m[value]).map(function (value2) {
                        self.requerimientos_m[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_m[value+'_'+value2] || "";
                    });       
                }
            });
            Object.keys(self.requerimientos_ferc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_ferc) {
                    Object.keys(self.requerimientos_ferc[value]).map(function (value2) {
                        self.requerimientos_ferc[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_ferc[value+'_'+value2] || "";
                    });       
                }
            });
            Object.keys(self.requerimientos_merc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_merc) {
                    Object.keys(self.requerimientos_merc[value]).map(function (value2) {
                        self.requerimientos_merc[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_merc[value+'_'+value2] || "";
                    });       
                }
            });
            self.calcularRequerimientos('requerimientos_f')
            self.calcularRequerimientos('requerimientos_m')
            self.calcularRequerimientos('requerimientos_ferc')
            self.calcularRequerimientos('requerimientos_merc')

            // LOAD METABOLISMO BASAL
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_f){
                self.metabolismo_basal_f=self.GetMedicoFichaActive.metadata.metabolismo_basal_f;
            }
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_m){
                self.metabolismo_basal_m=self.GetMedicoFichaActive.metadata.metabolismo_basal_m;
            }
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_ferc){
                self.metabolismo_basal_ferc=self.GetMedicoFichaActive.metadata.metabolismo_basal_ferc;
            }
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_merc){
                self.metabolismo_basal_merc=self.GetMedicoFichaActive.metadata.metabolismo_basal_merc;
            }

            // LOAD PLIEGUES
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_f){
                self.sumatoria_seis_pliegues_f=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_f;
            }
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_m){
                self.sumatoria_seis_pliegues_m=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_m;
            }
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_ferc){
                self.sumatoria_seis_pliegues_ferc=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_ferc;
            }
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_merc){
                self.sumatoria_seis_pliegues_merc=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_merc;
            }

            // LOAD EXTRA
            if(self.GetMedicoFichaActive.metadata.extra){
                self.extra=self.GetMedicoFichaActive.metadata.extra;
            }

            self.calcularTotal(1);
            self.calcularTotal(2);
            self.calcularTotal(3);
            self.calcularTotal(4);

        }
    }
        
    
},
mounted() {

    this.emitter.emit("medico-ficha-refresh",['nutricion_recordatorio','nutricion_evaluacion','datos_paciente','pa_femenino','pa_masculino','pa_femenino_erc','pa_masculino_erc','suma_total_pa_femenino','suma_total_pa_masculino','suma_total_pa_femenino_erc','suma_total_pa_masculino_erc','requerimientos_f','requerimientos_m','requerimientos_ferc','requerimientos_merc','metabolismo_basal_f','metabolismo_basal_m','metabolismo_basal_ferc','metabolismo_basal_merc','sumatoria_seis_pliegues_f','sumatoria_seis_pliegues_m','sumatoria_seis_pliegues_ferc','sumatoria_seis_pliegues_merc','extra'])

},
methods: {
    ...mapActions({
    }),
    dayjs,
    Numbro,
    saveRecordatorio(){
        const self = this;

        const arrToSave= {};

        Object.keys(self.recordatorio).map(function(value){
            arrToSave[value] = self.recordatorio[value].value;
        });

        API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'nutricion_recordatorio',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['nutricion_recordatorio']);
                    
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
    },
    saveEvaluacion(src){
        const self = this;

        if(src==="lb"){
            self.evaluacion.peso_kg= self.lbToKg(self.evaluacion.peso_lb);
        }else if(src==="kg"){
            self.evaluacion.peso_lb= self.kgToLb(self.evaluacion.peso_kg);
        }

        self.evaluacion.imc = self.calcularIMC()

        API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'nutricion_evaluacion',
                    detail: self.evaluacion,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['nutricion_evaluacion']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
    },
    calcularIMC() {
    const self = this;
    let peso = 0;

    if(self.evaluacion.peso_lb){
        peso = parseFloat(self.evaluacion.peso_lb).toFixed(1);
    }
    else if(self.evaluacion.peso_kg){
        peso = parseFloat(self.self.evaluacion.peso_kg).toFixed(1);
    }

    const tallaPulgadas = self.evaluacion.talla / 2.54;
    const IMC = (703 * peso) / (tallaPulgadas ** 2);
    const tempIMC = parseFloat(IMC).toFixed(1);

    if (isNaN(tempIMC) || self.evaluacion.talla === "" || self.evaluacion.talla == 0) {
        return 0;
    }

    return tempIMC;

    },
    lbToKg(value) {
            return parseFloat(parseFloat(value) * 0.45359237).toFixed(2);
    },
    kgToLb(value) {
    return parseFloat(parseFloat(value) / 0.45359237).toFixed(2);
    },
    savePa(type){
        const self = this;
      
        self.calcularTotal(type);     
            if(type === 1){
                self.savePaFemenino();
                self.saveSumaTotalPaFemenino();   
            }else if(type === 2){
                self.savePaMasculino();
                self.saveSumaTotalPaMasculino();  
            }else if(type === 3){
                self.savePaFemeninoERC();
                self.saveSumaTotalPaFemeninoERC();  
            }else if(type === 4){
                self.savePaMasculinoERC();  
                self.saveSumaTotalPaMasculinoERC();
            }
    },
    calcularTotal(type){
        const self = this;

        if(type===1){
         
        self.resetPaFemenino();
        Object.keys(self.pa_femenino).map(function (value) {
            self.total_pa_femenino[value].calorias = self.pa_femenino[value].calorias ? (parseFloat(self.pa_femenino[value].calorias) * (parseFloat(self.pa_femenino[value].porciones) || 0)) : 0;
            self.total_pa_femenino[value].carbohidratos = self.pa_femenino[value].carbohidratos ?  (parseFloat(self.pa_femenino[value].carbohidratos) * (parseFloat(self.pa_femenino[value].porciones) || 0)) : 0;
            self.total_pa_femenino[value].lipidos = self.pa_femenino[value].lipidos ?  (parseFloat(self.pa_femenino[value].lipidos) * (parseFloat(self.pa_femenino[value].porciones) || 0)) : 0;
            self.total_pa_femenino[value].proteinas = self.pa_femenino[value].proteinas ? (parseFloat(self.pa_femenino[value].proteinas) * (parseFloat(self.pa_femenino[value].porciones) || 0)) : 0;

            self.suma_total_pa_femenino.total.calorias += self.total_pa_femenino[value].calorias;
            self.suma_total_pa_femenino.total.carbohidratos += self.total_pa_femenino[value].carbohidratos;
            self.suma_total_pa_femenino.total.lipidos += self.total_pa_femenino[value].lipidos;
            self.suma_total_pa_femenino.total.proteinas += self.total_pa_femenino[value].proteinas;
        });
        self.calcularPorcentajeAdecuado('requerimientos_f');
        }else if(type===2){
        self.resetPaMasculino();
        Object.keys(self.pa_masculino).map(function (value) {
            self.total_pa_masculino[value].calorias = self.pa_masculino[value].calorias ? (parseFloat(self.pa_masculino[value].calorias) * (parseFloat(self.pa_masculino[value].porciones) || 0)) : 0;
            self.total_pa_masculino[value].carbohidratos = self.pa_masculino[value].carbohidratos ?  (parseFloat(self.pa_masculino[value].carbohidratos) * (parseFloat(self.pa_masculino[value].porciones) || 0)) : 0;
            self.total_pa_masculino[value].lipidos = self.pa_masculino[value].lipidos ?  (parseFloat(self.pa_masculino[value].lipidos) * (parseFloat(self.pa_masculino[value].porciones) || 0)) : 0;
            self.total_pa_masculino[value].proteinas = self.pa_masculino[value].proteinas ? (parseFloat(self.pa_masculino[value].proteinas) * (parseFloat(self.pa_masculino[value].porciones) || 0)) : 0;

            self.suma_total_pa_masculino.total.calorias += self.total_pa_masculino[value].calorias;
            self.suma_total_pa_masculino.total.carbohidratos += self.total_pa_masculino[value].carbohidratos;
            self.suma_total_pa_masculino.total.lipidos += self.total_pa_masculino[value].lipidos;
            self.suma_total_pa_masculino.total.proteinas += self.total_pa_masculino[value].proteinas;
        });
        self.calcularPorcentajeAdecuado('requerimientos_m');
        }else if(type===3){
        self.resetPaFemeninoERC();
        Object.keys(self.pa_femenino_erc).map(function (value) {
            self.total_pa_femenino_erc[value].calorias = self.pa_femenino_erc[value].calorias ? (parseFloat(self.pa_femenino_erc[value].calorias) * (parseFloat(self.pa_femenino_erc[value].porciones) || 0)) : 0;
            self.total_pa_femenino_erc[value].carbohidratos = self.pa_femenino_erc[value].carbohidratos ?  (parseFloat(self.pa_femenino_erc[value].carbohidratos) * (parseFloat(self.pa_femenino_erc[value].porciones) || 0)) : 0;
            self.total_pa_femenino_erc[value].lipidos = self.pa_femenino_erc[value].lipidos ?  (parseFloat(self.pa_femenino_erc[value].lipidos) * (parseFloat(self.pa_femenino_erc[value].porciones) || 0)) : 0;
            self.total_pa_femenino_erc[value].proteinas = self.pa_femenino_erc[value].proteinas ? (parseFloat(self.pa_femenino_erc[value].proteinas) * (parseFloat(self.pa_femenino_erc[value].porciones) || 0)) : 0;

            self.suma_total_pa_femenino_erc.total.calorias += self.total_pa_femenino_erc[value].calorias;
            self.suma_total_pa_femenino_erc.total.carbohidratos += self.total_pa_femenino_erc[value].carbohidratos;
            self.suma_total_pa_femenino_erc.total.lipidos += self.total_pa_femenino_erc[value].lipidos;
            self.suma_total_pa_femenino_erc.total.proteinas += self.total_pa_femenino_erc[value].proteinas;
        });
        self.calcularPorcentajeAdecuado('requerimientos_ferc');
        }else if(type===4){
        self.resetPaMasculinoERC();
        Object.keys(self.pa_masculino_erc).map(function (value) {
            self.total_pa_masculino_erc[value].calorias = self.pa_masculino_erc[value].calorias ? (parseFloat(self.pa_masculino_erc[value].calorias) * (parseFloat(self.pa_masculino_erc[value].porciones) || 0)) : 0;
            self.total_pa_masculino_erc[value].carbohidratos = self.pa_masculino_erc[value].carbohidratos ?  (parseFloat(self.pa_masculino_erc[value].carbohidratos) * (parseFloat(self.pa_masculino_erc[value].porciones) || 0)) : 0;
            self.total_pa_masculino_erc[value].lipidos = self.pa_masculino_erc[value].lipidos ?  (parseFloat(self.pa_masculino_erc[value].lipidos) * (parseFloat(self.pa_masculino_erc[value].porciones) || 0)) : 0;
            self.total_pa_masculino_erc[value].proteinas = self.pa_masculino_erc[value].proteinas ? (parseFloat(self.pa_masculino_erc[value].proteinas) * (parseFloat(self.pa_masculino_erc[value].porciones) || 0)) : 0;

            self.suma_total_pa_masculino_erc.total.calorias += self.total_pa_masculino_erc[value].calorias;
            self.suma_total_pa_masculino_erc.total.carbohidratos += self.total_pa_masculino_erc[value].carbohidratos;
            self.suma_total_pa_masculino_erc.total.lipidos += self.total_pa_masculino_erc[value].lipidos;
            self.suma_total_pa_masculino_erc.total.proteinas += self.total_pa_masculino_erc[value].proteinas;
        });
        self.calcularPorcentajeAdecuado('requerimientos_merc');
        }

    },
    resetPaFemenino(){
    this.total_pa_femenino ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    }
    this.suma_total_pa_femenino.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    resetPaMasculino(){
    this.total_pa_masculino ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    }
    this.suma_total_pa_masculino.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    resetPaFemeninoERC(){
    this.total_pa_femenino_erc ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    }
    this.suma_total_pa_femenino_erc.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    resetPaMasculinoERC(){
    this.total_pa_masculino_erc ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    }
    this.suma_total_pa_masculino_erc.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    savePaFemenino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_femenino).map(function (value) {
            Object.keys(self.pa_femenino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_femenino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_femenino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_femenino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    savePaMasculino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_masculino).map(function (value) {
            Object.keys(self.pa_masculino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_masculino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_masculino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_masculino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    savePaFemeninoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_femenino_erc).map(function (value) {
            Object.keys(self.pa_femenino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_femenino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_femenino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_femenino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    savePaMasculinoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_masculino_erc).map(function (value) {
            Object.keys(self.pa_masculino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_masculino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_masculino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_masculino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaFemenino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_femenino).map(function (value) {
            Object.keys(self.suma_total_pa_femenino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_femenino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_femenino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_femenino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaMasculino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_masculino).map(function (value) {
            Object.keys(self.suma_total_pa_masculino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_masculino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_masculino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_masculino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaFemeninoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_femenino_erc).map(function (value) {
            Object.keys(self.suma_total_pa_femenino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_femenino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_femenino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_femenino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaMasculinoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_masculino_erc).map(function (value) {
            Object.keys(self.suma_total_pa_masculino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_masculino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_masculino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_masculino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosF(){
        const self = this;
        self.calcularRequerimientos('requerimientos_f')
        let itemsTosave={};
        Object.keys(self.requerimientos_f).map(function (value) {
            Object.keys(self.requerimientos_f[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_f[value][value2] || 0;
            });
            });
                console.log( itemsTosave)
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_f",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_f']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosM(){
        const self = this;
        self.calcularRequerimientos('requerimientos_m')
        let itemsTosave={};
        Object.keys(self.requerimientos_m).map(function (value) {
            Object.keys(self.requerimientos_m[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_m[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_m",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_m']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosFERC(){
        const self = this;
        self.calcularRequerimientos('requerimientos_ferc')
        let itemsTosave={};
        Object.keys(self.requerimientos_ferc).map(function (value) {
            Object.keys(self.requerimientos_ferc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_ferc[value][value2] || 0;
            });
            });
             
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_ferc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_ferc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosMERC(){
        const self = this;
        self.calcularRequerimientos('requerimientos_merc')
        let itemsTosave={};
        Object.keys(self.requerimientos_merc).map(function (value) {
            Object.keys(self.requerimientos_merc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_merc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_merc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_merc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    calcularRequerimientos(item){
        const self = this;
        //Calculo de GR_D
        self[item].carbohidratos.gr_d=(parseFloat((parseFloat(self[item].kcal_dia.vct) || 0)*(parseFloat(self[item].carbohidratos.vct) || 0)).toFixed(5) || 0) / 4;

        self[item].lipidos.gr_d=(parseFloat((parseFloat(self[item].kcal_dia.vct) || 0)*(parseFloat(self[item].lipidos.vct) || 0)).toFixed(5) || 0) / 9;

        self[item].proteinas.gr_d=(parseFloat((parseFloat(self[item].kcal_dia.vct) || 0)*(parseFloat(self[item].proteinas.vct) || 0)).toFixed(5) || 0) / 4;

        //Calculo de GR_KG_D
        self[item].carbohidratos.gr_kg_d = this.dividirYValidad(self[item].carbohidratos.gr_d, self[item].peso.kg);
        self[item].lipidos.gr_kg_d = this.dividirYValidad(self[item].lipidos.gr_d, self[item].peso.kg);
        self[item].proteinas.gr_kg_d = this.dividirYValidad(self[item].proteinas.gr_d, self[item].peso.kg);

        self.calcularPorcentajeAdecuado(item);
    },
    calcularPorcentajeAdecuado(item){
        const self = this;
        
        function calcular(req,porc) {
            self[porc].adecuado.calorias= parseFloat((parseFloat(self[porc].total.calorias)*100)/parseFloat(self[req].kcal_dia.vct)).toFixed(2);
            self[porc].adecuado.carbohidratos= parseFloat((parseFloat(self[porc].total.carbohidratos)*100)/parseFloat(self[req].carbohidratos.gr_d)).toFixed(2);
            self[porc].adecuado.lipidos= parseFloat((parseFloat(self[porc].total.lipidos)*100)/parseFloat(self[req].lipidos.gr_d)).toFixed(2);
            self[porc].adecuado.proteinas= parseFloat((parseFloat(self[porc].total.proteinas)*100)/parseFloat(self[req].proteinas.gr_d)).toFixed(2);

            if (isNaN(self[porc].adecuado.calorias) || !isFinite(self[porc].adecuado.calorias))
            self[porc].adecuado.calorias=0;
            if (isNaN(self[porc].adecuado.carbohidratos) || !isFinite(self[porc].adecuado.carbohidratos))
            self[porc].adecuado.carbohidratos=0;
            if (isNaN(self[porc].adecuado.lipidos) || !isFinite(self[porc].adecuado.lipidos))
            self[porc].adecuado.lipidos=0;
            if (isNaN(self[porc].adecuado.proteinas) || !isFinite(self[porc].adecuado.proteinas))
            self[porc].adecuado.proteinas=0;
        }

        if(item=='requerimientos_f'){
            calcular(item,'suma_total_pa_femenino');
            self.calcularKcalBasal('metabolismo_basal_f');

        }else if(item=='requerimientos_m'){
            calcular(item,'suma_total_pa_masculino');
            self.calcularKcalBasal('metabolismo_basal_m');

        }else if(item=='requerimientos_ferc'){
            calcular(item,'suma_total_pa_femenino_erc');
            self.calcularKcalBasal('metabolismo_basal_ferc');

        }else if(item=='requerimientos_merc'){
            calcular(item,'suma_total_pa_masculino_erc');
            self.calcularKcalBasal('metabolismo_basal_merc');

        }

      
    },
    dividirYValidad(gr_d, kg) {
    let resultado = parseFloat(gr_d) / parseFloat(kg);
    if (isNaN(resultado) || !isFinite(resultado)) {
        resultado = 0;
    }
    return resultado.toFixed(5);
    },
    calcularKcalBasal(item){
        const self = this;

            if(self.GetMedicoFichaActive.cliente.genero=='F'){
                 self[item].kcal_basal= (self.evaluacion.peso_kg*10)+(6.25*self.evaluacion.talla)-(5*self.GetMedicoFichaActive.cliente.edad)-161;
            }else {
                self[item].kcal_basal= (self.evaluacion.peso_kg*10)+(6.25*self.evaluacion.talla)-(5*self.GetMedicoFichaActive.cliente.edad)+5;
            }
            
            if (isNaN(self[item].kcal_basal) || !isFinite(self[item].kcal_basal)) {
                self[item].kcal_basal = 0;
            }

            self[item].af_sedentario= parseFloat(self[item].kcal_basal*1.4).toFixed(4);
            self[item].af_moderada= parseFloat(self[item].kcal_basal*1.53).toFixed(4);
            self[item].af_severa= parseFloat(self[item].kcal_basal*1.75).toFixed(4);
   
    },
    saveMetaBasal(slug){
        
        const self = this;
        self.calcularKcalBasal(slug);
        let itemsTosave = self[slug];
            
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug,
                detail: itemsTosave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                 self.emitter.emit("medico-ficha-notify",[slug]);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            }); 
    },
    savePlieges(slug){

        const self = this;

        self[slug].total = 0;

        Object.keys(self[slug]).map(function (value){
            if(value !== 'total'){
                self[slug].total+= parseFloat(self[slug][value]) || 0;
            }
        });

        
        let itemsTosave = self[slug];
            
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug,
                detail: itemsTosave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                 self.emitter.emit("medico-ficha-notify",[slug]);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            }); 
    },
    saveExtra(){
        const self= this;
      
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'extra',
                detail: self.extra,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                 self.emitter.emit("medico-ficha-notify",['extra']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            }); 

    }

},
 }
</script>
 
 
<template>
   <box title="Recordatorio de 24 horas" icon="fas fa-clock" :contraction='true'>
        <div class="row">
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Actividad física</label>
                    <select class="form-control" v-model="recordatorio.actividad_fisica.value" @change="saveRecordatorio">
                            <option v-for="(item,index) in recordatorio.actividad_fisica.options" :value="item.value" :key="index">{{ item.label }}</option>
                    </select>
                </div>
            </div>
            <div v-if="recordatorio.actividad_fisica.value==='si'" class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Descripción de la actividad física</label>
                    <input type="text" class="form-control" v-model="recordatorio.descp_af.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div v-if="recordatorio.actividad_fisica.value==='si'" class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tiempo de la actividad física</label>
                    <input type="text" class="form-control" v-model="recordatorio.tiempo_af.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Hora de despertar</label>
                    <input type="text" class="form-control" v-model="recordatorio.hora_desp.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Hora de desayuno</label>
                    <input type="text" class="form-control" v-model="recordatorio.hora_desayuno.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de comida para el desayuno</label>
                    <input type="text" class="form-control" v-model="recordatorio.comida_desayuno.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de bebida para el desayuno</label>
                    <input type="text" class="form-control" v-model="recordatorio.bebida_desayuno.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de comida para la refacción</label>
                    <input type="text" class="form-control" v-model="recordatorio.comida_refaccion.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de bebida para la refacción</label>
                    <input type="text" class="form-control" v-model="recordatorio.bebida_refaccion.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de comida para el almuerzo</label>
                    <input type="text" class="form-control" v-model="recordatorio.comida_almuerzo.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de bebida para el almuerzo</label>
                    <input type="text" class="form-control" v-model="recordatorio.bebida_almuerzo.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de comida para la cena</label>
                    <input type="text" class="form-control" v-model="recordatorio.comida_cena.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Tipo de bebida para la cena</label>
                    <input type="text" class="form-control" v-model="recordatorio.bebida_cena.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Cantidad de agua que se toma durante el día</label>
                   <div class="d-flex">
                    <input v-model="recordatorio.cantida_agua.value" type="text" class="form-control w-50" aria-label="Text input with dropdown button" @change="saveRecordatorio">
                    <div class="input-group-append w-50">
                        <select class="form-control" v-model="recordatorio.type_agua.value" @change="saveRecordatorio">
                            <option v-for="(item, index) in recordatorio.type_agua.options" :value="item.value" :key="index">{{ item.label }}</option>
                    </select>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>La hora cuando se acuesta</label>
                    <input type="text" class="form-control" v-model="recordatorio.hora_dormir.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Se despierta en la noche y con qué frecuencia</label>
                    <input type="text" class="form-control" v-model="recordatorio.interrupcion_suenio.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>La hora cuando se despierta</label>
                    <input type="text" class="form-control" v-model="recordatorio.hora_despertar.value" @change="saveRecordatorio"/>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3">
                <div class="form-group">
                    <label>Promedio de horas en la noche que duerme</label>
                    <input type="text" class="form-control" v-model="recordatorio.horas_de_sueño.value" @change="saveRecordatorio"/>
                </div>
            </div>
        </div>
   </box>
   <box title="Evaluación" icon="fas fa-clipboard-list" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-8 col-md-4">
                <div class="form-group">
                    <label>Peso</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.peso_lb" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion('lb')"/>
                        <div class="input-group-append">
                            <span class="input-group-text">lb</span>
                        </div>
                        <input type="text" class="form-control" v-model="evaluacion.peso_kg" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"  @change="saveEvaluacion('kg')"/>
                        <div class="input-group-append">
                            <span class="input-group-text">Kg</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Talla</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.talla" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">cm</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>índice de masa corporal</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.imc" disabled/>
                        <div class="input-group-append">
                            <span class="input-group-text">IMC</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Porcentaje de grasa corporal</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.porcentaje_grasa" v-maska data-maska="%#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%/100</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Masa muscular</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.masa_muscular" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">Kg</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Porcentaje de agua corporal</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.porcentaje_agua" v-maska data-maska="%#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%/100</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Masa libre de grasa</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.masa_sin_grasa" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">Kg</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Masa ósea</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.masa_osea" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">Kg</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Porcentaje de proteína</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.porcentaje_proteina" v-maska data-maska="%#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%/100</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Porcentaje de grasa visceral</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.grasa_visceral" v-maska data-maska="%#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%/100</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Tasa de metabolismo basal</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.meta_basal" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">cal</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Edad metabólica</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.meta_edad" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                    </div>
                </div>
            </div>
            </div>
   </box>
   <box title="Plan de alimentación" icon="fas fa-mortar-pestle" :contraction='true'>
    <div class="row p-2">
        <div class="col-12 form-group">
            <label class="">Seleccione la tabla de calculo que desea ver:</label>
            <div class="input-group">
                <select class="form-control" v-model="tableShow">
                        <option :value="0">
                        Mostrar todo
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null" :value="1">
                        Femenino
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null" :value="2">
                        Masculino
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null" :value="3">
                        Femenino ERC
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null" :value="4">
                        Masculino ERC
                        </option>
                    </select>   
            </div>
        </div>
    </div>
    
    <div v-if="(tableShow === tableOptions.WOMAM ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo femenino
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_femenino" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(1)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_femenino" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.kcal_dia.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.carbohidratos.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_f.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.lipidos.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.proteinas.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.peso.kg" @change="saveRequerimientosF()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class=" text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-success font-weight-bold text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_femenino.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_f.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_f')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class="font-weight-bold">
                <th class="font-weight-bold">Sumatoria 6 pliegues</th>
                <th class="font-weight-bold">
                   {{ sumatoria_seis_pliegues_f.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.triceps" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.subescapular" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.abdominal" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div v-if="(tableShow === tableOptions.MAN ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo masculino
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_masculino" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(2)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_masculino" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.kcal_dia.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.carbohidratos.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_m.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.lipidos.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.proteinas.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.peso.kg" @change="saveRequerimientosM()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_masculino.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_m.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_m')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Sumatoria 6 pliegues</th>
                <th class=" font-weight-bold">
                    {{ sumatoria_seis_pliegues_m.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.triceps" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.subescapular" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.abdominal" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div v-if="(tableShow === tableOptions.WOMAM_ERC ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo femenino ERC
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_femenino_erc" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(3)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_femenino_erc" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.kcal_dia.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.carbohidratos.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_ferc.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.lipidos.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.proteinas.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.peso.kg" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0"> {{ suma_total_pa_femenino_erc.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_femenino_erc.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_ferc.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_ferc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Sumatoria 6 pliegues</th>
                <th class=" font-weight-bold">
                    {{ sumatoria_seis_pliegues_ferc.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.triceps" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.subescapular" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.abdominal" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div v-if="(tableShow === tableOptions.MAN_ERC ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo masculino ERC
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_masculino_erc" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(4)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_masculino_erc" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.kcal_dia.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.carbohidratos.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_merc.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.lipidos.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.proteinas.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.peso.kg" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_masculino_erc.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_merc.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_merc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Sumatoria 6 pliegues</th>
                <th class=" font-weight-bold">
                    {{ sumatoria_seis_pliegues_merc.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.triceps" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.subescapular" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.abdominal" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>

   </box>
   <box title="Recomendaciones y Observaciones" icon="fas fa-comments" :contraction='true'>

    <div class="row">
            <div class="col-12">
                <div class="form-group w-100">
                    <label>Recomendaciones</label>
                    <textarea v-model="extra.reconmendaciones" class="form-control" placeholder="Agregar recomendación..." style="min-height: 250px" @change="saveExtra"></textarea>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group w-100">
                    <label>Observaciones</label>
                    <textarea v-model="extra.observaiones" class="form-control" placeholder="Agregar observaciones..." style="min-height: 250px" @change="saveExtra"></textarea>
                </div>
            </div>
        </div>
   </box>
</template>
 
 
<style scoped>
 
</style>