<script>
import API from "src/core/Api";
import dayjs from "dayjs";
import Numbro from "numbro";
import {mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from '/src/modules/medico/views/fichas/Box.vue';

export default { 
 data() {
        return {
        //data

        showAddRango:false,
        showEditionRango:false,

        showAddSensacion:false,
        showEditionSensacion:false,

        showAddFuerzaM:false,
        showEditionFuerzaM:false,

        showAddPruebaEspecial: false,
        showEditionPruebaEspecial:false,
        
        fm_TotalScore:0,
        ib_TotalScore:0,
        lb_TotalScore:0,
        dp_TotalScore:0,
        historial_geriatrico:{
          nutricional: '',
          psiquiatrica: '',
          anamnesis: '',
        },

        formulario_mental:{
            orientacion:{
                orientacion_ask_1: {
                    ask: '¿El año en que estamos?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                orientacion_ask_2: {
                    ask: '¿La estación del año?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                orientacion_ask_3: {
                    ask: '¿La fecha de hoy?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                orientacion_ask_4: {
                    ask: '¿El día de la semana?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                orientacion_ask_5: {
                    ask: '¿El mes?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
        },
            ubicacion:{
                donde_estamos_ask1: {
                    ask: '¿Región, zona geográfica?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                donde_estamos_ask2: {
                    ask: '¿Estado?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                donde_estamos_ask3: {
                    ask: '¿Ciudad?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                donde_estamos_ask4: {
                    ask: '¿Nombre o tipo de local en dónde estamo?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
                donde_estamos_ask5: {
                    ask: '¿Piso o plana?',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
        },
            registro:{
          test: {
                    ask: 'Nombrar 3 objetos en 3 segundos, con una pausa entre cada uno de ellos. Aplicar 1 punto a cada objeto que el paciente consiga nombrar. Repetir los objetos hasta que el paciente nombre los tres. Puntuar solo la primera tentativa.',
                    check:false,
                    score: 3,
                    detailValue: '',
                },
        },
            calculo:{
          test: {
                    ask: 'Hacer que el paciente reste 7 de 100 y continuar la operación con el valor obtenido (es decir, resta seriada de 7). Aplicar 1 punto a cada respuesta correcta. Interrumpir la prueba después de 5 respuestas correctas.',
                    check:false,
                    score: 5,
                    detailValue: '',
                },
        },
            memoria:{
          test: {
                    ask: 'Hacer que el paciente recuerde el nombre de los tres objetos presentados en la fase de registro. Aplicar 1 punto a cada objeto recordado.',
                    check:false,
                    score: 3,
                    detailValue: '',
                },
        },
            nombrar:{
          test: {
                    ask: 'Señalar un bolígrafo y un reloj. Aplicar un punto a cada objeto que el paciente consiga nombrar.',
                    check:false,
                    score: 2,
                    detailValue: '',
                },
        },
            repeticion:{
          test: {
                    ask: 'Anotar los términos repetidos por el paciente: no, si y más.',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
        },
            lectura:{
          test: {
                    ask: 'El paciente debe leer y obedecer la orden siguiente ofrecida por escrito: “cierre los ojos',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
        },
            comprension:{
          test: {
                    ask: 'El paciente debe hacer las 3 fases de una orden: \n1. Coger un papel con la mano derecha \n2. Doblar el papel por la mitad \n3. Colocarlo en el suelo.\nAplicar 1 punto para cada fase realizada.',
                    check:false,
                    score: 3,
                    detailValue: '',
                },
        },
            escritura:{
          test: {
                    ask: 'El paciente debe escribir una frase escogida por el mismo. Aplicar 1 punto a las frases a las frases que contengan sujeto y objeto, y que tengan sentido. Ignorar los errores de ortografía.',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
        },
            disenio:{
          test: {
                    ask: 'Amplíe el diseño que aparece abajo hasta que tenga 1 a 5 cm y solicite al paciente que lo copie. Aplicar 1 punto si todos los lados y ángulos aparecen representados y si los lados de intersección forman un cuadrado.',
                    check:false,
                    score: 1,
                    detailValue: '',
                },
        }
        },
        
        indice_barthel:{
            vestirse:{
                label:'Describa el preceso de vestirse',
                type:'md',
                score:0,
                description1:'Independiente: capaz de ponerse y de quitarse la ropa, abotonarse, atarse los zapatos',
                description2:'Necesita ayuda',
                description3: 'Dependiente'
            },
            arreglarse:{
                label:'Describa el preceso de arreglarse',
                type:'sm',
                score:0,
                description1:'Independiente para lavarse la cara, las manos, peinarse, afeitarse, maquillarse, etc.',
                description2:'Dependiente',
            },
            deposiciones:{
                label:'Deposiciones (valorar la semana prevía)',
                type:'md',
                score:0,
                description1:'Continencia normal',
                description2:'Ocasionalmente algún episodio de incontinencia, o necesita ayuda para administrarse supositorios o lavativas',
                description3:'Incontinencia'
            },
            miccion:{
                label:'Micción (valórese la semana previa)',
                type:'md',
                score:0,
                description1:'Continencia normal, o es capaz de cuidarse de la sonda si tiene una puesta ',
                description2:'Un episodio diario como máximo de incontinencia, o necesita ayuda para cuidar de la sonda',
                description3:'Incontinencia'
            },
            usar_retrete:{
                label:'Describa el preceso de usar el retrete',
                type:'md',
                score:0,
                description1:'Independiente para ir al cuarto de aseo, quitarse y ponerse la ropa...',
                description2:'Necesita ayuda para ir al retrete, pero se limpia solo',
                description3:'Dependiente'
            },
            trasladarse:{
                label:'Describa el preceso de trasladarse',
                type:'lg',
                score:0,
                description1:'Independiente para ir del sillón a la cama',
                description2:'Mínima ayuda física o supervisión para hacerlo',
                description3:'Necesita gran ayuda, pero es capaz de mantenerse sentado solo',
                description4:'Dependiente'
            },
            deambular:{
                label:'Describa el preceso de deambular',
                type:'lg',
                score:0,
                description1:'Independiente, camina solo 50 metros',
                description2:'Necesita ayuda física o supervisión para caminar 50 metros',
                description3:'Independiente en silla de ruedas sin ayuda',
                description4:'Dependiente'
            },
            escalones:{
                label:'Describa el preceso de subir o bajar escalones',
                type:'md',
                score:0,
                description1:'Independiente para bajar y subir escaleras',
                description2:'Necesita ayuda física o supervisión para hacerlo',
                description3:'Dependiente'
            }
        },

        lawton_brody:{
            cuidar_casa:{
                name:'Cuidar la casa',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'Cuida la casa sin ayuda',
                value: 1,},
                {  option:'Hace todo, menos el trabajo pesado',
                value: 2,},
                {  option:'Tareas ligeras unicamente',
                value: 3,},
                {  option:'Necesita ayuda para todas las tareas',
                value: 4,},
                {  option:'Incapaz de hacer nada',
                value: 5,}
                ]
            },
            lavado_ropa:{
                name:'Lavado de ropa',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'La realiza personalmente',
                value: 1,},
                {  option:'Solo lava pequeñas prendas',
                value: 2,},
                {  option:'Incapaz de lavar',
                value: 3,}
                ]
            },
            preparacion_comida:{
                name:'Preparación de la comida',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'Planea prepara y sirve sin ayuda',
                value: 1,},
                {  option:'Prepara si le dan los ingredientes',
                value: 2,},
                {  option:'Prepara platos precocinados',
                value: 3,},
                {  option:'Tienen que darle la comida hecha',
                value: 4,},
                ]
            },
            ir_compras:{
                name:'Ir de compras',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'Lo hace sin ninguna ayuda',
                value: 1,},
                {  option:'Sólo hace pequeñas compras',
                value: 2,},
                {  option:'Tienen que acompañarle',
                value: 3,},
                {  option:'Es incapaz de ir de compras',
                value: 4,},
                ]
            },
            uso_telefono:{
                name:'Uso del telefono',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'Capaz de utilizarlo sin problemas',
                value: 1,},
                {  option:'Sólo para lugares muy familiares',
                value: 2,},
                {  option:'Puede contestar pero no llamar',
                value: 3,},
                {  option:'Incapaz de utilizarlo',
                value: 4,},
                ]
            },
            uso_transporte:{
                name:'Uso del transporte',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'Viaja en transporte publico o conduce',
                value: 1,},
                {  option:'Sólo en taxi, no en autobús',
                value: 2,},
                {  option:'Necesita acompañamiento',
                value: 3,},
                {  option:'Incapaz de usarlo',
                value: 4,},
                ]
            },
            manejo_dinero:{
                name:'Manejo del dinero',
                score:0,
                type:'select',
                options:[
                {  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'LLeva cuentas, va a bancos, etc',
                value: 1,},
                {  option:'Sólo maneja cuentas sencillas',
                value: 2,},
                {  option:'Incapaz de utilizar dinero',
                value: 3,},
                ]
            },
            responsable_medicamentos:{
                name:'Responsable de medicamentos',
                score:0,
                type:'select',
                options:[{  option:'Seleccione una opcion valida',
                value: 0,},
                {  option:'Es responsable de su propia medicación',
                value: 1,},
                {  option:'Hay que prepararselos',
                value: 2,},
                {  option:'Incapaz de hacerlo por sí sólo',
                value: 3,},
                
                ]
            },
            comentarios:{
                type:'textarea',
                content: ''
            }
        },

        evaluacion_dolor:{
            primary_question:{
                localizacion_dolor:{
                    label: 'Localizacion del dolor',
                    value:'',
                    type: 'text'
                },
                tiempo:{
                    label: 'Hace cuanto tiempo',
                    value:'',
                    type: 'text'
                },
                irradiado:{
                    label: 'Irradiado',
                    value:'No',
                    options:[
                        { option: 'Si' , value:'Si'},
                        { option: 'No' , value:'No'}
                    ],
                    type: 'select'
                },
                zona:{
                    label: 'Zona del dolor',
                    value:'',
                    type: 'text'
                },
                tipo:{
                    label: 'Tipo de dolor',
                    value:'No select',
                    options:[
                        { option: 'No select' , value:'No select'},
                        { option: 'Superficial' , value:'Superficial'},
                        { option: 'Profundo' , value:'Profundo'}
                    ],
                    type: 'select'
                },
                duracion:{
                    label: 'Duración del dolor',
                    value:'',
                    type: 'text'
                },
                especificacion:{
                    label: 'Especificación del dolor',
                    value:'',
                    type: 'text'
                },
                fracuencia:{
                    label: 'Frecuencia del dolor',
                    value:'',
                    type: 'text'
                },
                movimientos_dolor:{
                    label: 'Movimiento y actividades que intensifican el dolor',
                    value:'',
                    type: 'text'
                },
                medicamentos_dolor:{
                    label: 'Medicamentos, movimientos y actividades que alivian el dolor',
                    value:'',
                    type: 'text'
                },
            },
            escala_visual_simple:{
                label:'Escala visual analógica (Simple)',
                value:'',
                options:[
                {option:'Sin dolor'},
                {option:'Dolor minimo'},
                {option:'Dolor moderado'},
                {option:'Dolor intenso'},
                {option:'Dolor muy intenso'},
                {option:'Peor dolor imaginable'}]
            },
            escala_visual_numerica:{
                label:'Escala visual analógica (Numérica)',
                value:'',
                options:[
                {option:'0'},
                {option:'1'},
                {option:'2'},
                {option:'3'},
                {option:'4'},
                {option:'5'},
                {option:'6'},
                {option:'7'},
                {option:'8'},
                {option:'9'},
                {option:'10'}
                ]
            },
            escala_visual_grafica:{
                label:'Escala visual grafica',
                value:'',
                options:[
                {option:'0' , icon:'fa-regular fa-face-grin-beam fa-2x'},
                {option:'1' , icon:'fa-regular fa-face-grin fa-2x'},
                {option:'2' , icon:'fa-regular fa-face-meh fa-2x'},
                {option:'3' , icon:'fa-regular fa-face-frown-open fa-2x'},
                {option:'4' , icon:'fa-regular fa-face-frown fa-2x'},
                {option:'5' , icon:'fa-regular fa-face-sad-cry fa-2x'},
            ]
            },
            comentarios:{
                value:''
            }
        },
        evaluacion_postural:{
            anterior:{
                label:'Vista anterior',
                value:''
            },
            posterior:{
                label:'Vista posterior',
                value:''
            },
            lateral_derecha:{
                label:'Vista lateral derecha',
                value:''
            },
            lateral_izquierda:{
                label:'Vista lateral izquierda',
                value:''
            },
            comentarios:{
                label:'Comentarios',
                value:''
            }
        },
        depre_geriatrica:{
            ask1:{
                label:'¿Esta usted satisfecho con su vida?',
                si:false,
                no:false
            },
            ask2:{
                label:'¿Ha dejado muchas de sus actividades e intereses?',
                si:false,
                no:false
            },
            ask3:{
                label:'¿Siente que su vida ha sido vacía?',
                si:false,
                no:false
            },
            ask4:{
                label:'¿Se siete aburrido a menudo?',
                si:false,
                no:false
            },
            ask5:{
                label:'¿Se mantiene en un buen estado de ánimo la mayor parte del tiempo?',
                si:false,
                no:false
            },
            ask6:{
                label:'¿Tiene temor que le pueda ocurrir algo malo?',
                si:false,
                no:false
            },
            ask7:{
                label:'¿Se siente feliz la mayor parte del tiempo?',
                si:false,
                no:false
            },
            ask8:{
                label:'¿Se siente a menudo impotente?',
                si:false,
                no:false
            },
            ask9:{
                label:'¿Prefiere quedarse en casa en vez de salir a conocer cosas nuevas?',
                si:false,
                no:false
            },
            ask10:{
                label:'¿Considera que tiene mas problemas con la memoria que el resto de las personas?',
                si:false,
                no:false
            },
            ask11:{
                label:'¿Considera que es maravilloso estar vivo ahora?',
                si:false,
                no:false
            },
            ask12:{
                label:'¿Se siente infravalorado por la forma en la que ahora se encuentra?',
                si:false,
                no:false
            },
            ask13:{
                label:'¿Se siente lleno de energía?',
                si:false,
                no:false
            },
            ask14:{
                label:'¿Se siente sin esperanza en su situación actual?',
                si:false,
                no:false
            },
            ask15:{
                label:'¿Creen que la mayor parte de las personas está mejor que usted?',
                si:false,
                no:false
            }
        },

        neuro_examen_fisico:{
            frecuencia_c:{
                label:'Frecuencia cardiaca (FC)',
                value: ''
            },
            presion_a:{
                label:'Presión arteríal (PA)',
                value: ''
            },
            nivel_conciencia:{
                label:'Nivel de conciencia',
                options: [
                    {option: 'Colaboración', value: false},
                    {option: 'Indiferencia', value: false},
                    {option: 'Agresividad', value: false},
                    {option: 'Depresión', value: false},
                    {option: 'Negativismo', value: false},
                    {option: 'Liberación Emocional', value: false},
                    {option: 'Euforia', value: false},
                    {option: 'Inestabilidad', value: false},
                ]
            }

        },

        inspeccion:{
            edema_tedijo_blando:{
                label:'Edema en tejidos blandos (Zonas)',
                value:''
            },
            estado_piel:{
                label:'Estado de piel',
                value:''
            },
            estado_unia:{
                label:'Estado de las Uñas',
                value:''
            }
        },

        sensibilidad_superficial:{
            tactil:{
                label:'Sensibilidad tactil',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            d_super:{
                label:'Dolor Superficial',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            temperatura:{
                label:'Temperatura',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
        },

        sensibilidad_profunda:{
            batiestesia:{
                label:'Batiestesia',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            vibracion:{
                label:'Vibración',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            dolor_profundo:{
                label:'Dolor Profundo',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
        },

        sensibilidad_cortical:{
            somatognopsia:{
                label:'Somatognopsia',
                value: ''
            },
            esterognosia:{
                label:'Esterognosia',
                value: ''
            },
            discriminacion_puntos:{
                label:'Discriminación de dos puntos',
                value: ''
            },
            grafestesia:{
                label:'Grafestesia',
                value: ''
            },
            barognosia:{
                label:'Barognosia',
                value: ''
            }  
        },

        tono_muscular:{
            hipertonia:{
                label:'Hipertonía',
                value: ''
            },
            hipotonia:{
                label:'Hipotonía',
                value: ''
            },
            patron:{
                label:'Patrón Flexor o Extensor (Si lo tiene)',
                value: ''
            },
            comentarios:{
                label:'Comentarios del examinador',
                value: ''
            }
        },

        reflejos:{
            value1:{
                name:'',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            value2:{
                name:'',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            value3:{
                name:'',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            value4:{
                name:'',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            value5:{
                name:'',
                normoestesia: '',
                hiperestesia: '',
                hipoetesia: '',
                disestesia: '',
            },
            comentarios:{
                label: 'Comentarios del examinador',
                value: '',
            }
        },

        rangos_articulares:{
        },

        sensacion_final:{
        },

        fuerza_muscular:{
        },

        pruebas_especiales:{
        },

        concluciones_g:{
            ac_fisioterapeutico:'',
            conclucion_tratamiento:''
        },

        patron_capsular:{
                label: 'Patrón Capsular (Si lo presenta), Segmento(s)',
                value: '',
        },



 }},
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        })
    },
    watch: {
        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {

                const self = this;

                // Cargo la info de historia geriatrica
                Object.keys(self.historial_geriatrico).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.historial_geriatrico) {
                    if (self.GetMedicoFichaActive.metadata.historial_geriatrico[value]) {
                        self.historial_geriatrico[value] = self.GetMedicoFichaActive.metadata.historial_geriatrico[value];
                    }
                }
                });

                // Cargo la info de formulario mental
                Object.keys(self.formulario_mental).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.formulario_mental) {
                    Object.keys(self.formulario_mental[value]).map(function (value2) {

                        self.formulario_mental[value][value2].check = self.GetMedicoFichaActive.metadata.formulario_mental[value+'_'+value2+'_check']
                        self.formulario_mental[value][value2].detailValue = self.GetMedicoFichaActive.metadata.formulario_mental[value+'_'+value2+'_detailValue']
                        self.formulario_mental[value][value2].check ? (self.fm_TotalScore =  self.fm_TotalScore+self.formulario_mental[value][value2].score) : ''
                    });
                }
                });

                if(self.GetMedicoFichaActive.metadata.indice_barthel){
                Object.keys(self.indice_barthel).map(function (value) {
                        self.indice_barthel[value].score = self.GetMedicoFichaActive.metadata.indice_barthel[value+'_score'] ? parseInt(self.GetMedicoFichaActive.metadata.indice_barthel[value+'_score']) : 0;
                        self.ib_TotalScore += self.indice_barthel[value].score ?  parseInt(self.indice_barthel[value].score) : 0;   
                    });
                }

                if(self.GetMedicoFichaActive.metadata.lawton_brody){
                Object.keys(self.lawton_brody).map(function (value) {
                if(value !== 'comentarios'){
                    self.lawton_brody[value].score = self.GetMedicoFichaActive.metadata.lawton_brody[value+'_score'] ? parseInt(self.GetMedicoFichaActive.metadata.lawton_brody[value+'_score']) : 0;
                    self.lb_TotalScore += self.lawton_brody[value].score ?  parseInt(self.lawton_brody[value].score) : 0;   
                }else{
                    self.lawton_brody[value].content = self.GetMedicoFichaActive.metadata.lawton_brody[value+'_content'] ? self.GetMedicoFichaActive.metadata.lawton_brody[value+'_content'] : '';
                }
                });
                }   
                
                if(self.GetMedicoFichaActive.metadata.evaluacion_dolor){
                Object.keys(self.evaluacion_dolor).map(function (value) {
                if(value === 'primary_question'){
                    Object.keys(self.evaluacion_dolor[value]).map(function (value2) {
                        self.evaluacion_dolor[value][value2].value = self.GetMedicoFichaActive.metadata.evaluacion_dolor[value+'_'+value2+'_value'];
                    });
                }else{
                        self.evaluacion_dolor[value].value= self.GetMedicoFichaActive.metadata.evaluacion_dolor[value+'_value']
                }  
                });
                }    
                if(self.GetMedicoFichaActive.metadata.evaluacion_postural){
                Object.keys(self.evaluacion_postural).map(function (value) {
                        self.evaluacion_postural[value].value= self.GetMedicoFichaActive.metadata.evaluacion_postural[value+'_value']
                });
                }
                if(self.GetMedicoFichaActive.metadata.depre_geriatrica){
                Object.keys(self.depre_geriatrica).map(function (value) {
                self.depre_geriatrica[value].si= self.GetMedicoFichaActive.metadata.depre_geriatrica[value+'_si']
                self.depre_geriatrica[value].no= self.GetMedicoFichaActive.metadata.depre_geriatrica[value+'_no']
                if(self.depre_geriatrica[value].si){
                    self.dp_TotalScore+=1;
                }
                });
                }


                if (self.GetMedicoFichaActive.metadata.neuro_examen_fisico) {
                Object.keys(self.neuro_examen_fisico).map(function (value) {
                    if (value != 'nivel_conciencia') {
                        if (self.GetMedicoFichaActive.metadata.neuro_examen_fisico[value+'_value']) {
                            self.neuro_examen_fisico[value].value = self.GetMedicoFichaActive.metadata.neuro_examen_fisico[value+'_value'];
                        }
                    }else{
                        self.neuro_examen_fisico[value].options.map(function (item,index) {
                            item.value= self.GetMedicoFichaActive.metadata.neuro_examen_fisico[value+'_option_'+index] ? true : false;
                        })
                    }
                });
                }

                if (self.GetMedicoFichaActive.metadata.inspeccion) {
                Object.keys(self.inspeccion).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.inspeccion[value]) {
                        self.inspeccion[value].value = self.GetMedicoFichaActive.metadata.inspeccion[value];
                    }
                });
                }
                if (self.GetMedicoFichaActive.metadata.sensibilidad_superficial) {
                Object.keys(self.sensibilidad_superficial).map(function (value) {
                Object.keys(self.sensibilidad_superficial[value]).map(function (value2) {
                        if(value2 != 'label'){
                        if(self.GetMedicoFichaActive.metadata.sensibilidad_superficial[value+'_'+value2])
                        self.sensibilidad_superficial[value][value2] = self.GetMedicoFichaActive.metadata.sensibilidad_superficial[value+'_'+value2];
                    }
                })
                })
                }

                if (self.GetMedicoFichaActive.metadata.sensibilidad_profunda) {
                Object.keys(self.sensibilidad_profunda).map(function (value) {
                Object.keys(self.sensibilidad_profunda[value]).map(function (value2) {
                        if(value2 != 'label'){
                        if(self.GetMedicoFichaActive.metadata.sensibilidad_profunda[value+'_'+value2])
                        self.sensibilidad_profunda[value][value2] = self.GetMedicoFichaActive.metadata.sensibilidad_profunda[value+'_'+value2];
                    }
                })
                })
                }
                if (self.GetMedicoFichaActive.metadata.sensibilidad_cortical) {
                Object.keys(self.sensibilidad_cortical).map(function (value) {
                Object.keys(self.sensibilidad_cortical[value]).map(function (value2) {
                        if(value2 != 'label'){
                        if(self.GetMedicoFichaActive.metadata.sensibilidad_cortical[value+'_'+value2])
                        self.sensibilidad_cortical[value][value2] = self.GetMedicoFichaActive.metadata.sensibilidad_cortical[value+'_'+value2];
                    }
                })
                })
                }

                if (self.GetMedicoFichaActive.metadata.reflejos) {
                Object.keys(self.reflejos).map(function (value) {
                    Object.keys(self.reflejos[value]).map(function (value2) {
                        if(self.GetMedicoFichaActive.metadata.reflejos[value+'_'+value2])
                        self.reflejos[value][value2] = self.GetMedicoFichaActive.metadata.reflejos[value+'_'+value2];
                    });
                    });
                }

                if (self.GetMedicoFichaActive.metadata.tono_muscular) {
                Object.keys(self.tono_muscular).map(function (value) {
                Object.keys(self.tono_muscular[value]).map(function (value2) {
                        if(value2 != 'label'){
                        if(self.GetMedicoFichaActive.metadata.tono_muscular[value+'_'+value2])
                        self.tono_muscular[value][value2] = self.GetMedicoFichaActive.metadata.tono_muscular[value+'_'+value2];
                    }
                })
                })
                }

                if (self.GetMedicoFichaActive.metadata.patron_capsular) {
                self.patron_capsular.value = self.GetMedicoFichaActive.metadata.patron_capsular.value;
                }


                if (self.GetMedicoFichaActive.metadata.concluciones_g) {
                Object.keys(self.concluciones_g).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.concluciones_g[value]) {
                        self.concluciones_g[value] = self.GetMedicoFichaActive.metadata.concluciones_g[value];
                    }
                });
                }

            }
        }
    },
 components: {
        Box,
    },
  mounted(){
    // Se refresca la ficha y se mandan los slugs que usa el tab de la consulta
    this.emitter.emit("medico-ficha-load",['historial_geriatrico','formulario_mental','indice_barthel','lawton_brody', 'evaluacion_dolor', 'evaluacion_postural', 'depre_geriatrica', 'neuro_examen_fisico', 'inspeccion', 'sensibilidad_superficial', 'sensibilidad_profunda', 'sensibilidad_cortical', 'reflejos', 'tono_muscular', 'patron_capsular', 'pruebas_especiales', 'fuerza_muscular', 'sensacion_final', 'rangos_articulares', 'patron_capsular', 'concluciones_g'])
  },  
  methods:{
    dayjs,
    Numbro,
    saveConcluciones(){
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.concluciones_g).map(function (value) {
            if (self.concluciones_g[value] !== '') arrToSave[value] = self.concluciones_g[value];
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'concluciones_g',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['concluciones_g']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    savePruebaEspecial(){
        const self = this;

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                metadataId: self.pruebas_especiales.id,
                slug: 'pruebas_especiales',
                detail: self.pruebas_especiales.detail,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-refresh",['pruebas_especiales']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

        this.cancelAddOrEditPruebaEspecial();
    },
    cancelAddOrEditPruebaEspecial(){
        this.showAddPruebaEspecial = false;
        this.showEditionPruebaEspecial = false;
        this.pruebas_especiales={};
    },
    newPruebaEspecial(){
        this.pruebas_especiales={
            id:0,
            detail:{
                miembro_segmento:'',
                prueba:'',
                hallazgo:''
            }
        }
        this.showAddPruebaEspecial = true;
    },
    saveFuerzaM(){
        const self = this;

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                metadataId: self.fuerza_muscular.id,
                slug: 'fuerza_muscular',
                detail: self.fuerza_muscular.detail,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-refresh",['fuerza_muscular']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

        this.cancelAddOrEditFuerzaM();
    },
    cancelAddOrEditFuerzaM(){
        this.showAddFuerzaM = false;
        this.showEditionFuerzaM = false;
        this.fuerza_muscular={};
    },
    newFuerzaM(){
        this.fuerza_muscular={
            id:0,
            detail:{
                miembro_segmento:'',
                musculo:'',
                fuerza:''
            }
        }
        this.showAddFuerzaM = true;
    },
    saveSensacionFinal(){
        const self = this;

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                metadataId: self.sensacion_final.id,
                slug: 'sensacion_final',
                detail: self.sensacion_final.detail,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-refresh",['sensacion_final']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

        this.cancelAddOrEditSensaFinal();
    },
    cancelAddOrEditSensaFinal(){
        this.showAddSensacion = false;
        this.showEditionSensacion = false;
        this.sensacion_final={};
    },
    newSensacionFinal(){
        this.sensacion_final={
            id:0,
            detail:{
                miembro_segmento:'',
                tipo_sensacion:'',
                dolor:''
            }
        }
        this.showAddSensacion = true;
    },
    saveRangoArt(){
        const self = this;

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                metadataId: self.rangos_articulares.id,
                slug: 'rangos_articulares',
                detail: self.rangos_articulares.detail,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-refresh",['rangos_articulares']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

        this.cancelAddOrEditRangoArt();
    },
    cancelAddOrEditRangoArt(){
        this.showAddRango = false;
        this.showEditionRango = false;
        this.rangos_articulares={};
    },
    newRangoArticular(){
        this.rangos_articulares={
            id:0,
            detail:{
                miembro_segmento:'',
                rango:''  
            }
        }
        this.showAddRango = true;
    },
    savePatronCapsular(){
        const self = this;

        // transformo los keys a un array normal
        const arrToSave = {};
       
            if (self.patron_capsular.value !== '') arrToSave['value'] = self.patron_capsular.value;
     

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'patron_capsular',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['patron_capsular']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveTonoMuscular(){
        const self = this;

        const arrToSave = {};
       
            Object.keys(self.tono_muscular).map(function (value) {
            Object.keys(self.tono_muscular[value]).map(function (value2) {
            if(value2 != 'label')
            arrToSave[value+'_'+value2] = self.tono_muscular[value][value2];
            });
            });
        

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'tono_muscular',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['tono_muscular']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveReflejo(){
        const self = this;

        const arrToSave = {};

        
            Object.keys(self.reflejos).map(function (value) {
            Object.keys(self.reflejos[value]).map(function (value2) {
            arrToSave[value+'_'+value2] = self.reflejos[value][value2];
            });
            });
       
        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'reflejos',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['reflejos']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveSensibilidad(slug){
        const self = this;

        const arrToSave = {};
        if (slug=== 'sensibilidad_superficial') {
            Object.keys(self.sensibilidad_superficial).map(function (value) {
            Object.keys(self.sensibilidad_superficial[value]).map(function (value2) {
            if(value2 != 'label')
            arrToSave[value+'_'+value2] = self.sensibilidad_superficial[value][value2];
            });
            });
        }
        else if (slug=== 'sensibilidad_profunda') {
            Object.keys(self.sensibilidad_profunda).map(function (value) {
            Object.keys(self.sensibilidad_profunda[value]).map(function (value2) {
            if(value2 != 'label')
            arrToSave[value+'_'+value2] = self.sensibilidad_profunda[value][value2];
            });
            });
        }
        else if (slug=== 'sensibilidad_cortical') {
            Object.keys(self.sensibilidad_cortical).map(function (value) {
            Object.keys(self.sensibilidad_cortical[value]).map(function (value2) {
            if(value2 != 'label')
            arrToSave[value+'_'+value2] = self.sensibilidad_cortical[value][value2];
            });
            });
        }
       
        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: slug,
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",[slug]);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveInspeccion(){
        const self = this;

        const arrToSave = {};
        Object.keys(self.inspeccion).map(function (value) {
           arrToSave[value] = self.inspeccion[value].value;
        });
        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'inspeccion',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['inspeccion']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveNeuroExamenFisico(){
        const self = this;

        const arrToSave = {};
        Object.keys(self.neuro_examen_fisico).map(function (value) {

            if (value != 'nivel_conciencia'){
            arrToSave[value+'_value'] = self.neuro_examen_fisico[value].value;
            }else{
                self.neuro_examen_fisico[value].options.map(function (item,index) {
                    arrToSave[value+'_option_'+index] = item.value;
                })
            }

        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'neuro_examen_fisico',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['neuro_examen_fisico']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    onChangeDetail(item){
            item.detail=!item.detail;
    },
    saveHistorialGeriatric(){
      const self = this;

      // transformo los keys a un array normal
      const arrToSave = {};
      Object.keys(self.historial_geriatrico).map(function (value) {
          if (self.historial_geriatrico[value] !== '') arrToSave[value] = self.historial_geriatrico[value];
      });

      // Guardo y luego emito el evento
      API.send('POST', 'orders/metadata/save/', {
              orderId: self.GetMedicoFichaActive.orden.id,
              slug: 'historial_geriatrico',
              detail: arrToSave,
              uniqueMeta: 1,
          }, function (response) {
              API.showSuccessAlert(response.msg);
              self.emitter.emit("medico-ficha-notify",['historial_geriatrico']);
          },
          function (response) {
              API.showErrorAlert(response.msg);
          });
    },
    saveFormularioMental(){
        const self = this;
        self.fm_TotalScore= 0;
        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.formulario_mental).map(function (value) {
            let groptest = self.formulario_mental[value]; 
            Object.keys(groptest).map(function (value2) {
                let itemtest = groptest[value2];
                arrToSave[value+'_'+value2+'_check'] = itemtest.check;
                arrToSave[value+'_'+value2+'_detailValue'] = itemtest.detailValue;
                self.formulario_mental[value][value2].check ? (self.fm_TotalScore =  self.fm_TotalScore+self.formulario_mental[value][value2].score) : ''
            });
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'formulario_mental',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['formulario_mental']);

            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveBarthel(){
        const self = this;
        self.ib_TotalScore= 0;
        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.indice_barthel).map(function (value) {
                arrToSave[value+'_score'] = self.indice_barthel[value].score ? parseInt(self.indice_barthel[value].score) : 0;
                self.ib_TotalScore +=  self.indice_barthel[value].score ? parseInt(self.indice_barthel[value].score) : 0;
        });
        console.log(arrToSave);
        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'indice_barthel',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['indice_barthel']);

            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

    },
    saveLawtonbrody(){
        const self = this;
        self.lb_TotalScore= 0;
        // transformo los keys a un array normal
        const arrToSave = {};
        Object.keys(self.lawton_brody).map(function (value) {
            if(value!=='comentarios'){
                arrToSave[value+'_score'] = self.lawton_brody[value].score ? parseInt(self.lawton_brody[value].score) : 0;
                self.lb_TotalScore +=  self.lawton_brody[value].score ? parseInt(self.lawton_brody[value].score) : 0;
            }else{
                arrToSave[value+'_content'] = self.lawton_brody[value].content ?  self.lawton_brody[value].content : ''
            }
        });
        
        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'lawton_brody',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['lawton_brody']);

            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
  
    },
    saveEvaluacionDolor(){
        const self = this;

        const arrToSave = {};
        Object.keys(self.evaluacion_dolor).map(function (value) {
            if(value === 'primary_question'){
                Object.keys(self.evaluacion_dolor[value]).map(function (value2) {
                arrToSave[value+'_'+value2+'_value'] = self.evaluacion_dolor[value][value2].value ?  self.evaluacion_dolor[value][value2].value : ''
            });
            }else{
                arrToSave[value+'_value'] = self.evaluacion_dolor[value].value;
            }  
        });
        
        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'evaluacion_dolor',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['evaluacion_dolor']);

            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveEvaluacionPostural(){
        const self = this;

        const arrToSave = {};
        Object.keys(self.evaluacion_postural).map(function (value) {
                arrToSave[value+'_value'] = self.evaluacion_postural[value].value;
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'evaluacion_postural',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['evaluacion_postural']);

            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    saveDepreGeriatrica(){
        const self = this;
        self.dp_TotalScore=0;
        const arrToSave = {};
        Object.keys(self.depre_geriatrica).map(function (value) {
                arrToSave[value+'_si'] = self.depre_geriatrica[value].si;
                arrToSave[value+'_no'] = self.depre_geriatrica[value].no;
                if(self.depre_geriatrica[value].si){
                  self.dp_TotalScore+=1;
                }
        });

        // Guardo y luego emito el evento
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'depre_geriatrica',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['depre_geriatrica']);

            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
    },
    handleCheckStyle(item){
       if (item.check) {
            return "check-style";
        }
    },
    handleCheckStyle2(item){
            if(item.no){
                return "cancel-style";
            }else if (item.si) {
                return "check-style";
            }
        },
  }
 }
</script>
 
 
<template>
  <box  title="Análisis primario" icon="fa-solid fa-clipboard-user" :contraction='true'>
      <div class="row mx-2">
        <div class="col-12">
            <div class="form-group mb-2">
                <label>Historia nutricional</label>
                <textarea v-model="historial_geriatrico.nutricional" class="form-control" placeholder="Escribe aquí" @change="saveHistorialGeriatric"></textarea>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group mb-2">
                <label>Historia psiquiatrica</label>
                <textarea v-model="historial_geriatrico.psiquiatrica" class="form-control" placeholder="Escribe aquí" @change="saveHistorialGeriatric"></textarea>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group mb-2">
                <label>Anamnesis medica</label>
                <textarea v-model="historial_geriatrico.anamnesis" class="form-control" placeholder="Escribe aquí" @change="saveHistorialGeriatric"></textarea>
            </div>
        </div>
      </div>
  </box>
  <box  title="Formulario mental" icon="fa-solid fa-brain" :contraction='true'> 
      <div class="d-flex flex-column justify-content-center w-100">
        <div class="form-group w-100">
                <label class=" d-flex flex-column " ><h6 class="text-primary">Puntuación: {{ fm_TotalScore }} </h6> <small :class="fm_TotalScore < 26 ? 'text-danger' : 'text-muted'">*La puntuación inferior a 26 puntos puede indicar la necesidad de otras pruebas de evaluación. El diagnostico cognoscitivo determinado mediante esta prueba varía según el nivel educativo del paciente.</small></label>
                        <table class="table">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col" class="font-weight-bold">
                                Actividad
                                </th>
                              
                                <th scope="col" class="font-weight-bold">
                                  <div class="d-flex justify-content-center">
                                    Listo
                                  </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-hover">
                            <tr>
                              <td colspan="2"><span class="text-primary">*Preguntas de orientacion</span></td>
                            </tr>
                            <tr v-for="(item,key) in formulario_mental.orientacion" class="inputGroup bgtr" :class="handleCheckStyle(item)" :key="key">
                                <td>
                                  <div class="mb-2">
                                    {{ item.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="item.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${item.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${item.ask}`" class="check" name="check" type="checkbox" v-model="item.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="item.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*¿Donde estamos?</span></td>
                            </tr>
                            <tr v-for="(item,key) in formulario_mental.ubicacion" class="inputGroup bgtr" :class="handleCheckStyle(item)" :key="key">
                                <td>
                                  <div class="mb-2">
                                    {{ item.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="item.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option cursor-pointer">
                                        <label :for="`check${item.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${item.ask}`" class="check" name="check" type="checkbox" v-model="item.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="item.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Registro</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.registro.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.registro.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.registro.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option cursor-pointer">
                                        <label :for="`check${formulario_mental.registro.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.registro.test.ask}`" class="check cursor-pointer" name="check" type="checkbox" v-model="formulario_mental.registro.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.registro.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Atención y Calculo</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.calculo.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.calculo.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.calculo.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option cursor-pointer">
                                        <label :for="`check${formulario_mental.calculo.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.calculo.test.ask}`" class="check cursor-pointer" name="check" type="checkbox" v-model="formulario_mental.calculo.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.calculo.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Memoria</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.memoria.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.memoria.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.memoria.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option cursor-pointer">
                                        <label :for="`check${formulario_mental.memoria.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.memoria.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.memoria.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.memoria.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Nombrar</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.nombrar.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.nombrar.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.nombrar.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option cursor-pointer">
                                        <label :for="`check${formulario_mental.nombrar.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.nombrar.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.nombrar.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.nombrar.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Repetición</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.repeticion.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.repeticion.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.repeticion.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option cursor-pointer">
                                        <label :for="`check${formulario_mental.repeticion.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.repeticion.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.repeticion.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.repeticion.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Lectura</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.lectura.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.lectura.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.lectura.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${formulario_mental.lectura.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.lectura.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.lectura.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.lectura.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Comprensión</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.comprension.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.comprension.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.comprension.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${formulario_mental.comprension.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.comprension.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.comprension.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.comprension.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Escritura</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.escritura.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.escritura.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.escritura.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${formulario_mental.escritura.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.escritura.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.escritura.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.escritura.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                              <td colspan="2"><span class="text-primary">*Diseño</span></td>
                            </tr>
                            <tr class="inputGroup bgtr" :class="handleCheckStyle(formulario_mental.disenio.test)">
                                <td>
                                  <div class="mb-2">
                                    {{ formulario_mental.disenio.test.ask }}
                                  </div>
                                    <div class="form-group" title="Test de mental">
                                      <input type="text" class="form-control w-50" v-model="formulario_mental.disenio.test.detailValue" @change="saveFormularioMental" placeholder="Ingrese la respuesta..."/>
                                    </div>
                                </td>
                               
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${formulario_mental.disenio.test.ask}`" title="Marcar proceso como listo" class="cursor-pointer">

                                            <input :id="`check${formulario_mental.disenio.test.ask}`" class="check" name="check" type="checkbox" v-model="formulario_mental.disenio.test.check" @change="saveFormularioMental()"/>
                                            <svg  v-if="formulario_mental.disenio.test.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
        </div>
      </div>

  </box>
  <box  title="Índice de Barthel (AVD)." icon="fa-solid fa-person-cane" :contraction='true'>
    <section class="row w-100">
        <div class=" d-flex flex-column col-12" ><h6 class="text-primary">Puntuación: {{ ib_TotalScore }} </h6> <small  
        :class="
        (ib_TotalScore<20) ? 'text-danger'
        :(ib_TotalScore>=20 && ib_TotalScore<35) ? 'text-warning'
        :(ib_TotalScore>=35 && ib_TotalScore<55) ? 'text-muted'
        :(ib_TotalScore>=55 && ib_TotalScore<85) ? 'text-success'
        :(ib_TotalScore===85) ? 'text-primary' : ''
        ">Grado de dependecia: 
        <span>
            {{(ib_TotalScore<20) ? 'Total' : ''}}
            {{(ib_TotalScore>=20 && ib_TotalScore< 35) ? 'Grave' : ''}}
            {{(ib_TotalScore>=35 && ib_TotalScore< 55) ? 'Moderado' : ''}}
            {{(ib_TotalScore>=55 && ib_TotalScore< 85) ? 'Leve' : ''}}
            {{(ib_TotalScore===85) ? 'Independiente' : ''}}
        </span>
        </small></div>
        <div v-for="item in indice_barthel" :key="item.label" class="form-group col-12 col-md-4 row-gap-5 ">
         <div class="p-2">
            <label for="formControlRange" class="d-flex justify-content-between text-primary"><h6>{{ item.label }}</h6> <p>{{ item.score }}</p></label>
            <input v-if="item.type==='sm'" v-model="item.score" type="range" min="0" max="5" step="5" class="form-control-range cursor-pointer" id="formControlRange" aria-describedby="label" @change="saveBarthel">
            <input v-if="item.type==='md'" v-model="item.score" type="range" min="0" max="10" step="5" class="form-control-range cursor-pointer" id="formControlRange" aria-describedby="label" @change="saveBarthel">
            <input v-if="item.type==='lg'" v-model="item.score" type="range" min="0" max="15" step="5" class="form-control-range cursor-pointer" id="formControlRange" aria-describedby="label" @change="saveBarthel">
         </div>
         <small v-if="item.type==='sm'" id="label" class="form-text" :class="
          item.score==0 && 'text-danger'||
          item.score==5 && 'text-primary'
          ">
            {{(item.score==0) ? item.description1 :''}}
            {{(item.score==5) ? item.description2 : ''}}
          </small>
         <small v-if="item.type==='md'" id="label" class="form-text" :class="
          item.score==0 && 'text-danger' ||
          item.score==5 && 'text-success' ||
          item.score==10 && 'text-primary'
          ">
          {{(item.score==0) ? item.description1: ''}}
          {{(item.score==5) ? item.description2: ''}}
          {{(item.score==10) ? item.description3: ''}}
          </small>
         <small v-if="item.type==='lg'" id="label" class="form-text" :class="
          item.score==0 && 'text-danger' ||
          item.score==5 && 'text-warning' ||
          item.score==10 && 'text-success'||
          item.score==15 && 'text-primary' 
          ">
          {{(item.score==0) ? item.description1: ''}}
          {{(item.score==5) ? item.description2: ''}}
          {{(item.score==10) ? item.description3: ''}}
          {{(item.score==15) ? item.description4: ''}}
          </small>
        </div>
      </section>
  </box>
  <box  title="Índice de Lawton y Brody" icon="fa-solid fa-person-cane" :contraction='true'>
    <div class="row">
        <div class=" d-flex flex-column col-12" ><h6 class="text-primary">Puntuación: {{ lb_TotalScore }} </h6> <small  
        :class="
        (lb_TotalScore===0) ? 'text-muted'
        :(lb_TotalScore<=8) ? 'text-primary'
        :(lb_TotalScore> 8 && lb_TotalScore <= 20) ? 'text-warning'
        :(lb_TotalScore > 20) ? 'text-danger' : ''
        ">Grado de dependecia: 
        <span>
            {{
            (lb_TotalScore===0) ? 'Seleccione opciones validas para el test'
            :(lb_TotalScore<=8) ? 'Independiente'
            :(lb_TotalScore> 8 && lb_TotalScore <= 20) ? 'Necesita cierta ayuda'
            :(lb_TotalScore > 20) ? 'Necesita mucha ayuda' : ''
            }}
        </span>
        </small>
    </div>
        <div class="col-12 col-sm-6 col-md-3" v-for="(item,key) in lawton_brody" :key="key" :class="item.type=== 'textarea' ? 'col-md-12': ''">
            <div class="form-group" v-if="item.type!=='textarea'">
                <label>{{item.name}}</label>
                <select class="form-control" v-model="item.score" @change="saveLawtonbrody">
                   
                    <option v-for="(e,key) in item.options" :value="e.value" :key="key">{{ e.option }}</option>     
                </select>
            </div>
            <div class="form-group mb-2" v-else>
                <label>Comentarios del examinador</label>
                <textarea v-model="item.content" class="form-control" placeholder="Escriba comentarios aquí" @change="saveLawtonbrody"></textarea>
            </div>
        </div>
    </div>
  </box>
  <box  title="Evaluación de dolor" icon="fa-solid fa-person-cane" :contraction='true'>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3" v-for="(item,key) in evaluacion_dolor.primary_question" :key="key">
            <div class="form-group" v-if="item.type==='select'">
                <label>{{item.label}}</label>
                <select class="form-control" v-model="item.value" @change="saveEvaluacionDolor">
                    <option v-for="(e,key) in item.options" :value="e.value" :key="key">{{ e.option }}</option>     
                </select>
            </div>
            <div class="form-group mb-2" v-if="item.type==='textarea'">
                <label>{{ item.label }}</label>
                <textarea v-model="item.value" class="form-control" placeholder="Escriba comentarios aquí" @change="saveEvaluacionDolor"></textarea>
            </div>
            <div class="form-group mb-2" v-if="item.type==='text'">
                <label>{{ item.label }}</label>
                <input v-model="item.value" class="form-control" placeholder="Escriba aquí" @change="saveEvaluacionDolor" type="text">
            </div>
        </div>
    </div>
    <div class=" d-flex flex-column col-12 mt-4" >
        <h6 class="text-primary">{{evaluacion_dolor.escala_visual_simple.label}}</h6>
        <small v-if="!evaluacion_dolor.escala_visual_simple.value"  class="text-danger">Seleccione una opción de la escala visual simple*</small>
        <div class="col-12 row m-4 mx-md-10 ">
            <div v-for="item in evaluacion_dolor.escala_visual_simple.options" class="col-4 col-sm-2" title="seleccionar">
                <div class="minicard d-flex justify-content-center align-items-center cursor-pointer text-center" @click="evaluacion_dolor.escala_visual_simple.value = item.option ; saveEvaluacionDolor()" :class="(evaluacion_dolor.escala_visual_simple.value===item.option) ? 'bg-primary text-white': ''">
                    {{item.option}}
                </div>
            </div>
        </div>
    </div>
    <div class=" d-flex flex-column col-12 mt-4" >
        <h6 class="text-primary">{{evaluacion_dolor.escala_visual_numerica.label}}</h6>
        <small v-if="!evaluacion_dolor.escala_visual_numerica.value" class="text-danger">Seleccione una opción de la escala visual simple numérica*</small>
        <div class="col-12 row m-4 mx-md-10 ">
            <div v-for="item in evaluacion_dolor.escala_visual_numerica.options" class="col-1" title="seleccionar">
                <div class="minicard d-flex justify-content-center align-items-center cursor-pointer text-center p-2" @click="evaluacion_dolor.escala_visual_numerica.value = item.option ; saveEvaluacionDolor()" :class="(evaluacion_dolor.escala_visual_numerica.value===item.option)? 'bg-primary text-white': ''">
                    {{item.option}}
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between aling-items-center px-2">
            <small class="text-success">Sin dolor*</small>
            <small class="text-danger mr-sm-8">Peor dolor imaginable*</small>
        </div>
        </div>
    </div>
    <div class=" d-flex flex-column col-12 mt-4" >
        <h6 class="text-primary">{{evaluacion_dolor.escala_visual_grafica.label}}</h6>
        <small v-if="!evaluacion_dolor.escala_visual_grafica.value" class="text-danger">Seleccione una opción de la escala visual grafica*</small>
        <div class="col-12 row m-4 mx-md-10 ">
            <div v-for="item in evaluacion_dolor.escala_visual_grafica.options" class="col-2" title="seleccionar">
                <div class="minicard d-flex justify-content-center align-items-center cursor-pointer text-center p-2" @click="evaluacion_dolor.escala_visual_grafica.value = item.option; saveEvaluacionDolor()" :class="(evaluacion_dolor.escala_visual_grafica.value===item.option)? 'bg-primary text-white': ''">
                   <i :class="item.icon" class="text-2xl"></i>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between aling-items-center px-2">
            <small class="text-succes">Sin dolor*</small>
            <small class="text-danger">Peor dolor imaginable*</small>
        </div>
        </div>
    </div>
    <div class=" d-flex flex-column col-12 mt-4" >
        <h6 class="text-primary">Comentarios del examinador</h6>
        <textarea v-model="evaluacion_dolor.comentarios.value" class="form-control w-100" placeholder="Escribe aquí" @change="saveEvaluacionDolor"></textarea>
    </div>
  </box>
  <box  title="Evaluación postural" icon="fa-solid fa-person-cane" :contraction='true'>
    <div class="row mx-2">
        <div v-for="item in evaluacion_postural" class="col-12">
            <div class="form-group mb-2 w-100">
                <label>{{item.label}}</label>
                <textarea v-model="item.value" class="form-control w-100" placeholder="Escribe aquí" @change="saveEvaluacionPostural"></textarea>
            </div>
        </div>
    </div>
  </box>
  <box  title="Escala de depresión geriatrica" icon="fa-solid fa-person-cane" :contraction='true'>
    <div class="row">
        <div class="d-flex align-items-center justify-content-center w-100 p-4">
            <div class="form-group w-100">
                <label class=" d-flex flex-column " ><h6 class="text-primary">Puntuación: {{ dp_TotalScore }}</h6></label>
                        <table class="table">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col" class="font-weight-bold">
                                    Preguntas
                                </th>
                                <th scope="col" class="font-weight-bold">
                                    <div class="center-option">
                                        Si
                                    </div>
                                </th>
                                <th scope="col" class="font-weight-bold">
                                    <div class="center-option">
                                        No
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-hover">
                            <tr v-for="item in depre_geriatrica" class="inputGroup bgtr" :class="handleCheckStyle2(item)">
                                <td>
                                    {{ item.label }}
                                </td>
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${item.label}`" title="Si" class="cursor-pointer">

                                            <input :id="`check${item.label}`" class="check cursor-pointer" name="check" type="checkbox" v-model="item.si" @change="item.no=false; saveDepreGeriatrica()"/>
                                            <svg  v-if="item.si" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="center-option">
                                        <label :for="`cancel${item.label}`" title="No" class="cursor-pointer">

                                            <input :id="`cancel${item.label}`" class="cancel cursor-pointer" name="cancel" type="checkbox" v-model="item.no" @change="item.si=false; saveDepreGeriatrica()"/>
                                            <svg v-if="item.no" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#F05228;" d="M213.333,0C95.514,0,0,95.514,0,213.333s95.514,213.333,213.333,213.333  s213.333-95.514,213.333-213.333S331.153,0,213.333,0z M330.995,276.689l-54.302,54.306l-63.36-63.356l-63.36,63.36l-54.302-54.31  l63.356-63.356l-63.356-63.36l54.302-54.302l63.36,63.356l63.36-63.356l54.302,54.302l-63.356,63.36L330.995,276.689z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>


                                        </label>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
    </div>
  </box>
  <box  title="Evaluación neuromusculoesqueletica" icon="fa-solid fa-person-cane" :contraction='true'>
    <div class="row">
        <h6 class=" col-12 text-primary mt-2 py-2">Examen Fisico</h6>
        <template  v-for="item in neuro_examen_fisico" >
            <div v-if="item.label!== 'Nivel de conciencia'" class="form-group col-12 col-sm-6">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" @change="saveNeuroExamenFisico"/>
            </div>
            <template v-else>
                <label class="py-2 col-12">
                    {{item.label}}
                </label>
                <div v-for="option in item.options" class="col-12 col-sm-4 mb-2">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="option.value" :checked="option.value" @change="saveNeuroExamenFisico"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ option.option }}
                        </span>
                    </label>
                </div>
            </template>
           
        </template>
    </div>
    <div class="row">
        <h6 class=" col-12 text-primary mt-2 py-2">Inspección</h6>
        <template  v-for="item in inspeccion" >
            <div class="form-group col-12 col-sm-4">
                <label>{{ item.label }}</label>
                <input type="text" class="form-control" v-model="item.value" @change="saveInspeccion"/>
            </div>
        </template>
    </div>
    <div class="d-flex justify-content-center align-content-center flex-column">
        <h6 class=" w-100 text-primary mt-2 py-2">Palpación</h6>
        <div class="w-100 p-4">
        <label>Sensibilidad</label>
        <table class="table w-100">
            <thead class="thead-light">
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Sensibilidad Superficial
                    </div>
                </th>
                <th colspan="4" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Respuesta
                    </div>
                </th>
            </tr>    
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Estimulo
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Normoestesia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Hiperestesia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Hipoetesia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Disestesia
                    </div>
                </th>
            </tr>
            </thead>
            <tbody class="table-hover">
                <tr v-for="item in sensibilidad_superficial">
                <th scope="col" class="font-weight-bold">
                    {{ item.label}}
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.normoestesia" @change="saveSensibilidad('sensibilidad_superficial')"/>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.hiperestesia" @change="saveSensibilidad('sensibilidad_superficial')"/>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.hipoetesia" @change="saveSensibilidad('sensibilidad_superficial')"/>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.disestesia" @change="saveSensibilidad('sensibilidad_superficial')"/>
                    </div>
                </th>
            </tr>
            </tbody>
        </table>
        <table class="table w-100 mt-2">
            <thead class="thead-light">
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Sensibilidad Profunda
                    </div>
                </th>
                <th colspan="4" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Respuesta
                    </div>
                </th>
            </tr>    
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Estimulo
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Normoestesia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Hiperestesia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Hipoetesia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Disestesia
                    </div>
                </th>
            </tr>
            </thead>
            <tbody class="table-hover">
                <tr v-for="item in sensibilidad_profunda">
                <th scope="col" class="font-weight-bold">
                    {{ item.label}}
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.normoestesia" @change="saveSensibilidad('sensibilidad_profunda')"/>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.hiperestesia" @change="saveSensibilidad('sensibilidad_profunda')"/>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.hipoetesia" @change="saveSensibilidad('sensibilidad_profunda')"/>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.disestesia" @change="saveSensibilidad('sensibilidad_profunda')"/>
                    </div>
                </th>
            </tr>
            </tbody>
        </table>
        <table class="table w-100 mt-2">
            <thead class="thead-light">
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Sensibilidad cortical
                    </div>
                </th>
                <th colspan="4" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Hallazgo
                    </div>
                </th>
            </tr>    
            </thead>
            <tbody class="table-hover">
                <tr v-for="item in sensibilidad_cortical">
                    <th scope="col" class="font-weight-bold">
                        {{ item.label}}
                    </th>
                    <th scope="col" class="font-weight-bold">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="item.value" @change="saveSensibilidad('sensibilidad_cortical')"/>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="mt-2 w-100 p-4">
        <label>Tono Muscular</label>
        <div class="row">
            <div v-for="item in tono_muscular" class="col-12">
                <div class="form-group mb-2">
                    <label>{{ item.label }}</label>
                    <textarea v-model="item.value" class="form-control" placeholder="Escribe aquí" @change="saveTonoMuscular"></textarea>
                </div>
            </div>
         </div>
    </div>

    <div class="mt-2 w-100 p-4">
        <label>Reflejos</label>
        <table class="table w-100 mt-2">
            <thead class="thead-light">
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Reflejo
                    </div>
                </th>
                <th colspan="4" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                    Respuesta
                    </div>
                </th>
            </tr>
            <tr>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        <i class="fa-solid fa-arrow-down-long"></i>
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Normoreflexia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Hiperreflexia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Hiporreflexia
                    </div>
                </th>
                <th scope="col" class="font-weight-bold">
                    <div class="d-flex justify-content-center">
                        Arreflexia
                    </div>
                </th>
            </tr>    
            </thead>
            <tbody class="table-hover">
               <template v-for="(item, key) in reflejos">
                <tr v-if="key !== 'comentarios'">
                    <th scope="col" class="font-weight-bold">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="item.name" @change="saveReflejo"/>
                        </div>
                    </th>
                    <th scope="col" class="font-weight-bold">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="item.normoestesia" @change="saveReflejo"/>
                        </div>
                    </th>
                    <th scope="col" class="font-weight-bold">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="item.hiperestesia" @change="saveReflejo"/>
                        </div>
                    </th>
                    <th scope="col" class="font-weight-bold">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="item.hipoetesia" @change="saveReflejo"/>
                        </div>
                    </th>
                    <th scope="col" class="font-weight-bold">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="item.disestesia" @change="saveReflejo"/>
                        </div>
                    </th>
                </tr>
               </template>
            </tbody>
        </table>
        <div class="row">
            <div class="col-12">
                <div class="form-group mb-2">
                    <label>{{ reflejos.comentarios.label }}</label>
                    <textarea v-model="reflejos.comentarios.value" class="form-control" placeholder="Escribe aquí" @change="saveReflejo"></textarea>
                </div>
            </div>
         </div>
    </div>

    <div class="w-100 mt-2 p-4">
        <label>Rangos Articulares</label>

        <div v-if="typeof GetMedicoFichaActive.metadata.rangos_articulares === 'undefined'" class="w-100">
            <div class="text-center text-danger">No tiene rangos articulares ingresados</div>
        </div>
        <div v-else class="w-100">
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                    <tr class="font-weight-bold small">
                        <th class="font-weight-bold">Miembro o segmento</th>
                        <th class="font-weight-bold">Rango articular</th>
                    </tr>
                </thead>
                <tbody class="small">
                    <template v-for="item in GetMedicoFichaActive.metadata.rangos_articulares" :key="item.id">
                    <tr v-if="(typeof item.detail) !== 'undefined'" >
                        <td>{{ item.detail.miembro_segmento }}</td>
                        <td>{{ item.detail.rango }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-if="showEditionRango || showAddRango" class="row mt-2">
            <h6 class="text-primary col-12">
                {{showAddRango ? " Agregar rango articular" : "Editar rango articular"}}
            </h6>
           
            <div class="form-group col-4">
                    <label>Miembro o segmento nuevo</label>
                    <input type="text" class="form-control" v-model="rangos_articulares.detail.miembro_segmento"/>
            </div>
            <div class="form-group col-4">
                    <label>Rango articular</label>
                    <input type="text" class="form-control" v-model="rangos_articulares.detail.rango"/>
            </div>
            <div class="col-4 d-flex justify-content-around align-items-center">
                <button class="btn btn-danger" type="buttom" @click="cancelAddOrEditRangoArt">
                    Cancelar
                </button>
                <button class="btn btn-primary" type="buttom" @click="saveRangoArt">
                    Guardar
                </button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="newRangoArticular">
                <i class="fa fa-plus mr-2"></i>Agregar nuevo rango articular
            </button>
        </div>

        <div class="mt-2 w-100">
            <div class="row">
                    <div class="form-group mb-2 col-12">
                        <label>{{ patron_capsular.label }}</label>
                        <textarea v-model="patron_capsular.value" class="form-control" placeholder="Escribe aquí" @change="savePatronCapsular"></textarea>
                    </div>
            </div>
         </div>

    </div>

    <div class="w-100 mt-2 p-4">
        <label>Sensación Final</label>

        <div v-if="typeof GetMedicoFichaActive.metadata.sensacion_final === 'undefined'" class="w-100">
            <div class="text-center text-danger">No tiene sensaciones finales ingresadas</div>
        </div>
        <div v-else class="w-100">
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                    <tr class="font-weight-bold small">
                        <th class="font-weight-bold">Miembro o segmento a evaluar</th>
                        <th class="font-weight-bold">Tipo de sensacion final</th>
                        <th class="font-weight-bold">Dolor (Si o No)</th>
                    </tr>
                </thead>
                <tbody class="small">
                    <template v-for="item in GetMedicoFichaActive.metadata.sensacion_final" :key="item.id">
                    <tr v-if="(typeof item.detail) !== 'undefined'" >
                        <td>{{ item.detail.miembro_segmento }}</td>
                        <td>{{ item.detail.tipo_sensacion }}</td>
                        <td>{{ item.detail.dolor }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-if="showEditionSensacion || showAddSensacion" class="row mt-2">
            <h6 class="text-primary col-12">
                {{showAddSensacion ? " Agregar sensación final" : "Editar sensación final"}}
            </h6>
           
            <div class="form-group col-3">
                    <label>Miembro o segmento nuevo</label>
                    <input type="text" class="form-control" v-model="sensacion_final.detail.miembro_segmento"/>
            </div>
            <div class="form-group col-3">
                    <label>Tipo de sensación final</label>
                    <input type="text" class="form-control" v-model="sensacion_final.detail.tipo_sensacion"/>
            </div>
            <div class="form-group col-3">
                    <label>Dolor (Si o No)</label>
                    <input type="text" class="form-control" v-model="sensacion_final.detail.dolor"/>
            </div>
            <div class="col-3 d-flex justify-content-around align-items-center">
                <button class="btn btn-danger" type="buttom" @click="cancelAddOrEditSensaFinal">
                    Cancelar
                </button>
                <button class="btn btn-primary" type="buttom" @click="saveSensacionFinal">
                    Guardar
                </button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="newSensacionFinal">
                <i class="fa fa-plus mr-2"></i>Agregar nueva sensación final
            </button>
        </div>

    </div>

    <div class="w-100 mt-2 p-4">
        <label>Fuerza Muscular</label>

        <div v-if="typeof GetMedicoFichaActive.metadata.fuerza_muscular === 'undefined'" class="w-100">
            <div class="text-center text-danger">No tiene fuerza muscular ingresada</div>
        </div>
        <div v-else class="w-100">
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                    <tr class="font-weight-bold small">
                        <th class="font-weight-bold">Miembro o segmento a evaluar</th>
                        <th class="font-weight-bold">Músculo</th>
                        <th class="font-weight-bold">Grado de fuerza muscular (0-5) Segun escala de valoración</th>
                    </tr>
                </thead>
                <tbody class="small">
                    <template v-for="item in GetMedicoFichaActive.metadata.fuerza_muscular" :key="item.id">
                    <tr v-if="(typeof item.detail) !== 'undefined'" >
                        <td>{{ item.detail.miembro_segmento }}</td>
                        <td>{{ item.detail.musculo }}</td>
                        <td>{{ item.detail.fuerza }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-if="showEditionFuerzaM || showAddFuerzaM" class="row mt-2">
            <h6 class="text-primary col-12">
                {{showAddFuerzaM ? " Agregar sensación final" : "Editar sensación final"}}
            </h6>
           
            <div class="form-group col-3">
                    <label>Miembro o segmento nuevo</label>
                    <input type="text" class="form-control" v-model="fuerza_muscular.detail.miembro_segmento"/>
            </div>
            <div class="form-group col-3">
                    <label>Músculo</label>
                    <input type="text" class="form-control" v-model="fuerza_muscular.detail.musculo"/>
            </div>
            <div class="form-group col-3" title="Segun escala de valoración">
                    <label>Grado de fuerza muscular (0-5)</label>
                    <input type="number" class="form-control" v-model="fuerza_muscular.detail.fuerza"/>
            </div>
            <div class="col-3 d-flex justify-content-around align-items-center">
                <button class="btn btn-danger" type="buttom" @click="cancelAddOrEditFuerzaM">
                    Cancelar
                </button>
                <button class="btn btn-primary" type="buttom" @click="saveFuerzaM">
                    Guardar
                </button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="newFuerzaM">
                <i class="fa fa-plus mr-2"></i>Agregar nueva fuerza muscular
            </button>
        </div>

    </div>

    <div class="w-100 mt-2 p-4">
        <label>Pruebas Especiales</label>

        <div v-if="typeof GetMedicoFichaActive.metadata.pruebas_especiales === 'undefined'" class="w-100">
            <div class="text-center text-danger">No tiene pruebas especiales</div>
        </div>
        <div v-else class="w-100">
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                    <tr class="font-weight-bold small">
                        <th class="font-weight-bold">Miembro o segmento a evaluar</th>
                        <th class="font-weight-bold">Prueba</th>
                        <th class="font-weight-bold">Hallazgo</th>
                    </tr>
                </thead>
                <tbody class="small">
                    <template v-for="item in GetMedicoFichaActive.metadata.pruebas_especiales" :key="item.id">
                    <tr v-if="(typeof item.detail) !== 'undefined'" >
                        <td>{{ item.detail.miembro_segmento }}</td>
                        <td>{{ item.detail.prueba }}</td>
                        <td>{{ item.detail.hallazgo }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-if="showEditionPruebaEspecial || showAddPruebaEspecial" class="row mt-2">
            <h6 class="text-primary col-12">
                {{showAddPruebaEspecial ? " Agregar sensación final" : "Editar sensación final"}}
            </h6>
           
            <div class="form-group col-3">
                    <label>Miembro o segmento nuevo</label>
                    <input type="text" class="form-control" v-model="pruebas_especiales.detail.miembro_segmento"/>
            </div>
            <div class="form-group col-3">
                    <label>Prueba</label>
                    <input type="text" class="form-control" v-model="pruebas_especiales.detail.prueba"/>
            </div>
            <div class="form-group col-3" title="Segun escala de valoración">
                    <label>Hallazgo</label>
                    <input type="text" class="form-control" v-model="pruebas_especiales.detail.hallazgo"/>
            </div>
            <div class="col-3 d-flex justify-content-around align-items-center">
                <button class="btn btn-danger" type="buttom" @click="cancelAddOrEditPruebaEspecial">
                    Cancelar
                </button>
                <button class="btn btn-primary" type="buttom" @click="savePruebaEspecial">
                    Guardar
                </button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="newPruebaEspecial">
                <i class="fa fa-plus mr-2"></i>Agregar nueva prueba especial
            </button>
        </div>

    </div>

    </div>

    
  </box>
  <box  title="Concluciones" icon="fa-solid fa-clipboard-user" :contraction='true'>
    <div class="row mx-2">
        <div class="col-12">
            <div class="form-group mb-2">
                <label>Analisis Clinicofuncional Fisioterapeutico</label>
                <textarea v-model="concluciones_g.ac_fisioterapeutico" class="form-control" placeholder="Escribe aquí" @change="saveConcluciones"></textarea>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group mb-2">
                <label>Historia psiquiatrica</label>
                <textarea v-model="concluciones_g.conclucion_tratamiento" class="form-control" placeholder="Escribe aquí" @change="saveConcluciones"></textarea>
            </div>
        </div>
      </div>

  </box>
</template>
 
 
<style scoped>
.bgtr{
    background-color: var(--bodyBackground);
    transition: 0.2s ease-in;
}

.bgtr td{
    background-color: transparent;
}
.center-option{
   display: flex; 
   flex-direction: row;
   justify-content: center;
   padding: 5px 20px;
}

.check-style{
    color: white;
    background-color: #007bff;
    .center-option label svg{
        border-radius: 50%;
        background-color: aliceblue;
        opacity: 1;
    }
}
.cancel-style{
    background-color: #6c6c6c27;
    opacity: 0.6;
    .center-option label svg{
        border-radius: 50%;
        background-color: aliceblue;
        opacity: 1;
    }
}

.minicard{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius:7px;
    width:80%;
    max-width:95%;
    height:80px;
}

.cancel,
.check {
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    order: 1;
    z-index: 1;
    cursor: pointer;
    visibility: hidden;
}

.center-option label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 30px;
  height: 30px;
}


.center-option label svg{
    position: absolute;
    width: 25px;
    height: 25px;
}

.inputGroup {
    /* background-color: #fff;
    display: block;
    margin: 10px 0; */
    position: relative;
   
  }

  .text-detail{
    resize: none;
    max-height: 35px;
    text-decoration: none;
  }

</style>